import React, { useEffect } from 'react'
import WriteAReview from '../ProductDetailPage/WriteAReview'
import { useParams } from 'react-router-dom'
import { ForLanding } from '../../../Containers/LandingPage/Hooks'
import { useSelector } from 'react-redux'
import { ForAdmin } from '../../../Containers/Admin/Hooks'

const FavouriteVendorDetail = () => {
    const { getNewLandingOffers, getPostedReviews } = ForLanding()
  const {
    getServiceByBusinessInAdmin,
    handleServices
  } = ForAdmin();
    const businessData = useSelector((state) => state.landingData.businessDetails)
    const userId = useParams()
    const serviceData = useSelector((state) => state.totalVendorsInAdmin.services)

  useEffect(() => {
    getServiceByBusinessInAdmin(businessData?.id);
  }, [businessData?.id])
    useEffect(() => {
      getNewLandingOffers(userId?.id)
    }, [])
  
    useEffect(() => {
      getPostedReviews(businessData?.id)
  }, [businessData?.id])
    
    return (
      <div><WriteAReview BusinessDetails={businessData} serviceData={serviceData} handleServices={handleServices}
        reviewDate={true} /></div>
    )
}

export default FavouriteVendorDetail