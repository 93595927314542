import React from 'react'

const CommonTermsAndConditions = (props) => {
    return (
        <div className={`m-[3%]`}>
            <p className={`text-deepgray-4 font-[Roboto-Bold] text-3xl pb-[3%]`}>{`Terms and Conditions`}</p>
            <div className={`flex justify-between `}>
                <div className={`w-[65%] h-[60vh] overflow-y-scroll`}>
                    <p className={`text-deepgray-4 font-[Roboto-Regular] text-[15px] `}>
                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).


                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).


                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                    </p>

                </div>
                <div className={`border border-gray-3 rounded-md px-[2%] pt-[2%] pb-[4%] w-[25%] max-h-[50vh] overflow-y-scroll`}>
                    <p className={`text-deepgrey-3 font-[Roboto-Medium] text-lg pb-[5%]`}>{`Table of Contents`}</p>
                    <p className={`text-skyblue-5 font-[Roboto-Regular] text-base pb-[5%]`}>{`User Agreement`}</p>
                    <p className={`text-skyblue-5 font-[Roboto-Regular] text-base pb-[5%]`}>{`How We Use Your Data`}</p>
                    <p className={`text-skyblue-5 font-[Roboto-Regular] text-base pb-[5%]`}>{`How We Share Information`}</p>
                    <p className={`text-skyblue-5 font-[Roboto-Regular] text-base pb-[5%]`}>{`Your Choices & Obligations`}</p>
                    <p className={`text-skyblue-5 font-[Roboto-Regular] text-base pb-[5%]`}>{`Other Important Information`}</p>

                </div>
            </div>
        </div>
    )
}

export default CommonTermsAndConditions