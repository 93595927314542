import React, { useEffect, useState } from 'react'
import CommonFaq from '../../Common/Layout/Faq/CommonFaq'
import { ForAdmin } from '../../Containers/Admin/Hooks';
import { useSelector } from 'react-redux';

const Faq = (props) => {

    const  { getFaqInAdminApiCall, loading } = ForAdmin()

    const [faqBgClick, setFaqBgClick] = useState(false);
    const handleFaqBgChange = (id) => {
        setFaqBgClick(id)
    }

    useEffect(() => {
        getFaqInAdminApiCall()
    }, [])

    const vendorFaqPrivPol = useSelector((state) => state.cmsPage.faqData)
    const vendorFaq = vendorFaqPrivPol?.filter((item) => item?.type === 'faq')

    return (
        <>
            <CommonFaq 
                faqData={vendorFaq}
                loading={loading}
            />
        </>
    )
}

export default Faq