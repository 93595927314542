import React from 'react'
import DefaultProfileicon from '../../../../Images/Vendor/DefaultProfileicon.svg'
import { Grid } from '@mui/material'
import contactvendorimg from '../../../../Images/Vendor/contactvendorimage.svg'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import moment from 'moment'
import BidCard from '../../../../Common/Layout/Card/BidCard'
import CommonCard from '../../../../Common/Layout/Card/CommonCard'

const UserMyReqLiveDetails = (props) => {

    const liveRequirementsData = useSelector((state) => state.users.myReqUser)
    // const router = useRouter()
    // onClick={() => setOpen(true)}

    const userId = localStorage.getItem('userId')
    const biddata = useSelector((state) => state.vendor.bidsByReqId)
    const biddataOthers = biddata?.filter((item) => (item?.createdBy !== parseInt(userId)))
    // To filter by selected 
    const biddataSelected = biddata?.filter((item) => (item?.selected === true))

    useEffect(() => {
        props.getBidApiCAllByReqId(props.saveReqData?.id)
    }, [])

    return (
        <div>
            <div className={`pt-[1%] pb-[1%] font-[Roboto-Bold] text-base cursor-pointer w-fit`} onClick={props.goBackFromMyReqrLiveDetails}>
                <p> {"<"} Back</p>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} md={7}>
                    <BidCard
                        DefaultProfileicon={DefaultProfileicon}
                        name={props.saveReqData?.user?.name}
                        email={props.saveReqData?.user?.email}
                        LiveBid={props.saveReqData?.status === 'live' && true}
                        question={props.saveReqData?.title}
                        content={props.saveReqData?.description}
                        flex={true}
                        showdate={true}
                        date={moment(props.saveReqData?.startDate).format('LL')}
                        // peoplereplied={true}
                        // numberofpeoplereplied={props.saveReqData?.numberofpeoplereplied}
                        // attachment={true}
                        validdate={props.saveReqData?.status === 'live' ? true : false}
                        time={moment(props.saveReqData?.endDate).format('LL')}
                        uploadDocument={props.saveReqData?.document?.length > 0 && true}
                        saveReqData={props.saveReqData}
                        downloadPdfAttachments={() => props.downloadPdfAttachments(props.saveReqData?.document[0])}
                        item={props.saveReqData}
                    />
                </Grid>
                <Grid item xs={12} md={5}>
                    <div className={`overflow-y-scroll h-[78vh]`}>
                        {biddataOthers?.map((item, idx) =>
                            <div key={idx} className={`w-full pr-2 `}>
                                <CommonCard
                                    itememail={true}
                                    profile={true}
                                    profileicon={item?.profileicon}
                                    email={item?.user?.email}
                                    // ratingforname={true}
                                    ratingvalue={item?.ratingvalue}
                                    acceptdeclinesection={(biddataSelected && biddataSelected?.length === 1) ? false : true}
                                    // acceptdeclinesection={true}
                                    date={moment(item?.createdAt).format('LLL')}
                                    location={true}
                                    showlocationamount={true}
                                    amount={item?.price}
                                    phone={true}
                                    phonetext={item?.user?.phoneNo}
                                    itemname={item?.user?.name}
                                    offerpercentage={item?.offerpercentage}
                                    locationtext={item?.user?.location !== null ? item?.user?.location : "--"}
                                    vendorfullwidth={true}
                                    vendorbutton={`Contact Vendor`}
                                    vendoricon={contactvendorimg}
                                    handleAcceptBid={() => props.selectBidsApiCall(item?.id, true, item?.requirementsId)}
                                    handleDeclineBid={() => props.selectBidsApiCall(item?.id, false, item?.requirementsId)}
                                    item={item}
                                />
                            </div>
                        )}
                    </div>
                </Grid>
            </Grid>
        </div>

    )
}

export default UserMyReqLiveDetails