import React, { useEffect } from 'react'
import TabComponent from '../../../Common/UiComponents/TabComponent'
import BidAmountDetailPage from './BidAmountDetailPage'
import ExpiredBid from './ExpiredBid'
import InterestedBid from './InterestedBid'
import LiveBid from './LiveBid'
import { useSelector } from 'react-redux'
import DeclinedBid from './DeclinedBid'

const AllRequirementSection = (props) => {
  // const liveRequirementsData = useSelector((state) => state.vendor.myrequirements)
  const [value, setValue] = React.useState("0");
  const interestedBidsData = useSelector((state) => state.vendor.allInterestedBids)
  const deniedBids = useSelector((state) => state.vendor.deniedBidByUserId)
  const userId = localStorage.getItem('userId')

  useEffect(() => {
    // props.getAllOfMyRequirements()
    if (value === '2') {
      props.getAllInterestedBidsApiCAll()
    } else if (value === '3') {
      props.getDeniedBidByUserIdApiCall(userId)
    }
  }, [value])

  const categories = [
    {
      label: "liveBid",
      value: "0",
      backgroundcolor: "bg-[#F7F7F7]",
      content: <LiveBid
        goToAllReqrLiveDetails={props.goToAllReqrLiveDetails}
        goBackFromAllReqrLiveDetails={props.goBackFromAllReqrLiveDetails}
        showAllReqrLiveDetails={props.showAllReqrLiveDetails}
        getAllOfMyRequirements={props.getAllOfMyRequirements}
        postBid={props.postBid}
        singleVendorDetailsData={props.singleVendorDetailsData}
        postInterestedBidApiCall={props.postInterestedBidApiCall}
        interestedBidsData={interestedBidsData}
        loading={props.loading}
        postDeclinBid={props.postDeclinBid}
        deniedBids={deniedBids}
        downloadPdfAttachments={props.downloadPdfAttachments}
      />
    },
    {
      label: "expiredBid",
      value: "1",
      backgroundcolor: "bg-[#F7F7F7]",
      content: <ExpiredBid
        goToAllReqrLiveDetails={props.goToAllReqrLiveDetails}
        getAllOfMyRequirements={props.getAllOfMyRequirements}
        loading={props.loading}
      />
    },
    {
      label: "interestedBid",
      value: "2",
      backgroundcolor: "bg-[#F7F7F7]",
      content: <InterestedBid interestedBidsData={interestedBidsData} singleVendorDetailsData={props.singleVendorDetailsData} postBid={props.postBid} goToAllReqrLiveDetails={props.goToAllReqrLiveDetails} loading={props.loading} />
    },
    {
      label: "Declined bid",
      value: "3",
      backgroundcolor: "bg-[#F7F7F7]",
      content: <DeclinedBid loading={props.loading} deniedBids={deniedBids} />
    },
  ]
  return (
    <div className={` mt-3 colorforallreqtab`}>
      {props.showAllReqrLiveDetails ?
        <BidAmountDetailPage
          goBackFromAllReqrLiveDetails={props.goBackFromAllReqrLiveDetails}
          saveAllReqData={props.saveAllReqData}
          getBidApiCAllByReqId={props.getBidApiCAllByReqId}
          singleVendorDetailsData={props.singleVendorDetailsData}
          editBidPriceApiCall={props.editBidPriceApiCall}
          loading={props.loading}
          postBid={props.postBid}
          downloadPdfAttachments={props.downloadPdfAttachments}
          isLiveTab={props.isLiveTab}
        />
        :
        <div>
          <TabComponent
            mappingname={categories}
            button={false}
            buttonextracls={`bg-skyblue-6  text-white rounded text-sm mt-2 mr-4 capitalize`}
            buttonname="addNew"
            value={value}
            setValue={setValue}
          />

        </div>}

    </div>)
}

export default AllRequirementSection