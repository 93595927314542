import React, { useState } from 'react'
import TotalUsersHeader from './TotalUsersHeader'
import TotalUsersTable from './TotalUsersTable'
import text from '../../../Common/Languages/en_US.json'
import AddUserForm from './AddUserForm'
import { useSelector } from 'react-redux'


const TotalUserCompo = (props) => {

  return (
    <div>
      {props.userForm ? (
        <>
          <TotalUsersHeader userName={props.editUserFormData ? `Edit User` : `Add New User`} dropDown={false} addBtn={false} />
          <div className='flex'>
            <div className='w-full'>
              <AddUserForm
                toggleUserForm={props.toggleUserForm}
                createNewUserByAdmin={props.createNewUserByAdmin}
                editUserFormData={props.editUserFormData}
                editUserApi={props.editUserApi}
                totalUserCreateMsg={props.totalUserCreateMsg}
                loading={props.loading}
              />
            </div>
          </div>
        </>
      ) : (
        <div>
          <TotalUsersHeader
            userName={text.totalUsers}
            csvBtn={true}
            dropDown={true}
            addBtn={true}
            toggleSubAdmin={props.toggleUserForm}
            downloadExcelReport={props.downloadExcelReport}
            getAllUsers={props.getAllUsers}
            forComponent = 'totalUser'
            setGovFieldData={props.setGovFieldData}
            onChange={props.handleSearchUser}
            search={true}
          />
          <div className={`overflow-y-auto h-[75vh]`}>
            <TotalUsersTable
              getAllUsers={props.getAllUsers}
              editUserForm={props.editUserForm}
              getTotalUserDetailsApi={props.getTotalUserDetailsApi}
              approveUserApi={props.approveUserApi}
              userEnableDisableApi={props.userEnableDisableApi}
              loading={props.loading}
              giveBadgetoUser={props.giveBadgetoUser}
              buttonLoading={props.buttonLoading}
            />
          </div>
        </div>
      )
      }

    </div>
  )
}

export default TotalUserCompo