import React, { useEffect } from 'react'
import Radio from '@mui/material/Radio';
import { IoClose } from "react-icons/io5";
import { Modal } from '@mui/material';
import ButtonField from './ButtonField';
import { useSelector } from 'react-redux';

const CorporatePlanPopup = ({ vendor, open, onClose, planSubmit, selectPlan, setPlan, createCorporateSubscription }) => {

    const subscriptionPlanData = useSelector((state) => state.subscription.plans);
    const corporatePlansData = subscriptionPlanData?.filter(item => item?.planType === 'corporate');



    return (
        <>
            <Modal open={open} onClose={onClose} className='flex items-center justify-center'>
                <form onSubmit={planSubmit} className='bg-white w-[33rem] max-h-[80vh] overflow-y-scroll rounded-xl shadow-xl'>
                    <div className='flex justify-end'>
                        <div onClick={onClose} className='absolute text-gray-600 text-[25px] z-50 hover:rounded-lg cursor-pointer px-2 pt-6'>
                            <IoClose />
                        </div>
                    </div>
                    <div className=''>
                        <h2 className='text-xl text-green-600 font-semibold mb-4 sticky top-0 bg-white border-b border-slate-200 px-4 py-6 z-40'>Apply corporate plan</h2>

                        {/* Plan List */}
                        <div className='p-3'>
                            {corporatePlansData && corporatePlansData?.length > 0 && corporatePlansData?.map((plan, index) => (
                                <div key={index} className='mb-4'>
                                    <Radio
                                        checked={selectPlan?.id === plan?.id}
                                        onChange={() => setPlan(plan)}
                                        value={plan?.id}
                                        name="plan"
                                        inputProps={{ 'aria-label': plan?.name }}
                                    />
                                    {plan?.name} | {plan?.price} {plan?.currency}
                                </div>
                            ))}
                        </div>

                        {/* Apply Button */}
                        <div className='flex justify-end bg-white sticky bottom-0 gap-3 border-t border-slate-200 px-3 py-3'>
                            <ButtonField
                                buttonName="Cancel"
                                buttonextracls="text-blue-400 px-4 py-2"
                                type="submit"
                            />

                            <ButtonField
                                buttonName="Apply"
                                buttonextracls={`text-white px-4 py-2 ${selectPlan ? 'bg-green-600' : 'bg-green-300'}`}
                                type="submit"
                                disabled={selectPlan ? false : true}
                                onClick={() => createCorporateSubscription(selectPlan, vendor?.id)}
                            />
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default CorporatePlanPopup
