import React, { useEffect } from 'react'
import TotalUsersHeader from '../../Components/Admin/TotalUsers/TotalUsersHeader'
import { useNavigate } from 'react-router-dom'
import { ForAdmin } from './Hooks'
import { useJwt } from 'react-jwt'
import PlanComponentIndex from '../../Components/Admin/Plans/PlanComponentIndex.jsx'
import SubscriptionForm from '../../Components/Admin/Plans/SubscriptionForm'
import SnackBarComponent from '../../Common/Layout/Snackbar/SnackBar'

const PlansIndex = () => {

  const navigate = useNavigate()

  const { 
    adminSnackbar,
    messageClose,
    showSubscriptionForm,
    handleSubscriptionForm,
    createSubscriptionPlansApiCall,
    getSubscriptionPlansApiCall,
    editPlan,
    editSubscriptionFormHandler,
    updateSubscriptionPlansApiCall,
    deleteSubscriptionPlanApiCall,
    loading
  } = ForAdmin()

  // For refresh token when actual token expires 
  let token = localStorage.getItem('token') ? localStorage.getItem('token') : "";
  const { isExpired } = useJwt(token);
  const checkTokenExpiration = () => {
    if (isExpired) {
      navigate('/login');
    }
  }

  useEffect(() => {
    getSubscriptionPlansApiCall()
  }, [])

  return (
    <div onClick={() => checkTokenExpiration()}>
      {!showSubscriptionForm ?
        <div>
          <TotalUsersHeader
            userName={`Plans List`}
            addBtn
            toggleSubAdmin={handleSubscriptionForm}
          />
          <div className={`overflow-y-auto h-[75vh]`}>
            <PlanComponentIndex
              editSubscriptionFormHandler={editSubscriptionFormHandler}
              deleteSubscriptionPlanApiCall={deleteSubscriptionPlanApiCall}
              loading={loading}
            />
          </div>
        </div>
        :
        <SubscriptionForm
          handleSubscriptionForm={handleSubscriptionForm}
          createSubscriptionPlansApiCall={createSubscriptionPlansApiCall}
          editPlan={editPlan}
          updateSubscriptionPlansApiCall={updateSubscriptionPlansApiCall}
          loading={loading}
        />
      }

      <SnackBarComponent
        messageOpen={adminSnackbar.open}
        messageClose={messageClose}
        notificationText={adminSnackbar.message}
        subText={adminSnackbar.subText}
        alertType={adminSnackbar.alertType}
        borderClass={adminSnackbar.borderClass}
      />
    </div>

  )
}

export default PlansIndex