import React from 'react'
import CommonCard from '../../../Common/Layout/Card/CommonCard'
import DefaultProfileicon from '../../../Images/Vendor/DefaultProfileicon.svg'
import BidCard from '../../../Common/Layout/Card/BidCard'
import { CircularProgress, Grid } from '@mui/material'
import contactvendorimg from '../../../Images/Vendor/contactvendorimage.svg'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import moment from 'moment'

const VendorMyReqLive = (props) => {

    // const liveRequirementsData = useSelector((state) => state.vendor.myrequirements)
    // const router = useRouter()
    // onClick={() => setOpen(true)}

    const userId = localStorage.getItem('userId')

    const biddata = useSelector((state) => state.vendor.bidsByReqId)
    const biddataOthers = biddata?.filter((item) => (item?.createdBy !== parseInt(userId)))
    // To filter by selected 
    const biddataSelected = biddata?.filter((item) => (item?.selected === true))

    useEffect(() => {
        props.getBidApiCAllByReqId(props.saveReqData?.id)
    }, [])

    return (
        <div>
            <div onClick={props.goBackFromMyReqrLiveDetails} className={`pt-[1%] pb-[1%] font-[Roboto-Bold] text-base cursor-pointer w-fit`}>
                <p> {"<"} Back</p>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} md={7}>
                    <BidCard
                        DefaultProfileicon={DefaultProfileicon}
                        name={props.saveReqData?.user?.name}
                        email={props.saveReqData?.user?.email}
                        LiveBid={props.saveReqData?.status === 'live' && true}
                        question={props.saveReqData?.title}
                        content={props.saveReqData?.description}
                        flex={true}
                        showdate={true}
                        expiredtime={false}
                        date={moment(props.saveReqData?.startDate).format('LL')}
                        // peoplereplied={true}
                        // numberofpeoplereplied={props.saveReqData?.numberofpeoplereplied}
                        validdate={props.saveReqData?.status === 'live' ? true : false}
                        time={moment(props.saveReqData?.endDate).format('LL')}
                        uploadDocument={props.saveReqData?.document?.length > 0 && true}
                        saveReqData={props.saveReqData}
                        downloadPdfAttachments={() => props.downloadPdfAttachments(props.saveReqData?.document[0])}
                        item={props.saveReqData}
                    />
                </Grid>
                <Grid item xs={12} md={5}>
                    {props.loading === true ?
                        <div className='flex h-full justify-center items-center'>
                            <CircularProgress size={20} color='success' />
                            <p className='pl-3'>Loading...</p>
                        </div>
                        :
                        <div className={`overflow-y-scroll h-[78vh]`}>
                            {biddataOthers?.sort((a, b)=> a?.price - b?.price)?.map((item, idx) =>
                                <div key={idx} className={`w-full pr-2 `}>
                                    <CommonCard
                                        itememail={true}
                                        profile={true}
                                        profileicon={item?.profileicon}
                                        email={item?.user?.email}
                                        // ratingforname={true}
                                        ratingvalue={item?.ratingvalue}
                                        acceptdeclinesection={(biddataSelected && biddataSelected?.length === 1) ? false : true}
                                        date={moment(item?.createdAt).format('LLL')}
                                        location={true}
                                        showlocationamount={true}
                                        amount={item?.price}
                                        phone={true}
                                        phonetext={item?.user?.phoneNo}
                                        itemname={item?.user?.name}
                                        offerpercentage={item?.offerpercentage}
                                        locationtext={item?.business?.location !== null ? item?.business?.location : "--"}
                                        vendorfullwidth={true}
                                        vendorbutton={`Contact Vendor`}
                                        vendoricon={contactvendorimg}
                                        handleAcceptBid={() => props.selectBidsApiCall(item?.id, true, item?.requirementsId)}
                                        handleDeclineBid={() => props.selectBidsApiCall(item?.id, false, item?.requirementsId)}
                                        item={item}
                                    />
                                </div>
                            )}
                        </div>
                    }
                </Grid>
            </Grid>
        </div>

    )
}

export default VendorMyReqLive