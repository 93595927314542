import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import text from "../../Common/Languages/en_US.json"
import MainHeader from '../../Common/Layout/MainHheader/MainHeader'
import LandingHeader from '../../Common/Layout/LandingHeader/LandingHeader'
import Footer from '../../Common/Layout/Footer/Footer'
import HyperlinkIcon from '../../Images/Setting/HyperlinkIcon.svg'

const AllHyperlinkCompo = (props) => {
    const allLinks = useSelector((state) => state.setting.hyperlinks)
    const goToLink = (myLink) => {
        window.open(myLink, '_blank', 'noreferrer')
    }

    useEffect(()=>{
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <div className='sticky top-0 z-50'>
                <MainHeader />
                <LandingHeader
                    searchBar={true}
                    goTomessage={true}
                    goToNotification={true}
                    border={true}
                    profile={true}
                    showNotification={props.showNotification}
                    allnotification={props.allnotification}
                    detailsPage={props.detailsPage}
                    readNotificationApi={props.readNotificationApi}
                    handleclickNotification={props.handleshow} />
            </div>
            <div className={`p-[4%] bg-white-5`}>
                <p onClick={props.backtoPage} className={`bg-primeryColour-5 font-[Roboto-Regular] rounded text-white w-20 py-1 text-center cursor-pointer`}>
                    {"<-"} Back
                </p>
                <div className={`pb-[3%]`}>
                    <p className={`text-center text-3xl font-[Roboto-Regular] font-normal text-deepgray-5 pb-2 lg:pb-0 cursor-pointer`}>{text.allHyperlinks}</p>
                </div>
                <div className={`px-[2%] py-[3%] bg-white-5 gridContainer grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5`}>
                    {allLinks && allLinks?.length > 0 && allLinks.map((item, index) =>
                        <div key={index} className={`bg-white  px-[4%] py-[12%] border-2 rounded-md drop-shadow-sm border-gray-0.5 flex relative justify-center`} >
                            <div className='flex flex-col gap-8 pt-4 justify-between items-center cursor-pointer' onClick={() => goToLink(item?.url)}>
                                <img src={item?.picture?.key ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${item?.picture?.key}&height=20&width=auto` : HyperlinkIcon} alt='hyperlink-icon' className='hyperlinkImg rounded-full' 
                                    crossOrigin='anonymous'
                                />
                                <p className='text-[17px] font-[Roboto-Regular] text-deepgrey-3'>{item?.name}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Footer />

        </div>
    )
}

export default AllHyperlinkCompo