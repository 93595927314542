import React, { useState } from 'react'
import CommonCard from '../../../Common/Layout/Card/CommonCard'
import img1 from '../../../Images/Vendor/offerimg1.png'
import img2 from '../../../Images/Vendor/offerimg2.png'
import img3 from '../../../Images/Vendor/offerimg3.png'
import profileUserDetailsIcon from '../../../Images/profileUserCardImages/profileUserDetailsIcon.png'
import featuretag from "../../../Images/Vendor/Featuretag.svg"
import crownicon from "../../../Images/Vendor/crownicon.svg"
import { CircularProgress } from '@mui/material'
import DeclinePopup from '../../../Common/FormFields/DeclinePopup'
import { useTranslation } from 'react-i18next'
import NoContentPage from '../../../Common/Layout/NoContentPage/NoContentPage'

const OfferActivePage = (props) => {

    const { t } = useTranslation()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [productItem, setProductItem] = useState()
    const handleclickForDeleteProduct = (data) => {
        setOpenDeleteModal(!openDeleteModal)
        setProductItem(data)
    }
    const deleteProductSubmit = () => {
        props.deleteProductOffers(productItem)
        setOpenDeleteModal(!openDeleteModal)
    }
    const itemContents = [
        {
            image: img1
        },
        {
            image: img2
        },
        {
            image: img3
        },
        {
            image: img3
        }
    ]

    return (
        <>
            {props.loading === true ?
                <div className='flex h-[75vh] justify-center items-center'>
                    <CircularProgress size={20} color='success' />
                    <p className='pl-3'>Loading...</p>
                </div>
                :
                (props.tabledata && props.tabledata?.length > 0) ?
                    <div className="overflow-y-scroll h-[75vh] cardDesign mt-5">
                        {props.tabledata?.filter((item) => item?.status === 'active').map((item, idx) =>
                            <div key={idx} className={`w-full`}>
                                {
                                    <CommonCard
                                        carousel={item?.product?.picture && item?.product?.picture?.length > 0 ? true : false}
                                        noContentImg={item?.product?.picture && item?.product?.picture?.length === 0 && true}
                                        carouselimg={item?.product?.picture && item?.product?.picture?.length > 0 ? item?.product?.picture : itemContents}
                                        height={false}
                                        carouselHeight="150px"
                                        noContentCls={`h-[150px]`}
                                        featurecrown={false}
                                        crownicon={crownicon}
                                        featuretag={featuretag}
                                        rating={true}
                                        ratingvalue={item.ratingvalue}
                                        itemtext={true}
                                        Shareicon={false}
                                        text1={item?.product?.name}
                                        text2={item?.product?.description}
                                        itememail={true}
                                        profile={false}
                                        // profileicon={profileUserDetailsIcon}
                                        email={item?.user?.email}
                                        distance={false}
                                        showamount={true}
                                        amount={item?.product?.price}
                                        itemname={item?.user?.name}
                                        percentage={item?.percentage && true}
                                        offerpercentage={item?.percentage}
                                        price={item?.price && true}
                                        offerprice={item?.price}
                                        border={true}
                                        distancetext={item.distancetext}
                                        hoverbutton={false}
                                        buttonName2={item.buttonName2}
                                        buttonimg={item.buttonimg}
                                        buttonName3={item.buttonName3}
                                        buttonimg2={item.buttonimg2}
                                        deleteIcon={true}
                                        deleteIconFunc={() => handleclickForDeleteProduct(item)}
                                    />
                                }
                            </div>
                        )}
                        {openDeleteModal &&
                            <DeclinePopup
                                dialogTitlecls={`!text-red-500`}
                                submitBtnCls={`!bg-red-600`}
                                open={openDeleteModal}
                                title={`Decline`}
                                handleClose={handleclickForDeleteProduct}
                                DilogContenttext={"Price"}
                                DialogContent={
                                    <>
                                        <p>{t('deleteProductText')}</p>
                                    </>
                                }
                                DeleteBtn={"Decline"}
                                onClick={deleteProductSubmit}
                            />
                        }
                    </div>
                    :
                    <NoContentPage
                        NoContentMainDiv={`h-[78vh]`}
                        text2={`There is no data`}
                    />
            }
        </>
    )
}

export default OfferActivePage