import React from 'react'
import pdficon from '../../Images/CommonImages/pdficon.svg'
// import UploadButton from './UploadButton'
// import uploadicon from '../../Images/CommonImages/uploadicon.svg'
import ButtonField from '../FormFields/ButtonField'
import { Tooltip } from '@mui/material'
const UploadDocument = (props) => {
  return (
    <div className={`flex w-full justify-between bg-skyblue-0.5 py-5 rounded ${props.extracls}`}>
      <div className={`flex pl-5 w-3/4`}>
        <img src={pdficon} alt="pdficon" height={25} width={25} />
        <div className={`flex flex-col pl-4`}>
          <Tooltip title={props.pdfname}>
            <p className={`text-deepgray-7 font-[Roboto-Regular] text-xs w-3/4 truncate`}>{props.pdfname}</p>
          </Tooltip>
          {props.view ? (
            <p className={`text-skyblue-5 font-[Roboto-Medium] text-[13px] cursor-pointer`}>{props.viewid}</p>
          ) : (
            <p className={` text-deepgray-1 font-[Roboto-Regular] text-xs`}>{props.kb}</p>
          )}
        </div>
      </div>
      <div>
        {props.uploadbutton ? (
          <ButtonField img={props.img} disabled={props.disabled} onClick={props.downloadPdfAttachments}/>
        ) : (
          <p className={` text-deepgray-1 font-[Roboto-Regular] text-xs pr-5`}>{props.kb}</p>
        )
        }
      </div>
    </div>
  )
}

export default UploadDocument