import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  plans: [],
  planDetails: {},
  loading: false,
  error: false,
}

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    savePlanData: (state, action) => {
      state.plans = action.payload;
      state.loading = false;
      state.error = false;
    },
    singlePlanData: (state, action) => {
      state.planDetails = action.payload;
      state.loading = false;
      state.error = false;
    },
  }
})

export const { savePlanData, singlePlanData } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;