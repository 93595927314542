import React, { useState } from 'react'
import serviceOffered from '../../../Images/Vendor/serviceOffered.svg'
import LikeIcon from '../../UiComponents/LikeIcon'
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

const BabyCareShowImg = (props) => {

  const firstImg = props.imgArr && props.imgArr?.length > 0 && props.imgArr[0]?.key
  const [bigImg, setBigImg] = useState(firstImg ? firstImg : "" )
  const [clicked, setClicked] = useState(null)
  const param = useParams();
  useEffect(() => {
    setBigImg(firstImg ? firstImg : "");
  }, [param.id, firstImg])
  const handleImgClick = (savedKey, savedId) => {
    setBigImg(savedKey);
    setClicked(savedId)
  }

  return (
    <div className={`flex justify-between ${props.mainDivCls} w-full`}>
      {/* Small images/thumbnail  */}
      <div className={`w-[20%] ${props.smallImgsDivCls} `}>
        {props.imgArr && props.imgArr?.length > 0 && props.imgArr?.slice(0, 4).map((image, idx) =>
          <div key={idx} className={`flex justify-center items-center bg-[#D9D9D9] cursor-pointer h-20 w-full`}>
            <img
              className={`${clicked === image?.id ? 'border border-red-500' : ''}`}
              onClick={() => handleImgClick(image?.key, image?.id)}
              crossOrigin="anonymous"
              src={image?.key && `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${image?.key}&height=auto&width=auto`}
              alt="smallThumbnails"
              style={{width: '100%', height: '100%', objectFit: 'cover'}}
            />
          </div>)
        }
      </div>
      {/* Big image  */}
      <div className={`bg-[#D9D9D9] ${props.bigImgDivCls} ${bigImg !== "" && 'bg-black'}`}>
        <div className={`flex items-center justify-center w-full`}>
          {bigImg === "" ?
            <img src={serviceOffered} alt="serviceOffered"/>
            :
            <img
              crossOrigin="anonymous"
              src={`${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${bigImg}&height=auto&width=auto`}
              alt="thumbnail"
              className={`w-full h-80 object-contain `}
              // style={{width: '100%', height: '100%', objectFit: 'cover'}}
            />
          }
        </div>
        {props.likeIcon &&
          <div className={`absolute top-[3%] right-[3%]`}>
            <LikeIcon size='small' />
          </div>}
      </div>
    </div>
  )
}

export default BabyCareShowImg

BabyCareShowImg.propTypes = {
  imgArr: PropTypes.array,
  likeIcon: PropTypes.bool,
  mainDivCls: PropTypes.string,
  smallImgsDivCls: PropTypes.string,
  bigImgDivCls: PropTypes.string
}