import React, { useEffect } from 'react'
import SubscriptionComponent from '../../Components/Admin/Subscription'
import { useNavigate } from 'react-router-dom';
import { useJwt } from 'react-jwt';
import { ForAdmin } from './Hooks'
const SubscriptionIndex = () => {
    const navigate = useNavigate()
    const { getAllSubscription } = ForAdmin()

    // For refresh token when actual token expires 
    let token = localStorage.getItem('token') ? localStorage.getItem('token') : "";
    const { isExpired } = useJwt(token);
    const checkTokenExpiration = () => {
        if (isExpired) {
            navigate('/login');
        }
    }
    useEffect(() => { getAllSubscription() }, [])
    return (
        <div onClick={() => checkTokenExpiration()}><SubscriptionComponent /></div>
    )
}

export default SubscriptionIndex