import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  paymentHistory: [],
  loading: false,
  error: false,
};

export const paymentHistorySlice = createSlice({
  name: "paymentHistory",
  initialState,
  reducers: {
    getPaymentHistory: (state, action) => {
      state.paymentHistory = action.payload;
      state.loading = false;
      state.error = false;
    },
  },
});

export const { getPaymentHistory } = paymentHistorySlice.actions;
export default paymentHistorySlice.reducer;
