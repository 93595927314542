import React, { useState } from 'react'
import MainHeader from '../MainHheader/MainHeader'
import LandingHeader from '../LandingHeader/LandingHeader'
import searchicon from '../../../Images/profileDashboardIcons/searchicon.svg'
import Accordionbutton from '../../UiComponents/Accordionbutton'
import Footer from '../Footer/Footer'
import TextFieldInput from '../../FormFields/TextFieldInput'
import { CircularProgress, InputAdornment } from '@mui/material'

const CommonFaq = (props) => {

    const [faqBgClick, setFaqBgClick] = useState(false);
    // const handleFaqBgChange = (id) => {
    //     setFaqBgClick(id)
    // }

    return (
        <div className={`faqdesign h-fit`}>
            {/* <MainHeader /> */}
            <LandingHeader
                searchBar={true}
                goTomessage={true}
                goToNotification={true}
                border={true}
                profile={true}
                handleclickNotification={props.handleshow}
                showNotification={props.showNotification}
                detailsPage={props.detailsPage}
                allnotification={props.allnotification}
                readNotificationApi={props.readNotificationApi}
            />
            <div className={` px-[5%] py-[3%]`}>
                <div className={`flex justify-between w-full mb-[2%]`}>
                    <p className={`text-gray-9.5 text-3xl font-[Roboto-Bold]`}>FAQ</p>
                    {/* <div className={`w-[30%]`}>
                        <TextFieldInput
                            onlyValue
                            textnewclass={`flex-1 w-full text-sm`}
                            fullWidth={true}
                            placeholder={`Search Faq`}
                            id="outlined-basic"
                            variant="outlined"
                            startAdornment={
                                <InputAdornment position={"start"}>
                                    <img src={searchicon} alt='search' />
                                </InputAdornment>}
                        />
                    </div> */}
                </div>
                <div className={`h-[60vh] overflow-hidden hover:overflow-y-scroll`}>
                    {props.loading === true ?
                        <div className='flex h-full justify-center items-center'>
                            <CircularProgress size={20} color='success' />
                            <p className='pl-3'>Loading...</p>
                        </div>
                        :
                        <>
                            {props.faqData && props.faqData?.length > 0 && props.faqData?.map((faqItem, idx) =>
                                <div key={idx} className={`faqAdmin border-b`}>
                                    {faqItem?.questions?.length > 0 && faqItem?.answers?.length > 0 &&
                                        <Accordionbutton
                                            // onClick={() => handleFaqBgChange(faqItem?.id)}
                                            accordionMainCls={`${faqBgClick === faqItem?.id ? 'bg-[#F5F5F5]' : ''}`}
                                            AccordionSummary={
                                                <div className={`flex justify-between px-3 py-1 items-start text-sm font-[Roboto-Regular]`}>
                                                    <p className={`text-base text-black font-[Roboto-Medium]`}>{faqItem?.questions}</p>
                                                </div>
                                            }
                                            AccordionDetails={
                                                <div className={`mt-5`}>{faqItem?.answers}</div>
                                            }
                                            panelItem={faqItem?.id}
                                            setExpanded={setFaqBgClick}
                                            expanded={faqBgClick}
                                        />
                                    }
                                </div>
                            )}
                        </>
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CommonFaq