const Consolelog = (type, message, data) => {
    let url = window.location.href
    console.log(url, "url check")
    if (url.includes("localhost") || url.includes("dev")) {
        switch (type) {
            case 'success':
                console.log(`%c ${message} ${data ? "==>" + data : ""}`, "color: green")
                break
            case 'error':
                console.log(`%c ${message}  ${data ? "==>" + data : ""}`, "color:red")
                break
            case 'info':
                console.log(`%c ${message} ${data ? "==>" + data : ""}`, "color:cyan")
                break
            case 'warning':
                console.log(`%c ${message} ${data ? "==>" + data : ""}`, "color:orange")
                break
            default:
                console.log("Type not supported")
        }
    }

}
export default Consolelog