import React from 'react'
import SeeAllProductOfferPAge from '../../Components/LandingPage/SeeAllProductOfferPAge'
import { ForLanding } from './Hooks'
const ProductDetailsPage = () => {
    const { backtoPage } = ForLanding()


    return (
        <div>
            <SeeAllProductOfferPAge backtoPage={backtoPage} />
        </div>
    )
}

export default ProductDetailsPage