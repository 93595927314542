import React, { useEffect } from 'react'
import SeeAllPage from '../../Components/LandingPage/SeeAllPage'
import { ForSignup } from '../Authentication/Signup/Hook'
import { ForVendor } from '../VendorDetailPage/Hooks'
import { ForLanding } from './Hooks'
import SeeAllServiceOffer from '../../Components/LandingPage/SeeAllServiceOffer'
import { ForNotification } from '../Notification/Hooks'

const SeeAll = () => {
  const { onHandelOpeModal } = ForLanding()
  const { hasMore, backtoPage } = ForVendor()
  const { handleshow, showNotification, allnotification, detailsPage, getNotification, readNotificationApi } = ForNotification()

  useEffect(()=>{
    getNotification()
  }, [])

  return (
    <div>
      <SeeAllPage backtoPage={backtoPage}
        onHandelOpeModal={onHandelOpeModal}
        hasMore = { hasMore }
        detailsPage={detailsPage}
        allnotification={allnotification}
        showNotification={showNotification}
        handleshow={handleshow}
        readNotificationApi={readNotificationApi}
      />    
    </div>
  )
}

export default SeeAll