import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
    GoogleMap, Marker,
    Autocomplete, Circle,
    useJsApiLoader, InfoWindow,
    useLoadScript,
    InfoWindowF
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import { ForSignup } from '../../Containers/Authentication/Signup/Hook';
import { useSelector } from 'react-redux';

const libraries = ["places"]
const mapContainerStyle = {
    width: '90vw',
    height: '100vh'
}
const center = {
    lat:  22.5726,
    lng:  88.3639
}
const options = {
    disableDefaultUI: true,
    zoomControl: true
}


const GoogleMapView = ({ mapRef, searchResult,markers,handleSetMarkers,selected,handleSetSelected }) => {
    const { getLocationData, mapLocationTxt } = ForSignup()
    const locationTxt = useSelector((state) => state.vendorAccount.location)

    // const apiKey = `${process.env.REACT_APP_GOOGLE_API_KEY}`
    // const { isLoaded, loadError } = useLoadScript({
    //     googleMapsApiKey: apiKey,
    //     libraries: libraries
    // })

    const onMapClick = useCallback((event) => {
        getLocationData(event.latLng.lat(), event.latLng.lng())
        if (mapLocationTxt && mapLocationTxt?.length > 0) {
            // console.log("# if con")
            handleSetMarkers({
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
                address: mapLocationTxt,
                time: new Date(),
            })
        }
    }, []);

    const onMapload = useCallback((map) => {
        mapRef.current = map;
    }, [])

    useEffect(() => {
        if (searchResult) {
            searchResult.map((item) => {
                handleSetMarkers({
                    lat: item?.geometry?.location?.lat,
                    lng: item?.geometry?.location?.lng,
                    address: item?.vicinity,
                    name: item?.name,
                })
            })
            }
    }, [searchResult])

    return (
        <div>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={12}
                center={center}
                options={options}
                onClick={onMapClick}
                onLoad={onMapload}
            >
                {markers && markers.map((marker, index) => (
                    <Marker
                        key={index}
                        position={{ lat: marker.lat, lng: marker.lng }}
                        onClick={() => {
                            handleSetSelected(marker);
                        }}
                    />))
                }
                {selected ? (
                    <InfoWindowF
                        position={{ lat: selected.lat, lng: selected.lng }}
                        onCloseClick={() => {
                            handleSetSelected(null);

                        }}
                    >
                        <>
                        <div><p>{selected.name}</p></div>
                        <div><p>{selected.address}</p></div>
                        </>
                    </InfoWindowF>

                ) : null}
            </GoogleMap>
        </div>
    )
}


export default GoogleMapView