import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import SnackBarComponent from '../../Common/Layout/Snackbar/SnackBar'
import Category from '../../Components/Admin/Category'
import { ForUploadMediaHooks } from '../UploadMedia/Hooks'
import { ForAdmin } from './Hooks'
import { useNavigate } from 'react-router-dom'
import { useJwt } from 'react-jwt'

const AdminCategory = () => {
    const { getCategory,
        createCategory,
        categoryOpenModal,
        CategoryName,
        CategoryDescription,
        openModal,
        cancleModal,
        deleteCategory,
        editCategory,
        newCategoryName,
        addNewCategoryName,
        openEditName,
        categoryId,
        subCategoryModal,
        openSubCategoryModal,
        subCategoryModalClose,
        subCategoryId,
        addNewSubCategoryName,
        openEditSubCategoryName,
        newSubCategoryName,
        editSubCategory,
        addCategoryName,
        addCategoryDescription,
        uploadLogoProfile,
        setUploadLogoProfile,
        handelChange,
        treeUploadLogoProfile,
        setTreeUploadLogoProfile,
        saveId,
        dataValue,
        loading,
        CategoryForm,
        adminSnackbar,
        messageClose,
        subCategoryForm,
        editSubCategoryData,
        deleteSubCategoryApiCall
    } = ForAdmin()
    const { doUploadedMedia, doUploadedLogo, multipleMediaApiCall } = ForUploadMediaHooks()

    // const totalVendorsData = useSelector((state) => state.totalVendorsInAdmin.totalVendors)
    const categoryData = useSelector((state) => state.totalCategoryInAdmin.totalCategories)

    useEffect(() => {
        getCategory()
    }, [])

    const navigate = useNavigate()

    // For refresh token when actual token expires 
    let token = localStorage.getItem('token') ? localStorage.getItem('token') : "";
    const { isExpired } = useJwt(token);
    const checkTokenExpiration = () => {
        if (isExpired) {
            navigate('/login');
        }
    }

    return (
        <div onClick={() => checkTokenExpiration()}>
            <Category
                OpenModal={categoryOpenModal}
                openModal={openModal}
                createCategory={createCategory}
                CategoryName={CategoryName}
                CategoryDescription={CategoryDescription}
                cancleModal={cancleModal}
                categoryData={categoryData}
                deleteCategory={deleteCategory}
                editCategory={editCategory}
                newCategoryName={newCategoryName}
                addNewCategoryName={addNewCategoryName}
                openEditName={openEditName}
                categoryId={categoryId}
                addSubCategory={createCategory}
                subCategoryModal={subCategoryModal}
                openSubCategoryModal={openSubCategoryModal}
                subCategoryModalClose={subCategoryModalClose}
                subCategoryId={subCategoryId}
                addNewSubCategoryName={addNewSubCategoryName}
                openEditSubCategoryName={openEditSubCategoryName}
                editSubCategory={editSubCategory}
                newSubCategoryName={newSubCategoryName}
                addCategoryName={addCategoryName}
                addCategoryDescription={addCategoryDescription}
                doUploadedLogo={doUploadedLogo}
                uploadLogoProfile={uploadLogoProfile}
                setUploadLogoProfile={setUploadLogoProfile}
                handelChange={handelChange}
                treeUploadLogoProfile={treeUploadLogoProfile}
                setTreeUploadLogoProfile={setTreeUploadLogoProfile}
                saveId={saveId}
                dataValue={dataValue}
                loading={loading}
                CategoryForm={CategoryForm}
                subCategoryForm={subCategoryForm}
                editSubCategoryData={editSubCategoryData}
                deleteSubCategoryApiCall={deleteSubCategoryApiCall}
            />
             <SnackBarComponent
        messageOpen={adminSnackbar.open}
        messageClose={messageClose}
        notificationText={adminSnackbar.message}
        subText={adminSnackbar.subText}
        alertType={adminSnackbar.alertType}
        borderClass={adminSnackbar.borderClass}
      />
            </div>
    )
}

export default AdminCategory