import React from 'react'
import ButtonField from '../../../Common/FormFields/ButtonField'
import buttonArrow from '../../../Images/adminContent/buttonArrow.svg'
// import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import eventIcon from '../../../Images/adminContent/eventIcon.svg'
import ReactDateRange from '../../../Common/FormFields/DateRangePicker';
import './index.css'
import text from "../../../Common/Languages/en_US.json"
import moment from 'moment';

const DashboardTop = (props) => {
    const name = localStorage.getItem('name')
    return (
        <div className={`flex justify-between relative`}>
            <div>
                <p className='text-[#1C1C1C] text-2xl font-[Roboto-Regular]'>{text.dashboard}</p>
                <p className='text-[#969696] text-lg font-[Roboto-Regular]'>Good to see you again, {name}</p>
            </div>

            <div className={`flex items-end gap-4 `}>
                {/* date picker  */}
                <div className={`flex justify-between border border-[#DEDEDE] w-[15rem] px-3 py-2 rounded-md cursor-pointer bg-white`} onClick={props.handleOpenDate}>
                    <div className='flex items-center text-[#7B7B7B] gap-1'
                    // onClick={startDateHandler}
                    >
                        <img src={eventIcon} alt='event icon' />
                        <p className='text-[13px] font-[Roboto-Regular] text-gray-6'>{props.dateState[0]?.startDate !== null ? moment(props.dateState[0]?.startDate).format('DD-MM-YYYY') : text.startDate}</p>
                    </div>
                    <div className=' text-deepgray-4 font-[Roboto-Regular] text-[14px]'>{text.to}</div>
                    <div className='flex items-center basis-3/7 text-[#7B7B7B] gap-1'
                    // onClick={endDateHandler}
                    >
                        <img src={eventIcon} alt='event icon' />
                        <p className='text-[13px] font-[Roboto-Regular] text-gray-6'>{props.dateState[0]?.endDate !== null ? moment(props.dateState[0]?.endDate).format('DD-MM-YYYY') : text.endDate}</p>
                    </div>

                </div>
                <div>
                    <ButtonField
                        buttonName={text.downloadExcel}
                        startIcon={<img src={buttonArrow} alt='img' className='w-[14px] h-[17px]' />}
                        buttonextracls={` !px-6 !py-2 !text-white bg-[#007AA3]`}
                    // onClick={() => props.downloadExcelReportDashboard()}
                    />

                </div>
            </div>

            {props.dateOpen ? (
                <ReactDateRange
                    onChange={(item) => props.setDateState([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    ranges={props.dateState}
                    direction="horizontal"
                    months={2}
                    value={props.dateState}
                    editableDateInputs={true}
                    customStyles={{ position: 'absolute', top: '60px', right: '0px', zIndex: '10', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}
                // wrapperRef={props.wrapperRef}
                />
            ) : null}

        </div>
    )
}

export default DashboardTop