import React from 'react'
import ButtonField from '../../../Common/FormFields/ButtonField'
import ProfileIcon from '../../../Images/UserImages/userDummyImage.png'
import locationIcon from '../../../Images/vendors/location.svg'
import { Tooltip } from '@mui/material'

const UserCard = (props) => {

    const userDetailsP = `flex w-full gap-8 items-start justify-between font-[Roboto-Regular] text-sm text-[#111111]`
    const userDetailsSpan = ` w-[60%] text-left !text-gray-60`

    return (
        <div className='w-[22%] flex flex-col items-center bg-white border-[0.7px] border-solid border-[#b9c1ce80] rounded-[5px] px-2'>
            <img crossOrigin='anonymous' src={props.userData?.picture?.key !== null ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${props.userData?.picture?.key}&height=60&width=60` : ProfileIcon} alt="profileIcon" className={`w-20 h-20 rounded-full border mt-8`} />
            <div className={`flex flex-col items-center mb-10 mt-4`}>
                <p className={`mb-2 text-xl text-deepgray-5 font-[Roboto-Regular]`}>{props?.userData?.name ?? '--'}</p>
                {/* <p className={`flex gap-1 items-center`}>
                    <img src={locationIcon} alt='locationIcon' />
                    <span className={`text-sm text-grey-600 font-[Roboto-Regular]`}>{props?.userData?.location ?? '--'}</span>
                </p> */}
            </div>
            <div className={`w-full border-b border-solid border-[#b9c1ce80]`}></div>
            <div className={`w-full px-4 mt-4`}>
                <ButtonField
                    buttonextracls={`bg-seconderyColour-5 w-full rounded-full`}
                    buttonnamecls={` text-white text-sm py-[0.2rem] font-[Roboto-Medium] capitalize`}
                    buttonName="editprofile"
                    onClick={() => props.handleUserProfileEdit()}
                />
            </div>
            <div className={`w-full px-4 mt-8 flex flex-col gap-6`}>
                <p className={userDetailsP}>
                    <span>Phone:</span>
                    <span className={userDetailsSpan}>{props?.userData?.phoneNo ?? '--'}</span>
                </p>
                <div className={userDetailsP}>
                    <p className={`truncate`}>Email:</p>
                    <Tooltip title={`${props?.userData?.email ?? '--'}`}>
                    <p className={`${userDetailsSpan} truncate cursor-default`}>{props?.userData?.email ?? '--'}</p>
                    </Tooltip>
                </div>
                <p className={userDetailsP}>
                    <span>Unique ID:</span>
                    <span className={userDetailsSpan}>{props?.userData?.uniqueId ?? '--'}</span>
                </p>
                {/* <p className={userDetailsP}>
                    <span>Address:</span>
                    <span className={userDetailsSpan}>{props?.userData?.location ?? '--'}</span>
                </p> */}
            </div>
        </div>
    )
}

export default UserCard