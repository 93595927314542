import React, { useEffect } from 'react'
import AddSubAdmin from '../../Components/Admin/Settings/AddSubAdmin'
import { ForAdmin } from './Hooks';
import { useSelector } from 'react-redux';
import SnackBarComponent from '../../Common/Layout/Snackbar/SnackBar';
import { useNavigate } from 'react-router-dom';
import { useJwt } from 'react-jwt';

const SettingSubAdminIndex = () => {
    const { subAdminCreate,
        getAllUsers,
        showSubAdminForm,
        setShowSubAdminForm,
        toggleSubAdmin,
        subAdminUser,
        goBackToTable,
        getAllSubAdmin,
        editSubAdminForm,
        editSubAdminApiCall,
        editSubAdminFormData,
        deleteSubAdminApiCall,
        subAdminCreateMsg,
        adminSnackbar,
        messageClose,
        loading
    } = ForAdmin()

    const subAdminData = useSelector((state) => state.setting.subAdmin)
    useEffect(()=>{
        getAllSubAdmin()
    },[])

    const navigate = useNavigate()

    // For refresh token when actual token expires 
    let token = localStorage.getItem('token') ? localStorage.getItem('token') : "";
    const { isExpired } = useJwt(token);
    const checkTokenExpiration = () => {
        if (isExpired) {
            navigate('/login');
        }
    }
    return (
        <div onClick={() => checkTokenExpiration()}>
            <AddSubAdmin
                subAdminCreate={subAdminCreate}
                showSubAdminForm={showSubAdminForm}
                setShowSubAdminForm={setShowSubAdminForm}
                getAllUsers={getAllUsers}
                toggleSubAdmin={toggleSubAdmin}
                subAdminUser={subAdminUser}
                goBackToTable={goBackToTable}
                subAdminData={subAdminData}
                editSubAdminApiCall={editSubAdminApiCall}
                editSubAdminForm={editSubAdminForm}
                editSubAdminFormData={editSubAdminFormData}
                deleteSubAdminApiCall={deleteSubAdminApiCall}
                subAdminCreateMsg={subAdminCreateMsg}
                loading={loading}
            />
            <SnackBarComponent
                messageOpen={adminSnackbar.open}
                messageClose={messageClose}
                notificationText={adminSnackbar.message}
                subText={adminSnackbar.subText}
                alertType={adminSnackbar.alertType}
                borderClass={adminSnackbar.borderClass}
            />

        </div>
    )
}

export default SettingSubAdminIndex