import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const MultipleSelectCategory = (props) => {
    const theme = useTheme();
    // const editCategory = props.editServiceData?.category?.map((item) => item?.name)
    const [multipleCategory, setMultipleCategory] = React.useState(props.editServiceData?.category ? props.editCategory : []);
    
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setMultipleCategory(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const { t } = useTranslation()

    return (
        <div>
            <FormControl className={`w-full`}>
                <InputLabel id="demo-multiple-chip-label" className='bg-white'>{t(props.placeholder)}</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={multipleCategory}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {props.addCategory?.map((item, idx) => (
                        <MenuItem
                            key={idx}
                            value={item?.name}
                            style={getStyles(item?.name, multipleCategory, theme)}
                            onClick={() => { (item?.subCategory && props.onHandelClickData(item?.subCategory)); props.itemsId(item?.id); props.addCategoryValue(item?.name) }}
                        >
                            {item?.name}
                        </MenuItem>
                    ))}
                    {props.addsubCategory?.map((item, idx) => (
                        <MenuItem
                            key={idx}
                            value={item?.name}
                            style={getStyles(item?.name, multipleCategory, theme)}
                            onClick={() => { props.itemsId(item?.id); props.addSubCategoryValue(item?.name) }}
                        >
                            {item?.name}
                        </MenuItem>
                    ))}
                    {props.extraItem &&
                        < MenuItem className={props.extraItemCls}>
                            {props.extraItem}
                        </MenuItem>}
                </Select>
            </FormControl>
        </div>
    );
}

// eslint-disable-next-line react/no-typos
MultipleSelectCategory.PropTypes = {
    placeholder: PropTypes.string,
    addCategory: PropTypes.array,
    onHandelClickData: PropTypes.func,
    itemsId: PropTypes.func,
    addCategoryValue: PropTypes.func,
    addsubCategory: PropTypes.array,
    addSubCategoryValue: PropTypes.func,
    extraItem: PropTypes.bool,
    extraItemCls: PropTypes.string
}
export default MultipleSelectCategory