import React from 'react'
import { useSelector } from 'react-redux'
import CommonCard from '../../Common/Layout/Card/CommonCard'
import img1 from '../../Images/Vendor/offerimg1.png'
import img2 from '../../Images/Vendor/offerimg2.png'
import img3 from '../../Images/Vendor/offerimg3.png'
import contactvendorimg from '../../Images/Vendor/contactvendorimage.svg'
import trackicon from '../../Images/Vendor/trackicon.svg'
import text from "../../Common/Languages/en_US.json"
import MainHeader from '../../Common/Layout/MainHheader/MainHeader'
import LandingHeader from '../../Common/Layout/LandingHeader/LandingHeader'
import Footer from '../../Common/Layout/Footer/Footer'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { CircularProgress } from '@mui/material'
import { useState } from 'react'
import { ForVendor } from '../../Containers/VendorDetailPage/Hooks'
import { useEffect } from 'react'

const SeeAllPage = (props) => {
  const totalProductOfferData = useSelector((state) => state.vendor.offers)
  const { t, i18n } = useTranslation()
  const {
    getCreatedNewOffers,
    hasMore,
    increasePageNumber,
    loading
  } = ForVendor()

  useEffect(() => {
    getCreatedNewOffers()
  }, [])
  const itemContents = [
    {
      image: img1
    },
    {
      image: img2
    },
    {
      image: img3
    },
    {
      image: img3
    }
  ]

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className='sticky top-0 z-50'>
        <MainHeader />
        <LandingHeader
          searchBar={true}
          goTomessage={true}
          goToNotification={true}
          border={true}
          profile={true}
          detailsPage={props.detailsPage}
          allnotification={props.allnotification}
          showNotification={props.showNotification}
          handleclickNotification={props.handleshow}
          readNotificationApi={props.readNotificationApi}

        />
      </div>
      {/* For Offers */}
      <div className={`p-[4%] bg-white-5`}>
        <p onClick={props.backtoPage} className={`bg-primeryColour-5 font-[Roboto-Regular] rounded text-white w-20 py-1 text-center cursor-pointer`}>
          {"<-"} Back
        </p>
        <div className={`pb-[3%]`}>
          <p className={`text-center text-3xl font-[Roboto-Regular] font-normal text-deepgray-5 pb-2 lg:pb-0 cursor-pointer`}>{t("allNewOffers")}</p>
        </div>
        
        {loading === true ?
          <div className='flex h-[65vh] justify-center items-center'>
            <CircularProgress size={20} color='success' />
            <p className='pl-3'>Loading...</p>
          </div>
          :
          <InfiniteScroll
            dataLength={totalProductOfferData?.length} //This is important field to render the next data
            next={increasePageNumber}
            hasMore={hasMore}
            loader={<div className={`flex justify-center items-center`}>
              <p>Loading...</p>
            </div>}
          // endMessage={
          //   <p style={{ textAlign: 'center' }}>
          //     <p className='text-red-5'>No data left</p>
          //   </p>
          // }
          >
            <div className={`grid gap-2 md:gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 grid-rows-1`}>

              {totalProductOfferData?.map((item, idx) =>
                <div key={idx}>
                  {/* {showItems(item)} */}
                  <CommonCard
                    carouselimg={item?.product?.picture && item?.product?.picture?.length > 0 ? item?.product?.picture : itemContents}
                    carousel={item?.product?.picture && item?.product?.picture?.length > 0 ? true : false}
                    noContentImg={item?.product?.picture && item?.product?.picture?.length === 0 && true}
                    onHandelOpeModal={() => props.onHandelOpeModal(item?.user?.id)}
                    itemname={item?.product?.business?.name}
                    Shareicon={true}
                    carouselHeight="150px"
                    noContentCls={`h-[150px]`}
                    rating={true}
                    percentage={item?.percentage && true}
                    offerpercentage={item?.percentage}
                    price={item?.price && true}
                    offerprice={item?.price}
                    phone={true}
                    phonetext={item?.user?.phoneNo ? item?.user?.phoneNo : '--'}
                    location={true}
                    locationtext={(item?.product?.business?.lat !== null && item?.product?.business?.long !== null) && item.product?.business?.location ? item.product?.business?.location : "--"}
                    // distance={true}
                    // distancetext={item.distance ? item.distance : "--"}
                    hoverbutton={item?.user?.id !== parseInt(localStorage.getItem('userId')) ? true : false}
                    // hoverbuttonTrack={(item?.product?.business?.lat === null && item?.product?.business?.long === null) ? false : true}
                    hoverbuttonTrack={false}
                    buttonName2={"Contact Vendor"}
                    buttonimg={contactvendorimg}
                    buttonName3={"Track"}
                    buttonimg2={trackicon}
                    showlocationamount={true}
                    amount={item?.product?.price}
                    itemtext={true}
                    text1={item?.product?.name}
                    text2={item?.product?.description}
                    itememail={true}
                    email={item?.user?.email}
                    item={item}
                    extracls={`min-h-[30rem]`}
                  />
                </div>
              )}
            </div>
          </InfiniteScroll>
        }
      </div>
      <Footer />

    </div>
  )
}

export default SeeAllPage