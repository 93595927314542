import React from 'react'
import DDlandingBanner from '../../Images/LandingImages/DDlandingBanner.jpg'
import appStore from '../../Images/LandingImages/appStore.svg'
import playStore from '../../Images/LandingImages/playStore.svg'
import './LandingPage.css'
import ScrollTab from '../../Common/Layout/ScrollTab/ScrollTab'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

const BannerSection = (props) => {
    const { t, i18n } = useTranslation()
    const categories = useSelector(state => state.totalCategoryInAdmin.totalCategories)
    const subCatVendors = useSelector(state => state.landingData.subCatVendor)
  
    return (
        <>
            <div className={`h-full relative`}>
                <img src={DDlandingBanner} alt='DDlandingBanner' className='w-full h-[38rem]' />
                <div className={`flex flex-col w-4/5 h-full lg:w-3/5 justify-center text-left px-[4.5%] tracking-wide  text-white absolute top-0 ${i18n.dir() === 'rtl' ? 'bannerBackgroundForArabicCls' : 'bannerBackgroundCls'}`}>
                    <div className={`flex flex-col`}>
                        <p className={`font-[Roboto-Regular] leading-10 font-extrabold text-[30px] ${i18n.dir() === 'rtl' ? 'text-right' : ''}`}>{t("lookingForSomething")}</p>
                        <p className={`font-[Roboto-Light] leading-10 w-full lg:w-[65%] font-light text-[26px]`}>{t("weGotYou")}</p>
                    </div>
                    <div className={`flex items-center gap-4 py-7`}>
                        <div className={`h-11 w-fit flex justify-center items-center text-black bg-white rounded-full p-4 cursor-pointer`}>{t("postRequirement")}</div>
                        <div className={`h-11 w-fit flex justify-center items-center text-white bg-skyblue-6 rounded-full p-4 gap-4 cursor-pointer `}>
                            {t("downloadApp")}
                            <div className='flex items-center gap-3'>
                                <img src={appStore} alt='appStore' className='w-4 h-[19px]' />
                                <img src={playStore} alt='playStore' className='w-4 h-[19px]' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* scrolling tab */}
            <div id="landing-tabs">
                <ScrollTab categoriesArray={categories} scroll={true} scrollClickHandler={props.scrollClickHandler} getVendorsUnderSubCategory={props.getVendorsUnderSubCategory} subCatVendors={subCatVendors} />
            </div>
        </>
    )
}

export default BannerSection