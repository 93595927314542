import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react'
// import Shareicon from '../../Images/Vendor/shareicon.svg'
// import ratingicon from '../../Images/Vendor/ratingicon.svg'
// import defaultserviceIcon from '../../Images/vendors/defaultprofile.svg'


const Accordionbutton = (props) => {

  const handleChange = (panel) => (event, isExpanded) => {
    props.setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion expanded={props.expanded === props.panelItem ? true : false} onChange={handleChange(props.panelItem)} className={`${props.accordionMainCls}`}>
        <AccordionSummary sx={{width: "100%" , display: "flex" , alignItems:"baseline" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          // onClick={props.onClick ? props.onClick : ()=>{}}
        >
          <Typography sx={{width: "100%"}}>
          {props.AccordionSummary}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{border: "1px solid #EBF1F7" , boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)", display: "flex" , backgroundColor: "white", zIndex: "10", ...props.sx }}>
          <Typography>
           {props.AccordionDetails}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default Accordionbutton