import React, { useState } from 'react'
import FavoriteVendorsIcon from '../../../Images/UserImages/FavoriteVendors.svg'
import MessageIcon from '../../../Images/UserImages/Message.svg'
import YourRequirementsIcon from '../../../Images/UserImages/YourRequirements.svg'
import FavoriteVendorsIconActive from '../../../Images/UserImages/FavoriteVendorsActive.svg'
import MessageIconActive from '../../../Images/UserImages/MessageActive.svg'
import YourRequirementsIconActive from '../../../Images/UserImages/YourRequirementsActive.svg'
import { Link, useLocation } from 'react-router-dom'

const UserTab = () => {

    const location = useLocation()

    return (
        <div className={`flex gap-2`}>

            <Link to={`/user`} className={location.pathname === `/user` ? "active" : "buttonactive"} >
                <img src={location.pathname === `/user` ? YourRequirementsIconActive : YourRequirementsIcon} alt="YourRequirementsIcon" />
                <p>{`My Requirements`}</p>
            </Link>

            <Link to={`/user/FavoriteVendors`} className={location.pathname === `/user/FavoriteVendors` ? "active" : "buttonactive"}>
                <img src={location.pathname === `/user/FavoriteVendors` ? FavoriteVendorsIconActive : FavoriteVendorsIcon} alt="FavoriteVendorsIcon" />
                <p>{`Favourite Vendors`}</p>
            </Link>

            <Link to={`/user/message`} className={location.pathname === `/user/message` ? "active" : "buttonactive"}>
                <img src={location.pathname === `/user/message` ? MessageIconActive : MessageIcon} alt="MessageIcon" />
                <p>{`Message`}</p>
            </Link>


        </div>
    )
}

export default UserTab