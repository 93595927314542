import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    accountDetails: [],
    businessDetails: [],
    location: '',
    loading: false,
    error: false,
};

export const vendorAccountSlice = createSlice({
    name: 'vendorAccount',
    initialState,
    reducers: {
        getVendorAccount: (state, action) => {
            state.accountDetails = action.payload;
            state.loading = true;
            state.error = false;
        },
        getVendorBusinessDetails: (state, action) => {
            state.businessDetails = action.payload;
            state.loading = false;
            state.error = false;
        },
        getLocationReducer: (state, action) => {
            state.location = action.payload;
            state.loading = false;
            state.error = false;
        },
    }
})

export const { getVendorAccount ,getVendorBusinessDetails, getLocationReducer } = vendorAccountSlice.actions;
export default vendorAccountSlice.reducer;