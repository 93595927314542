import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { SUB_ADMIN_API } from '../../Constant';
import { doDeleteApiCall, doGetApiCall, doPutApiCall } from '../../Utils/ApiConfig';
import { getAllNotification } from './NotificationReducer';


export const ForNotification = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showNotification, setShowNotification] = useState(false)

  const handleshow = () => {
    setShowNotification(!showNotification);
  }
  const allnotification = () => {
    navigate("/allnotification")

  }
  const backtohomePage = () => {
    navigate("/")
  }
  const detailsPage = () => {
    navigate('/notificationDetails')
  }


  const getNotification = async () => {
    let data = {
      url: `${SUB_ADMIN_API}/notifications?dataPerPage=200&page=1`,
    }
    let res = await doGetApiCall(data)
    if (!res.error) {
      dispatch(getAllNotification(res.result))
    }
  }

  /**
  * @author uplRanabir
  * @method DELETE
  * @description- this function is used to delete notifications
  */
  const deleteNotificationApi = (notificationData) => {
    let data = {
      url: `${SUB_ADMIN_API}/notifications/type=all`,
      bodyData: {
        "notificationIds": [
          notificationData?.id
        ]
      }
    }
    doDeleteApiCall(data)
      .then((res) => {
        if (!res.error) {
          getNotification()
        }
      })
      .catch(err => {
        console.log(err, "error")
      })
  }

  /**
  * @author uplRanabir
  * @method PUT
  * @description- this function is used to read notifications
  */
  const readNotificationApi = (readType,notificationData) => {
    let data = {
      url: `${SUB_ADMIN_API}/notifications?type=${readType}`,
      bodyData: {
        notificationId:  notificationData?.id
      }
    }
    doPutApiCall(data)
      .then((res) => {
        if (!res.error) {
          getNotification()
        }
      })
      .catch(err => {
        console.log(err, "error")
      })
  }

  return {
    handleshow,
    showNotification,
    allnotification,
    backtohomePage,
    detailsPage,
    getNotification,
    readNotificationApi,
  }

}
