import React, { useEffect, useState } from 'react'
import CommonCard from '../../Common/Layout/Card/CommonCard'
import img1 from '../../Images/Vendor/offerimg1.png'
import img2 from '../../Images/Vendor/offerimg2.png'
import img3 from '../../Images/Vendor/offerimg3.png'
import contactvendorimg from '../../Images/Vendor/contactvendorimage.svg'
import trackicon from '../../Images/Vendor/trackicon.svg'
import text from "../../Common/Languages/en_US.json"
import { useSelector } from 'react-redux'
import SeeAllPage from './SeeAllPage'
import { useTranslation } from 'react-i18next'

const ServiceOffer = (props) => {
    const serviceOfferData = useSelector((state) => state.vendor.serviceOffers)
    const serviceOfferSlicedData = serviceOfferData?.slice(0, 4)

    const { t, i18n } = useTranslation()
    const itemContents = [
        {
            image: img1
        },
        {
            image: img2
        },
        {
            image: img3
        },
        {
            image: img3
        }
    ]

    return (
        <div className={`p-[4%] bg-white-5`}>
            <div className={`flex  flex-row justify-between items-center pb-[3%]`}>
                <p className={`text-left text-3xl font-[Roboto-Regular] font-normal text-deepgray-5 pb-2 lg:pb-0`}>{t("serviceOffer")}</p>
                <p className={`text-left text-base font-[Roboto-Regular] font-normal text-blue-5 cursor-pointer`} onClick={props.handleAllServiceOffer}>{t("seeall")} {'>'} </p>
            </div>

            <div className={`grid gap-2 md:gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 grid-rows-1`}>
                {serviceOfferSlicedData?.map((item, idx) =>
                    <div key={idx}>
                        <CommonCard
                            carouselimg={item?.services?.thumbnail && item?.services?.thumbnail?.length > 0 ? item?.services?.thumbnail : itemContents}
                            carousel={item?.services?.thumbnail && item?.services?.thumbnail?.length > 0 ? true : false}
                            noContentImg={item?.services?.thumbnail && item?.services?.thumbnail?.length === 0 && true}
                            onHandelOpeModal={() => props.onHandelOpeModal(item?.user?.id)}
                            itemname={item?.services?.business?.name}
                            Shareicon={true}
                            carouselHeight="150px"
                            noContentCls={`h-[150px]`}
                            rating={true}
                            percentage={item?.percentage && true}
                            offerpercentage={item?.percentage}
                            price={item?.price && true}
                            offerprice={item?.price}
                            phone={true}
                            phonetext={item?.user?.phoneNo ? item?.user?.phoneNo : '--'}
                            location={true}
                            locationtext={(item?.services?.business?.lat !== null && item?.services?.business?.long !== null) && item.services?.business?.address ? item.services?.business?.address : "--"}
                            // distance={true}
                            // distancetext={item.distance ? item.distance : "--"}
                            hoverbutton={item?.user?.id !== parseInt(localStorage.getItem('userId')) ? true : false}
                            // hoverbuttonTrack={(item?.services?.business?.lat === null && item?.services?.business?.long === null) ? false : true}
                            hoverbuttonTrack={false}
                            buttonName2={"Contact Vendor"}
                            buttonimg={contactvendorimg}
                            buttonName3={"Track"}
                            buttonimg2={trackicon}
                            showlocationamount={true}
                            amount={item?.services?.amount}
                            itemtext={true}
                            text1={item?.services?.name}
                            text2={item?.services?.description}
                            itememail={true}
                            email={item?.user?.email}
                            item={item}
                            extracls={`min-h-[30rem]`}
                        />
                    </div>
                )}
            </div>


        </div>
    )

}

export default ServiceOffer