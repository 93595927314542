import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { doDeleteApiCall, doGetApiCall, doUploadMediaApiCall } from "../../Utils/ApiConfig"
import { setUploadedData, setUploadedLogo, setUploadMultipleImg, setMediaLoading, setEditMultipleImg } from './reducer/UploadMediaReducer'

export const ForUploadMediaHooks = () => {

    const dispatch = useDispatch()
    // const [thumbnail, setThumbnail] = useState(null)
    // const [uploadData, setUploadData] = useState([]);

    const documentUpload = useSelector((state) => state.upload.uploadedData)
    const logoUpload = useSelector((state) => state.upload.uploadedLogo)
    const multipleUpload = useSelector((state) => state.upload.uploadMultipleImg)

    // const editMultiUpload = useSelector((state) => state.upload.editMultiImg)
    // console.log(multipleUpload, "# multipleUpload")

    //post api call for posting document and images
    const doUploadedMedia = async (imgData) => {
        let extension = imgData[0].type.split('/');
        if (extension[1] === 'pdf' || extension[1] === 'jpeg') {
            let formData = new FormData();
            formData.append("file", imgData[0]);
            let data = {
                url: `${process.env.REACT_APP_DG_BASE_URL}/uploads`,
                bodyData: formData
            }
            let res = await doUploadMediaApiCall(data)
            if (res?.result) {
                dispatch(setUploadedData(res?.result[0]))
            }
        }
        else {
            console.log("Error")
        }
    }

    //post api call for posting logo
    const doUploadedLogo = async (logoData) => {
        let extension = logoData[0].type.split('/');
        if (extension[1] === 'png' || extension[1] === 'jpeg') {
            let formData = new FormData();
            formData.append("file", logoData[0]);
            let data = {
                url: `${process.env.REACT_APP_DG_BASE_URL}/uploads`,
                bodyData: formData
            }
            let res = await doUploadMediaApiCall(data)
            if (res.result) {
                dispatch(setUploadedLogo(res.result[0]))
            }
        }
    }


    //post api call for posting multiple images altogether - NEW CODE
    const multipleMediaApiCall = async (multiData) => {
        dispatch(setMediaLoading(true))
        let formData = new FormData();
        multiData.forEach(item => {
            formData.append("file", item);
        });
        let data = {
            url: `${process.env.REACT_APP_DG_BASE_URL}/uploads`,
            bodyData: formData
        }
        let res = await doUploadMediaApiCall(data)
        if (res?.result) {
            let tempArr = [...multipleUpload]
            res?.result?.forEach(element => {
                let obj = {
                    name: element?.originalname,
                    key: element?.key,
                    mimetype: element?.mimetype,
                    location: element?.location,
                    size: element?.size,
                }
                tempArr.push(obj)
            });
            dispatch(setUploadMultipleImg(tempArr))
        }
    }

    //post api call for posting multiple images one by one - OLD CODE
    // const multipleMediaApiCall = async (multiData) => {
    //     dispatch(setMediaLoading(true))
    //     let formData = new FormData();
    //     formData.append("file", multiData[0]);
    //     let data = {
    //         url: `${process.env.REACT_APP_DG_BASE_URL}/uploads`,
    //         bodyData: formData
    //     }
    //     let res = await doUploadMediaApiCall(data)
    //     if (res?.result) {
    //             let tempArr = [...multipleUpload]
    //             let obj = {
    //                 name: res?.result[0]?.originalname,
    //                 key: res?.result[0]?.key,
    //                 mimetype: res?.result[0]?.mimetype,
    //                 location: res?.result[0]?.location,
    //                 size: res?.result[0]?.size,
    //             }
    //             tempArr.push(obj)
    //             dispatch(setUploadMultipleImg(tempArr))
    //     }
    // }

    const deleteUploadedMediaApiCall = async (id, key, type) => {
        let data = {
            url: `${process.env.REACT_APP_DG_BASE_URL}/uploads/${id}?key=${key}&type=${type}`,
        }
        let res = await doDeleteApiCall(data)
    }

    const cleanupMedia = () => {
        // localStorage.clear()
        dispatch(setUploadedData({}))
        dispatch(setUploadedLogo({}))
        dispatch(setUploadMultipleImg([]))
        dispatch(setEditMultipleImg([]))
    }

    return {
        doUploadedMedia,
        doUploadedLogo,
        multipleMediaApiCall,
        multipleUpload,
        // uploadData,
        documentUpload,
        logoUpload,
        cleanupMedia,
        deleteUploadedMediaApiCall,
        // editMultiUpload
    }
}