import { doGetApiCall } from "./ApiConfig"
import moment from 'moment'

export const isAccess = (type, action, subsData) => {
    try {

        // return true -- Subscription limit reached or expired
        //return false --Subscription not expire and limit not reached

        if (subsData?.subscription && subsData?.userDataCount) {
            if (moment().isAfter(subsData?.subscription?.expireIn)) {
                return true
            } else {
                switch (type) {
                    case "service":
                        if (subsData?.subscription?.serviceOffered === subsData?.userDataCount?.serviceAddedCount) {
                            return true;
                        } else return false;
                    case "requirement":
                        if (subsData?.subscription?.requirements === subsData?.userDataCount?.requirementAddedCount) {
                            return true;
                        } else return false;
                    case "offer":
                        if (subsData?.subscription?.offers === subsData?.userDataCount?.offersAddedCount) {
                            return true;
                        } else return false;
                    default:
                        return true
                }
            }

        } else return true

    } catch (error) {
        console.log(error, "Error in is access. FUN: isAccess")
    }
}