import moment from 'moment';
import React from 'react'
import Countdown from 'react-countdown';

const ReactCountdown = (props) => {

    // This variable is giving the difference between current date-time and combined end date-time
    const combinedEndDateTime = moment(props.endDate).format('YYYY-MM-DD') + 'T' + props.endTime + ':00'

    // If the difference is positive then only then the value is returned, otherwise the value returned will be 0
    const difference = (a, b) => {
        let result;
        if (a > b) {
            result = Math.abs(a - b);
        } else {
            result = 0;
        }
        return result
    }

    // Checks the timeZone the user is in 
    // const checkTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    // // Get the timezone offset in minutes (Kuwait is UTC+3)
    // const timezoneOffset = 2.5 * 60;

    // // Create a new date object with the timezone offset
    // const kuwaitDate = new Date(new Date().getTime() - (timezoneOffset * 60 * 1000));

    // Calculation for the time difference wrt timeZone
    const timeDifference = difference(new Date(combinedEndDateTime), new Date())
    
    // const timeDifference = checkTimeZone === 'Asia/Calcutta' ?
    //     difference(new Date(combinedEndDateTime), new Date())
    //     :
    //     difference(new Date(combinedEndDateTime), kuwaitDate);

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return `Time Left: ${`--`} D : ${`--`} Hr : ${`--`} Min : ${`--`} s`;
        } else {
            return `Time Left: ${days} D : ${hours} Hr : ${minutes} Min : ${seconds} s`;
        }
    };
    return (
        <Countdown
            date={Date.now() + timeDifference}
            renderer={renderer}
        />
    )
}

export default ReactCountdown