import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    subscriptionList: [],
    loading: false,
    error: false,
}

export const subscriptionListSlice = createSlice({
    name: 'subscriptionList',
    initialState,
    reducers: {
        getSubscriptionList: (state, action) => {
            state.subscriptionList = action.payload;
            state.loading = false;
            state.error = false;
        }
    }
})

export const { getSubscriptionList } = subscriptionListSlice.actions;
export default subscriptionListSlice.reducer;