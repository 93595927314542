// import { CircularProgress, Grid, InputAdornment } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import ButtonField from '../../../Common/FormFields/ButtonField'
import InputField from '../../../Common/FormFields/InputField'
import CheckBox from "../../../Common/FormFields/CheckBox"
// import locationicon from "../../../Images/Vendor/distance.svg"
import UploadButton from "../../../Common/UiComponents/UploadButton"
import fileUploadIcon from "../../../Images/SubAdmin/fileUploadIcon.svg"
import MultipleSelect from '../../../Common/FormFields/MultipleSelect'
import DropDownField from '../../../Common/FormFields/DropDownField'
// import texts from '../../../Common/Languages/en_US.json'
import UploadMultipleImage from '../../../Common/Layout/UploadMultipleImage/UploadMultipleImage'
import { useDispatch, useSelector } from 'react-redux'
import MuiDatePicker from '../../../Common/FormFields/MuiDatePicker'
import locationImage from "../../../Images/Setting/locationImg.svg"
import text from '../../../Common/Languages/en_US.json'
import MuiTimePicker from '../../../Common/FormFields/MuiTimePicker'
import CloseIcon from '@mui/icons-material/Close';
import pdficon from '../../../Images/CommonImages/pdficon.svg'
import { setUploadMultipleImg, setUploadedData, setUploadedLogo } from '../../../Containers/UploadMedia/reducer/UploadMediaReducer'
import { ForUploadMediaHooks } from '../../../Containers/UploadMedia/Hooks'
import { useTranslation } from 'react-i18next'
import TextFieldInput from '../../../Common/FormFields/TextFieldInput'
import { Autocomplete, GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api'
import { ForSignup } from '../../../Containers/Authentication/Signup/Hook'
import { useLocation } from 'react-router-dom'
import { ForVendor } from '../../../Containers/VendorDetailPage/Hooks'

const CreateAddress = (props) => {

    const {
        multipleUpload,
        // documentUpload,
        // logoUpload
    } = ForUploadMediaHooks()
    const { getLocationData, mapLocationTxt } = ForSignup()
    const { getBusiness } = ForVendor()
    const { t } = useTranslation()

    const libraries = ['places']

    // Reducer for loading while image upload is in progress 
    const loading = useSelector((state) => state.upload.loading)
    const multipleUploadState = [...multipleUpload]

    const dispatch = useDispatch()
    const routeLocation = useLocation()

    const [check, setCheck] = useState(false) // state for signup checkbox
    const [uploadDocuments, setUploadDocuments] = useState([])
    const [uploadLogoProfile, setUploadLogoProfile] = useState([])
    const [addressdata, setAddressdata] = useState({
        name: "",
        governorate: null,
        location: "",
        displayName: "",
        block: "",
        fullAddress: "",
        street: "",
        buildingNo: "",
        floor: "",
        officeNo: "",
        places: null,
        date: null,
        startTime: null,
        endTime: null,
        description: ""
    })
    const [addLocation, setAddLocation] = useState()
    const [input, setInput] = useState();

    const [openMap, setOpenMap] = useState(false)
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
        libraries: libraries
    })
    let center = React.useMemo(() => ({
        lat: parseFloat(sessionStorage.getItem('latitude')),
        lng: parseFloat(sessionStorage.getItem('longitude'))
    }), [])
    const [marker, setMarker] = useState({})

    const onMapClick = React.useCallback((event) => {
        getLocationData(event.latLng.lat(), event.latLng.lng())
        setMarker({
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
            address: mapLocationTxt,
        });
    }, []);

    const [map, setMap] = useState(null);
    const [autocomplete, setAutocomplete] = useState(null);

    const onLoad = (map) => {
        setMap(map);
    };

    const onAutocompleteLoad = (autocomplete) => {
        setAutocomplete(autocomplete);
    };

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            if (place.geometry) {
                map.panTo(place.geometry.location);
                map.setZoom(10);
                setMarker({
                    lat: place.geometry.location?.lat(),
                    lng: place.geometry.location?.lng(),
                    address: place?.formatted_address,
                });
            } else {
                alert('Place not found');
            }
        } else {
            alert('Autocomplete not loaded yet');
        }
    };

    const { handleSubmit, reset, control, formState: { errors } } = useForm({
        defaultValues: {
            location: props.mapLocationTxt,
            fullAddress: props.mapLocationTxt ? props.mapLocationTxt : ''
        }
    });

    const handleError = (errors) => { console.log(errors, 'errors') };
    const onSubmit = (data) => {
        setAddressdata(data)
        props.businessAddAddress(data, marker)
        routeLocation?.pathname === '/vendor' && getBusiness()
        reset(addressdata)
    }

    //this function is use for check the terms and condition

    const handleChecking = (e) => {
        setCheck(e.target.checked)
    }

    const governorateArray = [
        {
            name: 'Farwaniya',
            value: 'Farwaniya',
            FarwaniyaLocations: [
                { name: 'Jaleeb Al Shuwaikh', value: 'Jaleeb Al Shuwaikh' },
                { name: 'Dajeej', value: 'Dajeej' },
                { name: 'Ardiya', value: 'Ardiya' },
                { name: 'Riggae', value: 'Riggae' },
                { name: 'Farwaniya', value: 'Farwaniya' },
                { name: 'Kaithan', value: 'Kaithan' },
                { name: 'Al Rai', value: 'Al Rai' }
            ]
        },
        {
            name: 'Al Asimah',
            value: 'Al Asimah',
            AlAsimahLocations: [
                { name: 'Shuwaikh', value: 'Shuwaikh' }
            ]
        },
        {
            name: 'Hawally',
            value: 'Hawally',
            HawallyLocations: [
                { name: 'Hawally', value: 'Hawally' },
                { name: 'Jabriya', value: 'Jabriya' },
                { name: 'Salmiya', value: 'Salmiya' },
                { name: 'Shaab', value: 'Shaab' }
            ]
        },
        {
            name: 'Mubarak Al Kabeer',
            value: 'Mubarak Al Kabeer',
            MubarakAlKabeerLocations: [
                { name: 'Sabah Al Salem', value: 'Sabah Al Salem' },
                { name: 'Kuwait City', value: 'Kuwait City' },
                { name: 'Qurain', value: 'Qurain' }
            ]
        },
        {
            name: 'Ahmadi',
            value: 'Ahmadi',
            AhmadiLocations: [
                { name: 'Mahboula', value: 'Mahboula' },
                { name: 'Mangaf', value: 'Mangaf' },
                { name: 'Fahaheel', value: 'Fahaheel' },
                { name: 'Ahmadi', value: 'Ahmadi' }
            ]
        },
        {
            name: 'Jahra', value: 'Jahra',
            JahraLocations: [
                { name: 'Jahra', value: 'Jahra' },
            ]
        },
    ]

    const noLocationFound = [
    ]

    const removeParticularThumbnail = (idx) => {
        if (idx > -1) {
            let arr;
            arr = [...multipleUpload]
            arr.splice(idx, 1);
            dispatch(setUploadMultipleImg(arr))
        }
    }

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          event.preventDefault();
          event.returnValue = '';
          return 'Are you sure you want to leave? Your vendor sign-up process may not be complete.';
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <>

            {/* middleside start*/}


            <div className={`flex w-full px-[5%] py-[4.5%] flex-col items-center md:items-stretch`}>
                <form onSubmit={handleSubmit(onSubmit, handleError, reset)} >
                    <div className={`w-full flex justify-between`}>
                        <div className={`w-1/2`}>
                            <p className={`font-[Roboto-Medium] text-base text-left text-Grey-900`}>{props.showAddAddress === true ? 'Almost There!' : 'Seems like you did not create any business, please create your business'} </p>
                            <div className={`flex flex-col items-start justify-evenly  `}>
                                {/* Field for bussiness Name */}

                                <div className={`w-full my-3 `}>
                                    <Controller
                                        name={"name"}
                                        control={control}
                                        rules={{
                                            required: true,
                                            pattern: /^[a-zA-Z][a-zA-Z ]*/i,
                                            maxLength: 50,
                                            minLength: 2
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <InputField
                                                extracls={`text-sm `}
                                                placeholder="enterBusinessName"
                                                textinputname="name"
                                                variant="outlined"
                                                type="text"
                                                id="name"
                                                onChange={onChange}
                                                value={value}
                                                floatingLabel={'enterBusinessName'}
                                            />
                                        )}
                                    />
                                    <small className={`text-red-500 `}>
                                        {errors?.name && errors?.name?.type === "required" && (<p>This field is required</p>)}
                                        {errors?.name && errors?.name?.type === "maxLength" && (<p>Too Long</p>)}
                                        {errors?.name && errors?.name?.type === "minLength" && (<p>Too Short</p>)}
                                        {errors?.name && errors?.name?.type === "pattern" && (
                                            <p>Please enter a valid Business Name</p>
                                        )}
                                    </small>
                                </div>

                                {/* section for governorate */}

                                <div className={`w-full my-3  flex flex-col`}>
                                    <Controller
                                        name={"governorate"}
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <DropDownField
                                                name='governorate'
                                                id='governorate'
                                                dropDownRootCls={`governorateCls h-[3.5em]`}
                                                size="small"
                                                selectOption={governorateArray}
                                                placeholder={`enterGovernorate`}
                                                // option={value}
                                                // handleChange={onChange}
                                                option={value ? value : addLocation}
                                                handleChange={(e) => { setAddLocation(e.target.value); onChange(e.target.value); }}
                                                label={t(`enterGovernorate`)}
                                            />
                                        )}
                                    />
                                    <small className={`text-red-500`}>
                                        {errors?.governorate && errors?.governorate?.type === "required" && (<p>This field is required</p>)}

                                    </small>

                                </div>

                                {/* section for selecting multiple places */}
                                <div className={`w-full  my-3 flex flex-col`}>
                                    <Controller
                                        name={"places"}
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <MultipleSelect
                                                // size="small"
                                                multiple={false}
                                                name="places"
                                                id='places'
                                                label={t(`selectPlace`)}
                                                value={value ? value : input}
                                                onChange={(e, v) => { setInput(v.name); onChange(v.name); }}
                                                // label={`selectPlace`}
                                                isOptionEqualToValue={(option, value) => { return option?.name === value?.name; }}
                                                placeholder={`selectPlace`}
                                                autocompleteExtraCls={`selectLocationCls`}
                                                autocompleteArray={
                                                    addLocation === 'Farwaniya' ? governorateArray[0].FarwaniyaLocations
                                                        :
                                                        addLocation === 'Al Asimah' ? governorateArray[1].AlAsimahLocations
                                                            :
                                                            addLocation === 'Hawally' ? governorateArray[2].HawallyLocations
                                                                :
                                                                addLocation === 'Mubarak Al Kabeer' ? governorateArray[3].MubarakAlKabeerLocations
                                                                    :
                                                                    addLocation === 'Ahmadi' ? governorateArray[4].AhmadiLocations
                                                                        :
                                                                        addLocation === 'Jahra' ? governorateArray[5].JahraLocations
                                                                            : noLocationFound
                                                }

                                            />
                                        )}
                                    />
                                    <small className={`text-red-500`}>
                                        {errors?.places && errors?.places?.type === "required" && (<p>This field is required</p>)}

                                    </small>

                                </div>

                                {/* Field for location */}
                                <div className={`w-full my-3 `}>
                                    <Controller
                                        name={"location"}
                                        // defaultValue={props.mapLocationTxt}
                                        control={control}
                                        rules={{
                                            required: (mapLocationTxt && mapLocationTxt?.length > 0) ? false : true,
                                            pattern: /^[ A-Za-z0-9_@./,#&+-]*$/,
                                            minLength: 2,
                                            maxLength: 100
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <InputField
                                                extracls={`text-sm `}
                                                placeholder={`enterlocation`}
                                                textinputname="name"
                                                variant="outlined"
                                                type="text"
                                                id="location"
                                                onChange={onChange}
                                                value={mapLocationTxt ? mapLocationTxt : value}
                                                // disabled={props.mapLocationTxt ? true : false}
                                                floatingLabel={'enterlocation'}
                                                endAdornment={true}
                                                inputEndAdornment={
                                                    <div className={`flex justify-center items-center`}>
                                                        {props.mapLocationTxt && props.mapLocationTxt?.length > 0 &&
                                                            <CloseIcon
                                                                sx={{ fontSize: "20px", cursor: "pointer" }}
                                                                onClick={() => props.setMapLocationTxt('')}
                                                            />
                                                        }
                                                        {!value &&
                                                            // <img src={locationImage} alt="locationImage" className='cursor-pointer' onClick={props.handleClickLocation} />
                                                            <img src={locationImage} alt="locationImage" className='cursor-pointer' onClick={() => setOpenMap(true)} />
                                                        }
                                                    </div>
                                                }

                                            />
                                        )}
                                    />
                                    {/* <p className={`text-red-500 text-xs mt-2`}>Caution: Once you click on the location icon, the input field will be disabled and cannot be reversed back</p> */}
                                    <small className={`text-red-500 `}>
                                        {errors?.location && errors?.location?.type === "required" && (<p>This field is required</p>)}
                                        {errors?.location && errors?.location?.type === "maxLength" && (<p>Too Long</p>)}
                                        {errors?.location && errors?.location?.type === "minLength" && (<p>Too Short</p>)}
                                    </small>
                                </div>
                                {openMap &&
                                    <>
                                        {isLoaded &&
                                            <GoogleMap
                                                mapContainerStyle={{
                                                    height: '400px',
                                                    width: '100%',
                                                    position: 'relative'
                                                }}
                                                zoom={10}
                                                center={center}
                                                onClick={onMapClick}
                                                onLoad={onLoad}
                                            >
                                                <MarkerF
                                                    position={{ lat: marker.lat, lng: marker.lng }}
                                                />
                                                <Autocomplete
                                                    onLoad={onAutocompleteLoad}
                                                    onPlaceChanged={onPlaceChanged}
                                                >
                                                    <input
                                                        type="text"
                                                        placeholder="Enter any place to search"
                                                        style={{
                                                            boxSizing: `border-box`,
                                                            border: `1px solid transparent`,
                                                            width: `250px`,
                                                            height: `50px`,
                                                            padding: `0 12px`,
                                                            borderRadius: `3px`,
                                                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                                            fontSize: `14px`,
                                                            outline: `none`,
                                                            textOverflow: `ellipses`,
                                                            position: 'absolute',
                                                            left: '50%',
                                                            top: '15%',
                                                            marginLeft: '-120px',
                                                        }}
                                                    />
                                                </Autocomplete>
                                            </GoogleMap>
                                        }
                                    </>
                                }

                                {/* Field for Full address */}
                                <div className={`w-full my-3 `}>
                                    <Controller
                                        name={"fullAddress"}
                                        control={control}
                                        // defaultValue={props.mapLocationTxt}
                                        rules={{
                                            required: (props.mapLocationTxt && props.mapLocationTxt?.length > 0) ? false : true,
                                            pattern: /^[ A-Za-z0-9_@./,#&+-]*$/,
                                            minLength: 2,
                                            maxLength: 100
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <InputField
                                                extracls={` text-sm `}
                                                placeholder="enterFullAddr"
                                                textinputname="fullAddress"
                                                variant="outlined"
                                                type="text"
                                                id="fullAddress"
                                                onChange={onChange}
                                                value={value}
                                                // defaultValue={props.mapLocationTxt ? props.mapLocationTxt : value}
                                                floatingLabel={'enterFullAddr'}
                                            />
                                        )}
                                    />
                                    <small className={`text-red-500`}>
                                        {errors?.fullAddress && errors?.fullAddress?.type === "required" && (<p>This field is required</p>)}
                                        {errors?.fullAddress && errors?.fullAddress?.type === "maxLength" && (<p>Too Long</p>)}
                                        {errors?.fullAddress && errors?.fullAddress?.type === "minLength" && (<p>Too Short</p>)}
                                        {errors?.fullAddress && errors?.fullAddress?.type === "pattern" && (
                                            <p>Please enter a valid name</p>
                                        )}
                                    </small>
                                </div>

                                {/* Field for Street */}
                                <div className={`w-full my-3 `}>
                                    <Controller
                                        name={"street"}
                                        control={control}
                                        rules={{
                                            required: true,
                                            pattern: /^[ A-Za-z0-9_@./,#&+-]*$/,
                                            minLength: 2,
                                            maxLength: 100
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <InputField
                                                extracls={` text-sm `}
                                                placeholder="enterStreet"
                                                textinputname="street"
                                                variant="outlined"
                                                type="text"
                                                id="street"
                                                onChange={onChange}
                                                value={value}
                                                floatingLabel={'enterStreet'}

                                            />
                                        )}
                                    />
                                    <small className={`text-red-500`}>
                                        {errors?.street && errors?.street?.type === "required" && (<p>This field is required</p>)}
                                        {errors?.street && errors?.street?.type === "maxLength" && (<p>Too Long</p>)}
                                        {errors?.street && errors?.street?.type === "minLength" && (<p>Too Short</p>)}
                                        {errors?.street && errors?.street?.type === "pattern" && (
                                            <p>Please enter a valid name</p>
                                        )}
                                    </small>
                                </div>

                                {/* Fields for entering block and building no. */}
                                <div className={`flex w-full gap-4`}>
                                    {/* Field for entering block */}
                                    <div className={(props?.typecheck === "business" || localStorage.getItem('businessType') === 'business') ? `w-full my-3 ` : `w-[49%] my-3`}>
                                        <Controller
                                            name={"block"}
                                            control={control}
                                            rules={{
                                                required: true,
                                                pattern: /^[ A-Za-z0-9_@./,#&+-]*$/
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <InputField
                                                    extracls={` text-sm `}
                                                    placeholder="enterBlock"
                                                    textinputname="block"
                                                    variant="outlined"
                                                    type="text"
                                                    id="block"
                                                    onChange={onChange}
                                                    value={value}
                                                    floatingLabel={'enterBlock'}
                                                />
                                            )}
                                        />
                                        <small className={`text-red-500`}>
                                            {errors?.block && errors?.block?.type === "required" && (<p>This field is required</p>)}
                                            {errors?.block && errors?.block?.type === "pattern" && (
                                                <p>Please enter a valid name</p>
                                            )}
                                        </small>
                                    </div>
                                    {/* Field for building no. */}
                                    {(props?.typecheck === "business" || localStorage.getItem('businessType') === 'business') ?
                                        <div className={`w-full my-3 `}>
                                            <Controller
                                                name={"buildingNo"}
                                                control={control}
                                                rules={{
                                                    required: false,
                                                    pattern: /^[ A-Za-z0-9_@./,#&+-]*$/
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputField
                                                        extracls={` text-sm `}
                                                        placeholder="enterBuildNo"
                                                        textinputname="buildingNo"
                                                        variant="outlined"
                                                        type="text"
                                                        id="buildingNo"
                                                        onChange={onChange}
                                                        value={value}
                                                        floatingLabel={'enterBuildNo'}
                                                    />
                                                )}
                                            />
                                            <small className={`text-red-500`}>
                                                {errors?.buildingNo && errors?.buildingNo?.type === "required" && (<p>This field is required</p>)}
                                                {errors?.buildingNo && errors?.buildingNo?.type === "pattern" && (
                                                    <p>Please enter a valid name</p>
                                                )}
                                            </small>
                                        </div> : null}
                                </div>

                                {/* Fields for entering floor and office no.  */}
                                {(props?.typecheck === "business" || localStorage.getItem('businessType') === 'business') ?
                                    <div className={`flex w-full gap-4`}>
                                        {/* Field for Floor */}
                                        <div className={`w-full my-3 `}>
                                            <Controller
                                                name={"floor"}
                                                control={control}
                                                rules={{
                                                    required: false,
                                                    pattern: /^[ A-Za-z0-9_@./,#&+-]*$/
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputField
                                                        extracls={` text-sm `}
                                                        placeholder="enterFloor"
                                                        textinputname="floor"
                                                        variant="outlined"
                                                        type="text"
                                                        id="floor"
                                                        onChange={onChange}
                                                        value={value}
                                                        floatingLabel={'enterFloor'}
                                                    />
                                                )}
                                            />
                                            <small className={`text-red-500`}>
                                                {errors?.floor && errors?.floor?.type === "required" && (<p>This field is required</p>)}
                                                {errors?.floor && errors?.floor?.type === "pattern" && (
                                                    <p>Please enter a valid name</p>
                                                )}
                                            </small>
                                        </div>
                                        {/* Field for Office Number */}
                                        <div className={`w-full my-3 `}>
                                            <Controller
                                                name={"officeNo"}
                                                control={control}
                                                rules={{
                                                    required: false,
                                                    pattern: /^[ A-Za-z0-9_@./,#&+-]*$/
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputField
                                                        extracls={` text-sm `}
                                                        placeholder="enterOffNo"
                                                        textinputname="officeNo"
                                                        variant="outlined"
                                                        type="text"
                                                        id="officeNo"
                                                        onChange={onChange}
                                                        value={value}
                                                        floatingLabel={'enterOffNo'}
                                                    />
                                                )}
                                            />
                                            <small className={`text-red-500`}>
                                                {/* {errors?.officeNo && errors?.officeNo?.type === "required" && (<p>This field is required</p>)} */}
                                                {errors?.officeNo && errors?.officeNo?.type === "pattern" && (
                                                    <p>Please enter a valid name</p>
                                                )}
                                            </small>
                                        </div>
                                    </div> : null}

                                {/* For date picker  */}
                                <div className={`w-[49%] my-2 createaddress`}>
                                    <Controller name={"date"}
                                        control={control}
                                        defaultValue={null}
                                        render={({ field: { onChange, value }, formState: { error } }) => {
                                            return (
                                                (
                                                    <MuiDatePicker
                                                        label='establishmentYear'
                                                        // dateClsName={`${i18n.dir() === 'rtl' && 'businessTime'}`}
                                                        value={value}
                                                        views={['year']}
                                                        format={'yyyy'}
                                                        onChange={(newDate) => {
                                                            onChange(newDate)
                                                        }}
                                                    />
                                                )
                                            )
                                        }}
                                        rules={{ required: (props?.typecheck === "business" || localStorage.getItem('businessType') === 'business') ? true : false }}
                                    />
                                    {errors.date && errors.date.type === "required" && (
                                        <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                    )}
                                </div>

                                {/* For time picker  */}
                                {/* {props?.typecheck === "business" ? */}
                                <div className={`flex w-full gap-4`}>
                                    <div className={`w-full my-3`}>
                                        <Controller name={"startTime"}
                                            control={control}
                                            defaultValue={null}
                                            render={({ field: { onChange, value }, formState: { error } }) => {
                                                return (
                                                    (
                                                        <MuiTimePicker
                                                            label={"businessStartTime"}
                                                            onChange={onChange}
                                                            value={value}
                                                        />
                                                    )
                                                )
                                            }}
                                            rules={{ required: true }}
                                        />
                                        {errors.startTime && errors.startTime.type === "required" && (
                                            <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                        )}
                                    </div>

                                    <div className={`w-full my-3`}>
                                        <Controller name={"endTime"}
                                            control={control}
                                            defaultValue={null}
                                            render={({ field: { onChange, value }, formState: { error } }) => {
                                                return (
                                                    (
                                                        <MuiTimePicker
                                                            label={"businessEndTime"}
                                                            onChange={onChange}
                                                            value={value}
                                                        />
                                                    )
                                                )
                                            }}
                                            rules={{ required: true }}
                                        />
                                        {errors.endTime && errors.endTime.type === "required" && (
                                            <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                        )}
                                    </div>
                                </div>
                                {/* : null} */}

                                {/* Field for Displaying Name */}
                                {/* <div className={`w-full my-3 `}>
                                    <Controller
                                        name={"displayName"}
                                        control={control}
                                        rules={{
                                            required: true,
                                            minLength: 2,
                                            maxLength: 50
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <InputField
                                                extracls={` text-sm `}
                                                placeholder="enterdisplayname"
                                                textinputname="displayName"
                                                variant="outlined"
                                                type="text"
                                                id="displayName"
                                                onChange={onChange}
                                                value={value}
                                                floatingLabel={'enterdisplayname'}
                                            />
                                        )}
                                    />
                                    <small className={`text-red-500`}>
                                        {errors?.displayName && errors?.displayName?.type === "required" && (<p>This field is required</p>)}
                                        {errors?.displayName && errors?.displayName?.type === "maxLength" && (<p>Too Long</p>)}
                                        {errors?.displayName && errors?.displayName?.type === "minLength" && (<p>Too Short</p>)}
                                        {errors?.displayName && errors?.displayName?.type === "pattern" && (
                                            <p>Please enter a valid name</p>
                                        )}
                                    </small>
                                </div> */}

                                {/* Field for Description */}
                                <div className={`w-full my-3 `}>
                                    <Controller
                                        name={"description"}
                                        control={control}
                                        rules={{
                                            required: false,
                                            minLength: 0,
                                            maxLength: 2000
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <InputField
                                                extracls={` text-sm `}
                                                placeholder="writeSometingAboutbusiness"
                                                textinputname="description"
                                                variant="outlined"
                                                type="text"
                                                id="description"
                                                onChange={onChange}
                                                multiline={true}
                                                rows={4}
                                                value={value}
                                                floatingLabel={'writeSometingAboutbusiness'}
                                            />
                                        )}
                                    />
                                    <small className={`text-red-500`}>
                                        {errors?.description && errors?.description?.type === "required" && (<p>This field is required</p>)}
                                        {errors?.description && errors?.description?.type === "maxLength" && (<p>Too Long</p>)}
                                        {errors?.description && errors?.description?.type === "minLength" && (<p>Too Short</p>)}
                                    </small>
                                </div>
                            </div>
                            <div className={`flex flex-col items-start justify-evenly`}>

                                {/* Upload file section */}
                                <div className={`flex w-full justify-between `}>
                                    <div>
                                        <p className={`text-Grey-900 font-[Roboto-Medium] font-normal text-[13px] `}>{t("clickToUploadCivilId")}</p>
                                        <p className={`text-Grey-900 font-[Roboto-Regular] font-normal text-[12px] `}>Upload license and civil ID <br />as PDF or JPG</p>
                                    </div>
                                    {
                                        <div className={`relative`}>
                                            {uploadDocuments && uploadDocuments?.length > 0 && (uploadDocuments[0]?.type === 'image/jpeg' || uploadDocuments[0]?.type === 'application/pdf') &&
                                                <div className={`absolute -top-3 left-20 -right-4 cursor-pointer`}>
                                                    <CloseIcon
                                                        sx={{ fontSize: "12px", border: '1px solid red', borderRadius: '9999px' }}
                                                        onClick={() => { setUploadDocuments([]); dispatch(setUploadedData({})) }}
                                                    />
                                                </div>
                                            }
                                            <UploadButton
                                                sendData={(data) => {
                                                    props.doUploadedMedia(data)
                                                    setUploadDocuments(data);
                                                }}
                                                extracls='flex justify-center items-center bg-white items-center rounded-md'
                                                extratextcls='text-xs w-16'
                                                type='button'
                                                fileUploadIcon={
                                                    uploadDocuments && uploadDocuments?.length > 0 && uploadDocuments[0]?.type === 'image/jpeg' ?
                                                        window.URL.createObjectURL(uploadDocuments[0]) :
                                                        uploadDocuments[0]?.type === 'application/pdf' ?
                                                            pdficon :
                                                            fileUploadIcon
                                                }
                                                accept={'image/jpeg, application/pdf'}
                                                multiple={false}
                                                disabled={uploadDocuments && uploadDocuments?.length === 1 && (uploadDocuments[0]?.type === 'image/jpeg' || uploadDocuments[0]?.type === 'application/pdf') ? true : false}
                                                fileName={uploadDocuments[0]?.type === 'application/pdf' ? uploadDocuments[0]?.name : null}
                                                fileSize={uploadDocuments[0]?.type === 'application/pdf' ? uploadDocuments[0]?.size : null}
                                            />
                                        </div>
                                    }
                                </div>

                                {/* For Logo  */}
                                <div className={`flex w-full justify-between`}>
                                    <div>
                                        <p className={`text-Grey-900 font-[Roboto-Medium] font-normal text-[13px] `}>*Upload Logo</p>
                                        <p className={`text-Grey-900 font-[Roboto-Regular] font-normal text-[12px] `}>Drag and drop or <span className={`text-skyblue-6 underline`}>browse</span> <br />your files(png, jpg)</p>
                                    </div>
                                    {
                                        <div className={`relative`}>
                                            {uploadLogoProfile && uploadLogoProfile?.length > 0 && (uploadLogoProfile[0]?.type === 'image/jpeg' || uploadLogoProfile[0]?.type === 'image/png') &&
                                                <div className={`absolute -top-3 left-20 -right-4 cursor-pointer`}>
                                                    <CloseIcon
                                                        sx={{ fontSize: "12px", border: '1px solid red', borderRadius: '9999px' }}
                                                        onClick={() => { setUploadLogoProfile([]); dispatch(setUploadedLogo({})) }}
                                                    />
                                                </div>
                                            }
                                            <UploadButton
                                                sendData={(data) => {
                                                    props.doUploadedLogo(data)
                                                    setUploadLogoProfile(data)
                                                }}
                                                extracls='flex justify-center items-center bg-white  items-center rounded-md '
                                                type='button'
                                                fileUploadIcon={
                                                    uploadLogoProfile && uploadLogoProfile?.length > 0 &&
                                                        (uploadLogoProfile[0]?.type === 'image/jpeg' || uploadLogoProfile[0]?.type === 'image/png') ?
                                                        window.URL.createObjectURL(uploadLogoProfile[0]) :
                                                        fileUploadIcon
                                                }
                                                accept={'image/jpeg, image/svg, image/png'}
                                                multiple={false}
                                                disabled={uploadLogoProfile && uploadLogoProfile?.length === 1 && (uploadLogoProfile[0]?.type === 'image/jpeg' || uploadLogoProfile[0]?.type === 'image/png') ? true : false}
                                            />
                                        </div>
                                    }
                                </div>
                                {/* Checkbox */}
                                <div className={`flex items-center h-10 gap-2`}>
                                    <CheckBox checked={check ? true : false} handleBoxChange={(e) => handleChecking(e)}
                                    />
                                    <p onClick={() => setCheck(!check)} className={`text-Grey-900 font-[Roboto-Regular] font-normal text-sm cursor-pointer`}>
                                        {t("subscribeDaily")}
                                    </p>
                                </div>

                                {/* continue button */}

                                <ButtonField
                                    buttonextracls={`${(Object.keys(errors) && Object.keys(errors).length > 0) || loading === true ? `bg-gray-400` : `bg-skyblue-6`} border-[1.5px] border-solid border-deepgray-0.5  w-32 h-12`}
                                    buttonName="Continue"
                                    buttonnamecls={`font-[Roboto-Medium] text-white text-sm`}
                                    type="submit"
                                    onClick={handleSubmit(onSubmit)}
                                    disabled={(Object.keys(errors) && Object.keys(errors).length > 0) || loading === true ? true : false}
                                />

                            </div>
                        </div>
                        <div className={`w-[45%] pt-2 flex flex-col gap-6 justify-start items-center`}>
                            <UploadMultipleImage
                                sendData={(data) => {
                                    if (data[0]?.type === 'image/png' || data[0]?.type === 'image/jpeg') {
                                        // setThumbnailMulti([...thumbnailMulti, data[0]])
                                        props.multipleMediaApiCall(data);
                                    }
                                }}
                                disabled={multipleUploadState && multipleUploadState?.length === 4 ? true : false}
                                text1="selectFile"
                                text2="dragAndDropItHereText"
                                text3={loading === true ? 'uploading' : "uploadImg"}
                                multiple={true}
                            />
                            <div className={`flex gap-4 pt-2 w-full relative`}>
                                {multipleUploadState && (multipleUploadState?.length > 0 && multipleUploadState?.length <= 4) ?
                                    multipleUploadState?.map((multipleImg, idx) =>
                                        <div key={idx} className={`flex w-fit justify-between`}>
                                            <img
                                                // src={window.URL.createObjectURL(multipleImg)}
                                                crossOrigin="anonymous"
                                                src={multipleImg?.key && `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${multipleImg?.key}&height=75&width=100`}
                                                alt='thumbnail'
                                            />
                                            <div className={`cursor-pointer `}>
                                                <CloseIcon
                                                    onClick={() => removeParticularThumbnail(idx)}
                                                    sx={{ fontSize: "12px", position: 'absolute', top: '0px', border: '1px solid red', borderRadius: '9999px' }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={`flex w-full justify-between`}>
                                            <img src={fileUploadIcon} alt='file upload icon' />
                                            <img src={fileUploadIcon} alt='file upload icon' />
                                            <img src={fileUploadIcon} alt='file upload icon' />
                                            <img src={fileUploadIcon} alt='file upload icon' />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </form>

            </div>

            {/* middleside end*/}

        </>
    )
}

// CreateAddress.PropTypes = {
//     businessAddAddress: PropTypes.func
// }

export default CreateAddress