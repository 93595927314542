import React, { useState } from 'react'
import textsEn from '../../Languages/en_US.json'
import textsAr from '../../Languages/arabic.json'
import DdGlob from '../../../Images/CommonImages/DdGlob.svg'
import DdPhone from '../../../Images/CommonImages/DdPhone.svg'
import CustomDropDown from '../../FormFields/CustomDropDown'
import textsHi from '../../Languages/hindi.json'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import becomeAVendor from '../../../Images/LandingImages/becomeAVendor.svg'
import becomeAUser from '../../../Images/LandingImages/becomeAUser.svg'

const MainHeader = () => {

    const navigate = useNavigate()

    // Use the useTranslation hook inside your functional components to access the translation function or i18n instance.
    const { t, i18n } = useTranslation();

    // func for changing language
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    // window.location.reload(true)
    const [select, setSelect] = useState('')

    const languageArray = [
        { name: textsEn.English, value: "en_US" },
        { name: textsAr.Arabic, value: "ar" },
        { name: textsHi.Hindi, value: "hin" }
    ]

    return (
        <div className='bg-primeryColour-5 h-10 w-full flex justify-between items-center pl-[4.6%] pr-[5%] '>
            <div>
                <div className={`flex gap-4 items-center cursor-pointer`}>
                    <img src={DdPhone} alt='DdPhone' className={`h-[12px] w-[12px]`} />
                    <p className={`font-medium text-white text-xs leading-5 capitalize font-[Roboto-Medium] tracking-[0.15px]`}>{t("HelpContact")}</p>
                </div>
            </div>
            <div className={`flex items-center`}>
                <CustomDropDown
                    startIcon={true}
                    src={DdGlob}
                    alt='DdGlob'
                    dropdownBoxExtraCls={`top-8`}
                    dropDownValue={select}
                    dropDownName={select}
                    dropDownArray={languageArray}
                    setValue={setSelect}
                    enableLanguageChanger={true}
                    value={i18n.language}
                    changeLanguage={changeLanguage}
                    extraContain={
                        i18n.language === 'en_US' ? "English"
                            :
                            i18n.language === 'hin' ? "हिंदी"
                                :
                                i18n.language === 'ar' && "عربي"
                    }
                />
                <div className={`border-l mx-5 border-solid border-primeryColour-7 h-6`}></div>
                <div className='flex gap-4'>
                    <div onClick={() => navigate('/signup')} className={`font-medium text-white text-xs leading-5 cursor-pointer font-[Roboto-Medium] tracking-[0.15px] flex gap-2`}>
                        <img src={becomeAVendor} alt='become a vendor' />
                        <p>{t("BecomeAVendor")}</p>
                    </div>
                    <div onClick={() => navigate('/usersignup')} className={`font-medium text-white text-xs leading-5 cursor-pointer font-[Roboto-Medium] tracking-[0.15px] flex gap-2`}>
                        <img src={becomeAUser} alt='become a user' />
                        <p>{t("BecomeAUser")}</p>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default MainHeader