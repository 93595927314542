import * as React from 'react';
import PropTypes from 'prop-types';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import { useState } from 'react'

const labels = {
  1: '1',

  2: '2',

  3: '3',

  4: '4',

  5: '5',
};

const getLabelText = (value) => {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

const RatingField = (props) => {
  const [value, setValue] = useState(props.value);
  const [hover, setHover] = useState(-1);

  return (
    <Box
      sx={{
        // width: 168,
        display: 'flex',
        alignItems: 'center',
        color: "rgba(67, 67, 67, 0.6)",
      }}
    >
      {props.SendRating ?
        <Rating
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled}
          readOnly={props.readOnly}
        />
        :
        <Rating
          name={props.name}
          value={value}
          // precision={0.5}
          size={props.size}
          disabled={props.disabled}
          max={props.max}
          highlightSelectedOnly={false}
          getLabelText={getLabelText}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
          readOnly={props.readOnly}
        />
      }
      {props.SendRating === false ?
        <div>
          {value !== null && (
            <Box sx={{ ml: "3px", fontSize: 13, display: "flex", fontFamily: "Roboto-Regular", mt: "3px" }}>
              <p className={`mr-1`}>{labels[hover === 1 ? hover : value]}</p>
              <p>out of 5</p>
            </Box>
          )}
        </div>
        :
        <div>
          {props.value !== null && (
            <Box sx={{ ml: "3px", fontSize: 13, display: "flex", fontFamily: "Roboto-Regular", mt: "3px" }}>
              <p className={`mr-1`}>{labels[props.value]}</p>
              <p>out of 5</p>
            </Box>
          )}
        </div>
      }
    </Box>
  );
}
RatingField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  precision: PropTypes.number,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  max: PropTypes.string,
  highlightSelectedOnly: PropTypes.bool,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  SendRating: PropTypes.bool
};

export default RatingField