import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    businessDetails: {},
    searchQuery: [],
    reviews: [],
    favVendors: [],
    markers: [],
    subCatVendor: {},
    loading: false,
    error: false,
};

export const landingSlice = createSlice({
    name: 'landingData',
    initialState,
    reducers: {
        getBusinessDetails: (state, action) => {
            state.businessDetails = action.payload;
            state.loading = false;
            state.error = false;
        },
        getSearchDetails: (state, action) => {
            state.searchQuery = action.payload;
            state.loading = false;
            state.error = false;
        },
        getReviews: (state, action) => {
            state.reviews = action.payload;
            state.loading = false;
            state.error = false;
        },
        getFavVendors: (state, action) => {
            state.favVendors = action.payload;
            state.loading = false;
            state.error = false;
        },
        getGoogleMarker: (state, action) => {
            state.markers = action.payload;
            state.loading = false;
            state.error = false;
        },
        getSubCategoryVendor: (state, action) => {
            state.subCatVendor = action.payload;
            state.loading = false;
            state.error = false;
        }
    }
})

export const { getBusinessDetails, getSearchDetails, getReviews, getFavVendors, getGoogleMarker, getSubCategoryVendor } = landingSlice.actions;
export default landingSlice.reducer;