import { I18nextProvider, useTranslation } from 'react-i18next';
import './App.css';
import i18n from './Common/i18n';
import React from 'react';
import router from '../src/Routes/index.jsx'
import { RouterProvider } from 'react-router-dom';

function App() {

  const { i18n } = useTranslation();
  // Changes the direction of the content according to the language selected
  React.useEffect(() => {
    document.dir = i18n.dir();
  }, [i18n, i18n.language]);

  return (
    <I18nextProvider i18n={i18n}>
      <div className="App">
        <RouterProvider router={router} />
      </div >
    </I18nextProvider >

  );
}

export default App;
