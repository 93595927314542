import React, { useEffect } from 'react'
import TabComponent from '../../../Common/UiComponents/TabComponent'
import MyReqActive from '../../VendorDetailsPage/MyRequirement/MyReqActive'
import MyReqPending from '../../VendorDetailsPage/MyRequirement/MyReqPending'
import { useSelector } from 'react-redux'
import { ForAdmin } from '../../../Containers/Admin/Hooks'

const SingleVendorRequirementTab = (props) => {
  // const [value, setValue] = React.useState("0");
  const {value, setValue} = ForAdmin()
  
  // From Reducer in AdminPanelSettings, for getting the requirements of the clicked vendor 
  const vendorRequirements = useSelector((state) => state.totalVendorsInAdmin.requirements)

  const categories = [
    {
      label: "approved",
      value: "0",
      backgroundcolor: "bg-[#F7F7F7]",
      content: <MyReqActive liveMyRequirementsData={vendorRequirements} loading={props.loading}/>
    },
    {
      label: "pending",
      value: "1",
      backgroundcolor: "bg-white",
      content: <MyReqPending liveMyRequirementsData={vendorRequirements} buttonLoading={props.buttonLoading} loading={props.loading}/>
    },
  ]

  useEffect(() => {
    if (value === '0') {
      props.getVendorReqByVendorIdAdmin(props.userId?.id, 'active')
    } else if (value === '1') {
      props.getVendorReqByVendorIdAdmin(props.userId?.id, 'pending')
    }
  }, [props.userId?.id, value])
  
  return (
    <div>
      <TabComponent
        mappingname={categories}
        button={false}
        value={value}
        setValue={setValue}
      />
    </div>
  )
}

export default SingleVendorRequirementTab