import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ButtonField from '../../../Common/FormFields/ButtonField'
import text from '../../../Common/Languages/en_US.json'

const TextEditor = (props) => {
    const [quill, setQuill] = useState();
    const [quillData, setQuillData] = useState();
    // const [headingVal, setHeadingVal] = useState("");
    // const [contentVal, setContentVal] = useState("");


    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
    ];
    // useEffect(() => {
    //     const quillServer = new Quill('#container', { theme: 'snow', modules: { toolbar: toolbarOptions } });
    //     // setQuill(quillServer);
    //     //     quillServer.on('text-change', function(delta, oldDelta, source) {
    //     //         //on change opration  
    //     //         console.log(delta, oldDelta, source, 'delta');

    //     //  });
    //     //     let value = quillServer.getText();
    //     // console.log(value, 'quillVal');
    //     let editor_content = quillServer?.container.firstChild.innerHTML 
    // console.log(editor_content, 'quillContainer')
    // }, []);


    // useEffect(() => {
    //     // const handleChange = (delta, oldData, source) => {
    //     //     // setQuillData(delta)
    //     //     console.log(JSON.stringify(delta), 'quillData');
    //     // }
    //     // quill && quill.on('text-change', handleChange);



    // }, [quill])


    const modules = {
        toolbar: [
            [{ font: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "sub" }, { script: "super" }],
            ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
            ["link", "image", "video"],
            ["clean"],
        ],
    };

    // const headingText = (text) =>{}
    // Assuming you have the HTML content stored in a variable called 'html'
    // var html = headingVal;
    // var html2 = contentVal;

    // // Create a temporary element
    // var tempElement = document.createElement('div');
    // var tempElement2 = document.createElement('div');

    // // Assign the HTML content to the temporary element
    // tempElement.innerHTML = html;
    // tempElement2.innerHTML = html2;

    // // Retrieve the text value
    // var headingValue = tempElement.textContent || tempElement.innerText;
    // var contentValue = tempElement2.textContent || tempElement2.innerText;

    // const handleHeadingChange = (value) => {
    //     const cleanedHeadValue = value.toString().replace( /(<([^>]+)>)/ig, ''); // Removes empty html tags
    //     setHeadingVal(cleanedHeadValue);
    // };

    // const handleContentChange = (value) => {
    //     const cleanedContentValue = value.toString().replace( /(<([^>]+)>)/ig, ''); // Removes empty html tags
    //     setContentVal(cleanedContentValue);
    // };

    // console.log(typeof (headingValue), contentValue, 'textVal');


    return (
        <div className='flex flex-col gap-4'>
            <div className='headingWrapper'>
                <ReactQuill modules={modules} theme="snow" onChange={props.onChange} />
            </div>
        </div>
    )
}

export default TextEditor