import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    totalNotification: [],
    loading: false,
    error: false,
};

export const totalNotificationInAdminSlice = createSlice({
    name: 'totalNotificationInAdmin',
    initialState,
    reducers: {
        getTotalNotification: (state, action) => {
            state.totalNotification = action.payload;
            state.loading = false;
            state.error = false;
        },


    }
},
)

export const { getTotalNotification } = totalNotificationInAdminSlice.actions;
export default totalNotificationInAdminSlice.reducer;