import React from 'react'
import CommonTermsAndConditions from '../../Common/Layout/TermsAndConditions/CommonTermsAndConditions'
import LandingHeader from '../../Common/Layout/LandingHeader/LandingHeader'
import Footer from '../../Common/Layout/Footer/Footer'

const TermsAndConditions = (props) => {
    return (
        <div>
            <LandingHeader
                searchBar={true}
                goTomessage={true}
                goToNotification={true}
                border={true}
                profile={true}
                handleclickNotification={props.handleshow}
                showNotification={props.showNotification}
                detailsPage={props.detailsPage}
                allnotification={props.allnotification}
                readNotificationApi={props.readNotificationApi}
            />
            <CommonTermsAndConditions />
            <Footer />
        </div>
    )
}

export default TermsAndConditions