import React, { useState } from 'react'
import DefaultProfileicon from '../../../Images/Vendor/DefaultProfileicon.svg'
import ButtonField from '../../FormFields/ButtonField'
import CalenderCheckicon from '../../../Images/Vendor/CalenderCheckicon.svg'
import peopleicon from '../../../Images/Vendor/peopleicon.svg'
import expireicon from '../../../Images/Vendor/expireicon.svg'
import attachmentIcon from '../../../Images/profileDashboardIcons/attachment.svg'
import UploadDocument from '../../UiComponents/UploadDocument'
import uploadicon from '../../../Images/CommonImages/uploadicon.svg'
import { useLocation } from 'react-router'
import DeclinePopup from '../../FormFields/DeclinePopup'
import moment from 'moment'
import { calculateDateTimeDifference } from '../../../Utils/CommonFunc'
import ReactCountdown from '../../UiComponents/ReactCountdown';
import { Tooltip } from '@mui/material'

const BidCard = (props) => {
    const locatn = useLocation()
    const [openDecline, setOpenDecline] = useState(false)

    const handleClickForDeny = (data) => {
        setOpenDecline(!openDecline)
    }

    return (
        <div className={` bg-white rounded-lg shadow-md mb-4`}>
            <div className={`${props.onClick && 'cursor-pointer'}`} onClick={props.onClick}>
                <div className={`p-[3%] border-b flex justify-between items-center  `} >
                    <div className={`flex items-center`}>
                        <img src={props.DefaultProfileicon} alt="DefaultProfileicon" />
                        <div className={`flex flex-col ml-3`}>
                            <p className={`font-[Roboto-Regular] text-base text-black`}>{props.name}</p>
                            <p className={`text-grey-600 text-sm font-[Roboto-Medium]`}>{props.email}</p>
                        </div>
                    </div>
                    {props.LiveBid ? (
                        <div className={`flex items-center`}>
                            <p className={`w-2 h-2 rounded-full bg-green-primary-100 `}></p>
                            <p className={`text-green-primary-100 font-[Roboto-Medium] text-xs uppercase ml-2`}>Live Bid</p>
                        </div>
                    ) : props.item?.status === 'denied' ? <p className={`text-red-500`}>Denied</p> : (props.item?.status === 'pending' && (locatn.pathname === `/vendor/myrequirements` || locatn.pathname === `/user`)) ? <p className={`text-yellow-500`}>Pending</p> : props.item?.status === 'active' ? <p className={`text-blue-500`}>Active</p> : null}
                    {(locatn.pathname === `/admin/totalVendors/${props.item?.user?.id}` || locatn.pathname === `/admin/totalUsers/${props.item?.user?.id}`) && props.item?.status === 'pending' &&
                        <div className={`flex gap-4`}>
                            <ButtonField
                                buttonextracls={`cursor-pointer text-xs bg-skyblue-5 p-2 text-white rounded w-fit`}
                                onClick={() => props.approveFunc(props.item?.id, "active", props.item?.user?.id)}
                                // loading={props.buttonLoading}
                                buttonName={`Approve`}
                            />
                            <div
                                className={`cursor-pointer text-xs bg-red-600 p-2 text-white rounded w-fit`}
                                onClick={() => handleClickForDeny(props.item?.id)}
                            >
                                Decline
                            </div>
                        </div>}
                </div>
                <div className={`p-[3%] border-b`}>
                    <p className={`font-[Roboto-Medium] text-text-60 text-base mb-2`}>{props.question}</p>
                    <p className={`font-[Roboto-Regular] text-text-60 text-[13px] leading-5`}>{props.content}</p>
                </div>
            </div>
            {props.flex ? (
                <div className={`p-[3%] flex justify-between w-full`}>
                    <div className={`flex justify-between items-center w-[60%]`}>
                        {props.showdate ? (
                            <div className={`flex items-center`}>
                                <img src={CalenderCheckicon} alt="" height={18} width={18} />
                                <p className={`text-xs font-[Roboto-Regular] text-grey-600 pl-2`}>{props.date}</p>
                            </div>
                        ) : null}
                        {props.peoplereplied ? (
                            <div className={`flex items-center`}>
                                <img src={peopleicon} alt="" height={18} width={18} />
                                <p className={`text-xs font-[Roboto-Regular] text-grey-600 pl-2`}>{props.numberofpeoplereplied} People Replied</p>
                            </div>
                        ) : null}
                        {props.expiredtime ? (
                            <div className={`flex items-center`}>
                                <img src={expireicon} alt="" height={18} width={18} />
                                <p className={`text-xs font-[Roboto-Regular] text-black pl-2`}>
                                    {/* {calculateDateTimeDifference(props.item?.startDate, props.item?.startTime, props.item?.endDate, props.item?.endTime)} */}
                                    <ReactCountdown
                                        endDate={props.item?.endDate}
                                        endTime={props.item?.endTime}
                                    />
                                </p>
                            </div>
                        ) : null}
                        {props.attachment ? 
                            <div className={`flex items-center`}>
                                <img src={attachmentIcon} alt="attachmentIcon" height={15} width={15} />
                                <Tooltip title={props.item?.document[0]?.name}>
                                    <p className={`text-xs font-[Roboto-Regular] text-skyblue-5 px-2 truncate w-40`}>{props.item?.document[0]?.name}</p>
                                </Tooltip>
                                <img className={`cursor-pointer`} src={uploadicon} alt="uploadicon" onClick={props.downloadPdfAttachments} />
                            </div>
                            : 
                            <div className={`w-40`}></div>
                        }

                    </div>
                    <div className={`flex`}>
                        {props.interestedbid ? (
                            <ButtonField
                                buttonName={'I am interested to bid'}
                                type='reset'
                                buttonextracls={`${props.interestedbidDisabled === true && 'hidden'} !px-5 !py-2 !text-skyblue-5 bg-gray-0.5 mr-4 font-[Roboto-Medium]`}
                                onClick={props.doInterestedBidClick}
                                disabled={props.interestedbidDisabled}
                            />
                        ) : null}
                        {props.interestedbidDisabled === true && <p className={`text-blue-600`}>Interested</p>}
                        {(props.Bid === true && props.isLiveTab === false) ? (
                            <ButtonField
                                buttonName={'Bid'}
                                type='submit'
                                buttonextracls={`${props.bidDisabled === true ? 'text-white bg-deepgray-4' : '!text-white bg-skyblue-6'} !px-5 !py-2 font-[Roboto-Medium]`}
                                onClick={props.doBidClick}
                                disabled={props.bidDisabled}
                            />
                        ) : null}
                        {props.declineBid ? (
                            <ButtonField
                                buttonName={'Decline bid'}
                                type='submit'
                                buttonextracls={`${props.deniedbidDisabled === true && 'hidden'} !text-white bg-red-600 !px-5 !py-2 font-[Roboto-Medium]`}
                                onClick={props.doDeclineBidClick}
                                disabled={props.deniedbidDisabled}
                            />
                        ) : null}
                        {props.deniedbidDisabled === true && <p className={`text-red-600`}>Declined</p>}
                        {props.validdate ? (
                            <div className={`flex items-center`}>
                                <img src={expireicon} alt="" height={18} width={18} />
                                <p className={`text-xs font-[Roboto-Regular] text-black pl-2`}>
                                    {/* {calculateDateTimeDifference(props.item?.startDate, props.item?.startTime, props.item?.endDate, props.item?.endTime)} */}
                                    <ReactCountdown
                                        endDate={props.item?.endDate}
                                        endTime={props.item?.endTime}
                                    />
                                </p>
                            </div>
                        ) : null}
                    </div>
                </div>
            )
                :
                (
                    <div className={`flex justify-between items-center w-full p-[3%]`}>
                        <div className={`flex items-center`}>
                            <img src={CalenderCheckicon} alt="" height={18} width={18} />
                            <p className={`text-xs font-[Roboto-Regular] text-grey-600 pl-2`}>{props.date}</p>
                        </div>
                        <div className={`flex items-center`}>
                            <img src={peopleicon} alt="" height={18} width={18} />
                            <p className={`text-xs font-[Roboto-Regular] text-grey-600 pl-2`}>{props.numberofpeoplereplied} People Replied</p>
                        </div>
                        <div className={`flex items-center`}>
                            <img src={expireicon} alt="" height={18} width={18} />
                            <p className={`text-xs font-[Roboto-Regular] text-black pl-2`}>Valid Through : {props.time} </p>
                        </div>

                    </div>
                )
            }
            {props.uploadDocument ? (
                <div className={`p-[3%]`}>
                    <UploadDocument
                        uploadbutton={true}
                        // img={(props.saveReqData?.document?.length > 0 && props.saveReqData?.user?.id !== parseInt(localStorage.getItem('userId'))) && uploadicon}
                        img={props.saveReqData?.document?.length > 0 && uploadicon}
                        disabled={props.saveReqData?.document?.length > 0 ? false : true}
                        pdfname={props.saveReqData?.document[0]?.name ? props.saveReqData?.document[0]?.name : `No document`}
                        kb={props.saveReqData?.document[0]?.size ? `${(props.saveReqData?.document[0]?.size / 1024).toFixed(2)} kb` : "0kb"}
                        downloadPdfAttachments={props.downloadPdfAttachments}
                    />
                </div>
            ) : null}

            {openDecline &&
                <DeclinePopup
                    title={`Delete Requirement`}
                    dialogTitlecls={`text-black`}
                    open={openDecline}
                    // userID={userID}
                    handleClose={handleClickForDeny}
                    DilogContentText={
                        "Are you sure that you want to delete this requirement?"
                    }
                    DeleteBtn={"Yes"}
                    onClick={() => {
                        props.approveFunc(props.item?.id, "denied", props.item?.user?.id);
                        setOpenDecline(false)
                    }
                    }
                />
            }
        </div>
    )
}

export default BidCard