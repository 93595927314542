import CryptoJS from "crypto-js";
import Consolelog from "./Consolelog"

export default function encryptDecrypt(data, type) {
    let promise = new Promise(function (resolve, reject) {
        try {
            const reqstValues = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    access: process.env.REACT_APP_TOKEN_ACCESS,
                },
            };
            fetch(`${process.env.REACT_APP_DG_BASE_URL}/users/en/token`, reqstValues)
                .then((res) => res.json())
                .then((response) => {
                    var key = CryptoJS.enc.Base64.parse(response.token);
                    var iv = CryptoJS.enc.Base64.parse(`${response.token}-${process.env.REACT_APP_SEC_KEY}`);
                    let result
                    if (type === 'e') {
                        var encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, { iv: iv });
                        result = encrypted.toString()

                    } else if (type === 'd') {
                        var decrypted = CryptoJS.AES.decrypt(data.toString(), key, { iv: iv });
                        result = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
                    }
                    resolve(result);
                })

        } catch (err) {
            Consolelog("error", `[Key-005] Data ${type === "e" ? "encrypt" : "decrypt"}  failed`);
            reject(err);
        }
    });
    return promise;
}