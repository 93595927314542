import React, { useState } from 'react'
import text from '../../../Common/Languages/en_US.json'
import CheckBox from '../../../Common/FormFields/CheckBox'
import addIcon from '../../../../src/Images/Setting/addIcon.svg'
import DeclinePopup from '../../../Common/FormFields/DeclinePopup'
import { Controller, useForm } from 'react-hook-form';
import TextFieldInput from '../../../Common/FormFields/TextFieldInput'
import DropDownField from '../../../Common/FormFields/DropDownField'
import { useSelector } from 'react-redux'


const NotificationSettings = (props) => {
  const notification = useSelector((state) => state.notification.totalNotification) 

  const selectNotificationArr = [
    { name: "Notify All", value: "all" },
    { name: "Notify Only User", value: "user" },
    { name: "Notify Only Vendors", value: "vendor" },
  ]

  return (
    <div className={`px-[3%] py-[5%] flex flex-col gap-6 overflow-y-auto h-[68vh] relative`}>

      {/* header section  */}
      <div>
        <div className={`flex justify-between flex-col gap-3`}>
          <div className={`flex justify-between items-center`}>
            <p className='text-deepgray-5 font-[Roboto-Regular] text-lg'>{text.alertsNotification}</p>
          </div>
          <p className={`text-grey-5 text-[17px] font-[Roboto-Medium] mb-3`}>{text.sendMe}</p>
        </div>

        {props.notificationFound ? <p className={`text-lime-600 text-[14px] font-[Roboto-Regular] fixed right-7 top-64`}>notification seting updated sucsessfully</p> : props.notificationNotFound && <p className={`text-red-600 text-[14px] font-[Roboto-Regular] italic fixed right-7 top-64`}>notification seting not updated</p>}

        {/* announcement section  */}

        <div className={`py-4 flex flex-col gap-4`} >
          <div className='flex '>
            <div className='flex flex-col gap-2 w-full'>

              <div className={`flex gap-2 items-center`} >
                <CheckBox size={'medium'} sx={{ color: '#00000057' }} handleCheck={props.checkSpecialAnnouncement} checked={notification[0]?.specialAnnouncement || props.specialAnnouncement} />
                <label onClick={props.checkSpecialAnnouncement}><p className={`text-grey-5 text-base font-[Roboto-Regular] cursor-pointer`}>Special Announcement</p></label>
              </div>
              <p className={`text-grey-5 text-[15px] font-[Roboto-Regular]`}>Send notifications to all users when special announcements are posted.</p>

              <div className={`flex gap-2 items-center`} >
                <CheckBox size={'medium'} sx={{ color: '#00000057' }} handleCheck={props.checkAccountExpiry} checked={notification[0]?.accountExpiry || props.accountExpiry} />
                <label onClick={props.checkAccountExpiry}><p className={`text-grey-5 text-base font-[Roboto-Regular] cursor-pointer`}>Account Expiry</p></label>
              </div>
              <p className={`text-grey-5 text-[15px] font-[Roboto-Regular]`}>Send notification when the user's subscription expires.</p>

              <div className={`flex gap-2 items-center`} >
                <CheckBox size={'medium'} sx={{ color: '#00000057' }} handleCheck={props.checkNewOffer} checked={notification[0]?.newOffer || props.newOffer} />
                <label onClick={props.checkNewOffer}><p className={`text-grey-5 text-base font-[Roboto-Regular] cursor-pointer`}>New Offer</p></label>
              </div>

              <div className={`flex justify-between w-full`}>
                <p className={`text-grey-5 text-[15px] font-[Roboto-Regular]`}>Send all users notifications on “new offers”.</p>
                <DropDownField
                  selectOption={selectNotificationArr}
                  dropDownRootCls={`h-10 pl-[25px] text-sm text-deepgrey-3 font-[Roboto-Medium]`}
                  filterImg={true}
                  handleChange={(e) => {
                    props.selectTypeMsg(e.target.value);
                  }}
                  placeholder="Notification"
                  option={notification[0]?.alert || props.dropdownName}
                />
              </div>
            </div>
          </div>
          <hr className='bg-deepgrey-0 h-[1px] w-full' />
        </div>
      </div>

      {/* Account activity section  */}
      <div>
        <div className={`flex justify-between flex-col gap-3`}>
          <div className={`flex justify-between items-center`}>
            <p className='text-deepgray-5 font-[Roboto-Regular] text-lg'>{text.accountActivity}</p>
          </div>
          <p className={`text-grey-5 text-[17px] font-[Roboto-Medium] mb-3`}>{text.emailMe}</p>
        </div>


        <div className={`py-4 flex flex-col gap-4`} >
          <div className='flex flex-col gap-2'>
{/* 
            <div className={`flex gap-2 items-center`} >
              <CheckBox size={'medium'} sx={{ color: '#00000057' }} handleCheck={props.checkNewUser} checked={notification[0]?.newUser || props.newUser} />
              <label onClick={props.checkNewUser}><p className={`text-grey-5 text-base font-[Roboto-Regular] cursor-pointer`}>New users register</p></label>
            </div> */}

            <div className={`flex gap-2 items-center`} >
              <CheckBox size={'medium'} sx={{ color: '#00000057' }} handleCheck={props.checkSubscriptionActivity} checked={notification[0]?.subscriptionActivity || props.subscriptionActivity} />
              <label onClick={props.checkSubscriptionActivity}><p className={`text-grey-5 text-base font-[Roboto-Regular] cursor-pointer`}>Notifications for Vendor and User Subscription Activity</p></label>
            </div>

            <div className={`flex gap-2 items-center`} >
              <CheckBox size={'medium'} sx={{ color: '#00000057' }} handleCheck={props.checkBidConfirmation} checked={notification[0]?.bidConfirmation || props.bidConfirmation} />
              <label onClick={props.checkBidConfirmation}><p className={`text-grey-5 text-base font-[Roboto-Regular] cursor-pointer`}>All User and Vendor " Confirmation on Bid" needs to be notified via Email.</p></label>
            </div>

          </div>
          <hr className='bg-deepgrey-0 h-[1px] w-full' />
        </div>

      </div>
      <div className={`flex gap-2 justify-end fixed bottom-10 right-7`}>
        <div onClick={props.postNotificationSeting} className={`text-primeryColour-4 border bg-white border-solid border-primeryColour-4 w-14 h-7 rounded flex items-center justify-center cursor-pointer font-medium text-sm leading-5 font-[Roboto-Medium] tracking-[0.15px]`}>RESET</div>
        {/* <div onClick={()=>props.updateNotifications === true ? props.settingNotificationUpdate(notification[0]?.id) : props.postNotificationSeting()} className={`bg-primeryColour-4 text-white w-14 h-7 rounded flex items-center justify-center cursor-pointer font-medium text-sm leading-5 font-[Roboto-Medium] tracking-[0.15px]`}>SAVE</div> */}
        <div onClick={() => notification?.length > 1 ? props.settingNotificationUpdate(notification[0]?.id) : props.postNotificationSeting()} className={`bg-primeryColour-4 text-white w-14 h-7 rounded flex items-center justify-center cursor-pointer font-medium text-sm leading-5 font-[Roboto-Medium] tracking-[0.15px]`}>SAVE</div>
      </div>
    </div>
  )
}

export default NotificationSettings