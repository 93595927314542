import { CircularProgress, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DashboardTags from '../../Common/Layout/ProfileCard/DashboardTags'
import ProfileUserDescriptionCard from '../../Common/Layout/ProfileCard/ProfileUserDescriptionCard'
import ProfileUserImagesCard from '../../Common/Layout/ProfileCard/ProfileUserImagesCard'
import ProfileUserInfoCard from '../../Common/Layout/ProfileCard/ProfileUserInfoCard'
import VendorDetailsPageIndex from '../../Components/VendorDetailsPage/VendorDetailsPageIndex'
import LandingHeader from '../../Common/Layout/LandingHeader/LandingHeader'
import Footer from '../../Common/Layout/Footer/Footer'
import text from '../../Common/Languages/en_US.json'
import VendorserviceOfferpage from '../../Components/VendorDetailsPage/ServiceOffer'
import AllRequirementSection from '../../Components/VendorDetailsPage/AllRequirement'
// import MessageSection from '../../Components/VendorDetailsPage/Message'
import { ForVendor } from './Hooks'
import VendorMyReqPage from '../../Components/VendorDetailsPage/MyRequirement'
import Subscription from '../../Components/VendorDetailsPage/Subscription'
// import Chatbox from '../../Components/VendorDetailsPage/Message/Chatbox'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Offer from '../../Components/VendorDetailsPage/Offers'
import { ForAdmin } from '../Admin/Hooks'
import { ForUploadMediaHooks } from '../UploadMedia/Hooks'
import { ForNotification } from '../Notification/Hooks'
import TabComponent from '../../Common/UiComponents/TabComponent'
import EditProfileInfo from '../../Components/VendorDetailsPage/ProfileEdit/EditProfileInfo'
import EditBusinessInfo from '../../Components/VendorDetailsPage/ProfileEdit/EditBusinessInfo'
import { useJwt } from "react-jwt";
import SnackBarComponent from '../../Common/Layout/Snackbar/SnackBar'
import { useLocation, useNavigate } from 'react-router-dom'
import VendorMessageMain from './VendorMessageMain'
import { ForSignup } from '../Authentication/Signup/Hook'
import CreateAddress from '../../Components/Authentication/Signup/CreateAddress'
import { ForLogin } from '../Authentication/Login/Hooks'

const VendorDescription = () => {

  const { t } = useTranslation();

  const {
    createVendorPostService,
    createVendorMyRequirements,
    getAllPostedService,
    getAllOfMyRequirements,
    toggleNewService,
    showNewServiceForm,
    goBackToActiveServiceOfferedTab,
    showAddOfferForm,
    toggleAddOffer,
    goBackToPendingServiceOfferedTab,
    createNewOffers,
    getCreatedNewOffers,
    goBackToMyRequirementTab,
    toggleMyRequirement,
    showMyReqrForm,
    goToMyReqrLiveDetails,
    goBackFromMyReqrLiveDetails,
    showMyReqrLiveDetails,
    goToAllReqrLiveDetails,
    goBackFromAllReqrLiveDetails,
    showAllReqrLiveDetails,
    setShowAllReqrLiveDetails,
    closeOtherTabItems,
    getBusiness,
    selectedService,
    submitError,
    singleVendorDetailsData,
    showOfferForm,
    toggleOffer,
    createNewProductOffers,
    dateState,
    setDateState,
    gobackToProductOffer,
    getEachVendorProductOffers,
    getServiceByBusiness,
    deletePendingServices,
    editServiceForm,
    editServiceData,
    editServices,
    addCategoryId,
    addSubCategoryId,
    myRequirementCreate,
    getCategoryInVendor,
    productSubmitError,
    serviceSubmitError,
    toggleEditProfile,
    editProfile,
    editVendorProfileDetailsApi,
    editVendorBusinessDetailsApi,
    vendorProfileData,
    VendorProfileUpdateTexts,
    getVendorRequirementsByVendorId,
    saveReqData,
    saveAllReqData,
    downloadPdfAttachments,
    postBid,
    getBidApiCAllByReqId,
    loading,
    getPostedReviewsInVendor,
    postInterestedBidApiCall,
    getAllInterestedBidsApiCAll,
    setShowMyReqrForm,
    selectBidsApiCall,
    value,
    setValue,
    reqError,
    notification,
    messageClose,
    editBidPriceApiCall,
    serviceSubmitErrorMsg,
    dateStateVendorDashboard,
    setDateStateVendorDashboard,
    getDashboardDataVendorApi,
    handleOpenDate,
    dateOpen,
    setDateOpen,
    postChatApiCall,
    getChatsApiCAll,
    editProductForm,
    editProduct,
    postDeclinBid,
    getDeniedBidByUserIdApiCall,
    deleteProductOffers,
    isLiveTab,
    changePasswordLoader,
    businessLoader
  } = ForVendor();


  const { getCategory } = ForAdmin()
  const { getSubscriptionDetailsForUser } = ForLogin()
  const subscriptionData = useSelector((state) => state?.subscriptionDetails?.subscriptionDetail)


  // const [switchContent, setSwitchContent] = useState('value0')

  const { doUploadedMedia,
    doUploadedLogo,
    sendData,
    uploadData,
    multipleMediaApiCall,
    documentUpload,
    logoUpload,
    multipleUpload } = ForUploadMediaHooks()

  const { showNotification, getNotification, handleshow, detailsPage, allnotification, readNotificationApi } = ForNotification()
  const navigate = useNavigate()

  // const {
  //   getTotalVendorsInAdmin,
  //   handleOpenDate,
  //   dateOpen
  // } = ForAdmin()

  // const userData = useSelector((state) => state.vendor.services)
  const { mapLocationTxt,
    setMapLocationTxt,
    mapLocationArr,
    mapLocation,
    handleClickLocation,
    businessAddAddress,
    typecheck
  } = ForSignup()

  const businessId = localStorage.getItem('businessId')
  const routeLocation = useLocation()

  useEffect(() => {
    getBusiness()
    getCategory()
    getNotification()
    getSubscriptionDetailsForUser()
  }, [])

  // useEffect(() => {
  //   editVendorProfileDetailsApi()
  // }, [])

  useEffect(() => {
    // getServiceByBusiness()
    getPostedReviewsInVendor()
  }, [businessId])

  const getServicesByBusinessDetails = useSelector((state) => state.vendor.servicesByBusiness)

  const categoryArr = useSelector((state) => state.totalCategoryInAdmin.totalCategories)
  const reviewData = useSelector((state) => state.vendor.reviews)

  // For refresh token when actual token expires 
  let token = localStorage.getItem('token') ? localStorage.getItem('token') : "";
  const { isExpired } = useJwt(token);
  const checkTokenExpiration = () => {
    if (isExpired) {
      navigate('/login');
    }
  }
  const handleRenderReqForm = (e) => {
    e.persist();
    // setRenderReqForm(true)
    setShowMyReqrForm(true)
  }

  useEffect(() => {
    getDashboardDataVendorApi()
  }, [businessId, dateStateVendorDashboard[0]?.endDate]);

  const categories = [
    {
      label: "Profile Info",
      value: "0",
      backgroundcolor: "bg-[#F7F7F7]",
      content: <EditProfileInfo
        toggleEditProfile={toggleEditProfile}
        editVendorProfileDetailsApi={editVendorProfileDetailsApi}
        vendorProfileData={vendorProfileData}
        loading={loading}
      />
    },
    {
      label: "Business Info",
      value: "1",
      backgroundcolor: "bg-white",
      content: <EditBusinessInfo
        toggleEditProfile={toggleEditProfile}
        vendorProfileData={vendorProfileData}
        editVendorBusinessDetailsApi={editVendorBusinessDetailsApi}
        doUploadedMedia={doUploadedMedia}
        doUploadedLogo={doUploadedLogo}
        multipleMediaApiCall={multipleMediaApiCall}
        documentUpload={documentUpload}
        logoUpload={logoUpload}
        multipleUpload={multipleUpload}
        VendorProfileUpdateTexts={VendorProfileUpdateTexts}
        loading={loading}
        mapLocationTxt={mapLocationTxt}
        setMapLocationTxt={setMapLocationTxt}
        mapLocationArr={mapLocationArr}
        mapLocation={mapLocation}
        handleClickLocation={handleClickLocation}
      />
    },
  ]

  return (
    <div onClick={() => checkTokenExpiration()}>
      <div className='sticky top-0 z-50'>
        {/* <MainHeader /> */}
        <LandingHeader
          searchBar={true}
          goTomessage={true}
          goToNotification={true}
          border={true}
          profile={true}
          handleclickNotification={handleshow}
          showNotification={showNotification}
          detailsPage={detailsPage}
          allnotification={allnotification}
          readNotificationApi={readNotificationApi}
          handleRenderReqForm={handleRenderReqForm}
        />
      </div>
      {businessLoader === true ?
        <div className='flex h-screen justify-center items-center'>
          <CircularProgress size={20} color='success' />
          <p className='pl-3'>Loading...</p>
        </div>
        :
        <div className={`px-[4%] py-[3%] !bg-[#F7F7F7] w-full h-fit `}>
          {((singleVendorDetailsData && Object?.keys(singleVendorDetailsData)?.length > 0) || businessId) ?
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <div className={`w-full  flex flex-col justify-start gap-4 overflow-y-scroll h-[95vh] `}>
                  <ProfileUserInfoCard
                    dataObject={singleVendorDetailsData}
                    toggleEditProfile={toggleEditProfile}
                  />
                  <div className={`flex justify-between flex-col gap-4`}>
                    <div className={`w-full`}><ProfileUserImagesCard imgArr={singleVendorDetailsData?.thumbnail} dataObject={singleVendorDetailsData} /></div>
                    <div className={`w-full`}><ProfileUserDescriptionCard description={t("description")}
                      profileUserCardDescText={text.profileUserCardDescText} dataObject={singleVendorDetailsData} /></div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} md={9}>
                {/*Conditions for showing Edit profile section or Dashboard tags */}
                {editProfile === true ?
                  <TabComponent
                    mappingname={categories}
                    onClick={toggleEditProfile}
                    value={value}
                    setValue={setValue}
                  />
                  :
                  <div>
                    <DashboardTags
                      closeOtherTabItems={closeOtherTabItems}
                    />
                    {routeLocation.pathname === '/vendor' && <VendorDetailsPageIndex
                      BusinessDetails={singleVendorDetailsData}
                      reviewData={reviewData}
                      dateStateVendorDashboard={dateStateVendorDashboard}
                      setDateStateVendorDashboard={setDateStateVendorDashboard}
                      getDashboardDataVendorApi={getDashboardDataVendorApi}
                      handleOpenDate={handleOpenDate}
                      dateOpen={dateOpen}
                      setDateOpen={setDateOpen} />
                    }
                    {routeLocation.pathname === '/vendor/services' &&
                      <VendorserviceOfferpage
                        createVendorPostService={createVendorPostService}
                        getAllPostedService={getAllPostedService}
                        toggleNewService={toggleNewService}
                        showNewServiceForm={showNewServiceForm}
                        goBackToActiveServiceOfferedTab={goBackToActiveServiceOfferedTab}
                        showAddOfferForm={showAddOfferForm}
                        toggleAddOffer={toggleAddOffer}
                        goBackToPendingServiceOfferedTab={goBackToPendingServiceOfferedTab}
                        createNewOffers={createNewOffers}
                        getCreatedNewOffers={getCreatedNewOffers}
                        userData={getServicesByBusinessDetails}
                        // userData={singleVendorDetailsData}
                        selectedService={selectedService}
                        submitError={submitError}
                        deletePendingServices={deletePendingServices}
                        editServiceForm={editServiceForm}
                        editServiceData={editServiceData}
                        editServices={editServices}
                        categoryArr={categoryArr}
                        addCategoryId={addCategoryId}
                        addSubCategoryId={addSubCategoryId}
                        doUploadedMedia={doUploadedMedia}
                        multipleMediaApiCall={multipleMediaApiCall}
                        sendData={sendData}
                        uploadData={uploadData}
                        serviceSubmitError={serviceSubmitError}
                        getServiceByBusiness={getServiceByBusiness}
                        loading={loading}
                        value={value}
                        setValue={setValue}
                        serviceSubmitErrorMsg={serviceSubmitErrorMsg}
                        getCategoryInVendor={getCategoryInVendor}
                      />

                    }
                    {routeLocation.pathname === '/vendor/allrequirements' &&
                      <AllRequirementSection
                        goToAllReqrLiveDetails={goToAllReqrLiveDetails}
                        goBackFromAllReqrLiveDetails={goBackFromAllReqrLiveDetails}
                        showAllReqrLiveDetails={showAllReqrLiveDetails}
                        setShowAllReqrLiveDetails={setShowAllReqrLiveDetails}
                        getAllOfMyRequirements={getAllOfMyRequirements}
                        saveAllReqData={saveAllReqData}
                        postBid={postBid}
                        getBidApiCAllByReqId={getBidApiCAllByReqId}
                        singleVendorDetailsData={singleVendorDetailsData}
                        postInterestedBidApiCall={postInterestedBidApiCall}
                        getAllInterestedBidsApiCAll={getAllInterestedBidsApiCAll}
                        value={value}
                        setValue={setValue}
                        editBidPriceApiCall={editBidPriceApiCall}
                        loading={loading}
                        downloadPdfAttachments={downloadPdfAttachments}
                        postDeclinBid={postDeclinBid}
                        getDeniedBidByUserIdApiCall={getDeniedBidByUserIdApiCall}
                        isLiveTab={isLiveTab}
                      />
                    }
                    {routeLocation.pathname === '/vendor/myrequirements' &&
                      <VendorMyReqPage
                        createVendorMyRequirements={createVendorMyRequirements}
                        goBackToMyRequirementTab={goBackToMyRequirementTab}
                        toggleMyRequirement={toggleMyRequirement}
                        showMyReqrForm={showMyReqrForm}
                        goToMyReqrLiveDetails={goToMyReqrLiveDetails}
                        goBackFromMyReqrLiveDetails={goBackFromMyReqrLiveDetails}
                        showMyReqrLiveDetails={showMyReqrLiveDetails}
                        myRequirementCreate={myRequirementCreate}
                        getCategoryInVendor={getCategoryInVendor}
                        // doUploadedMedia={doUploadedMedia}
                        getVendorRequirementsByVendorId={getVendorRequirementsByVendorId}
                        saveReqData={saveReqData}
                        multipleMediaApiCall={multipleMediaApiCall}
                        downloadPdfAttachments={downloadPdfAttachments}
                        getBidApiCAllByReqId={getBidApiCAllByReqId}
                        singleVendorDetailsData={singleVendorDetailsData}
                        loading={loading}
                        selectBidsApiCall={selectBidsApiCall}
                        value={value}
                        setValue={setValue}
                        reqError={reqError}
                      />
                    }
                    {routeLocation.pathname === '/vendor/message' && <VendorMessageMain getChatsApiCAll={getChatsApiCAll} postChatApiCall={postChatApiCall} />}
                    {routeLocation.pathname === '/vendor/subscription' && <Subscription subscriptionData={subscriptionData?.subscription} />}

                    {routeLocation.pathname === '/vendor/products' &&
                      <Offer
                        showOfferForm={showOfferForm}
                        toggleOffer={toggleOffer}
                        createNewProductOffers={createNewProductOffers}
                        multipleMediaApiCall={multipleMediaApiCall}
                        dateState={dateState}
                        setDateState={setDateState}
                        showAddOfferForm={showAddOfferForm}
                        gobackToProductOffer={gobackToProductOffer}
                        getEachVendorProductOffers={getEachVendorProductOffers}
                        productSubmitError={productSubmitError}
                        loading={loading}
                        value={value}
                        setValue={setValue}
                        editProductForm={editProductForm}
                        editProduct={editProduct}
                        deleteProductOffers={deleteProductOffers}
                      />
                    }

                    {/* <Outlet /> */}

                  </div>
                }
                <SnackBarComponent
                  messageOpen={notification.open}
                  messageClose={messageClose}
                  notificationText={notification.message}
                  subText={notification.subText}
                  alertType={notification.alertType}
                  borderClass={notification.borderClass}
                />
              </Grid>
            </Grid>
            :
            <CreateAddress
              businessAddAddress={businessAddAddress}
              doUploadedMedia={doUploadedMedia}
              doUploadedLogo={doUploadedLogo}
              multipleMediaApiCall={multipleMediaApiCall}
              typecheck={typecheck}
              mapLocationTxt={mapLocationTxt}
              setMapLocationTxt={setMapLocationTxt}
              mapLocation={mapLocation}
              handleClickLocation={handleClickLocation}
            />
          }
        </div>}
      <Footer />
    </div>

  )
}

export default VendorDescription