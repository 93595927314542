import { ClickAwayListener, Tabs, tabsClasses } from '@mui/material'
import React, { useEffect, useState } from 'react'
import texts from '../../Languages/en_US.json'
import PropTypes from 'prop-types';
import HyperlinkIcon from '../../../Images/Setting/HyperlinkIcon.svg'
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router';
import './ScrollTab.css';

const ScrollTab = (props) => {
    const { t, i18n } = useTranslation()
    const [openCategoryModal, setOpenCategoryModal] = useState(false)
    const [subCategoryData, setSubCategoryData] = useState()
    const navigate = useNavigate()
    const handleOpenCategoryModal = (subCatData) => {
        setOpenCategoryModal(true)
        setSubCategoryData(subCatData)
    }
    
    const handleCloseCategoryModal = () => {
        setOpenCategoryModal(false)
    }

    const handleSubCategoryVendor = (subCatId) => {
        props.getVendorsUnderSubCategory(subCatId)
    }

    const handleVendorDetail = (id) => {
        navigate(`/details/${id}`)
    }
   
    const seeAllCategory = ()=>{
        navigate(`/seeAllCategory`)
    }

    const gotToCategoryDetails = (data) => {
        navigate(`/seeAllCategory?catId=${data?.id}`);
    }

    const slicedCategory = props.categoriesArray?.slice(0,8)
    return (
        <ClickAwayListener onClickAway={handleCloseCategoryModal}>
            <div className={`relative`} >
                <p className={`absolute z-10 bg-gray-0.5 h-full flex justify-center items-center font-[Roboto-Regular] w-[10rem] ${i18n.dir() === 'rtl' ? 'left-[0.0001rem]' : 'right-[0.0001rem]'}  text-blue-5 select-none cursor-pointer`} onClick={seeAllCategory}>{t("moreCategories")}</p>
                {/* <Tabs
                    className={` w-full h-20 bg-gray-0.5 flex justify-start items-center pl-[1.5%] pr-[4%] ${i18n.dir() === 'rtl' ? 'scrollArrowArabic' : 'scrollArrow'} tabFix`}
                    variant="scrollable"
                    scrollButtons
                    aria-label="scrollable auto tabs example"
                    value={0}
                    disabled={true}
                    sx={{
                        [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.5, background: '#F5F5F5' },
                        },
                    }}
                > */}
                    <div className={`flex w-full gap-14 px-[4%] h-20 bg-gray-0.5 overflow-x-hidden`}
                    //  onClick={props.scroll ? props.scrollClickHandler : () => { }}
                     >
                        {/* category mapping  */}
                        {slicedCategory?.length > 0 ? slicedCategory.map((categoryData, idx) =>
                            <div key={idx} className={`flex items-center gap-3 py-2 cursor-pointer ${subCategoryData?.subCategory.length > 0 && categoryData?.subCategory.includes(subCategoryData?.subCategory[0]) ? `no-underline category_underline` : ``}`}
                                onClick={()=> gotToCategoryDetails(categoryData)}
                            >
                                <div className={`border-2 border-violet-0.5 h-[50px] w-[50px] rounded-full flex justify-center items-center`}>
                                    <img
                                        crossOrigin='anonymous'
                                        src={categoryData?.picture?.key !== null ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${categoryData?.picture?.key}&height=60&width=60` : HyperlinkIcon}
                                        alt={categoryData?.name}
                                        className={`h-fit w-fit rounded-full `}
                                    />
                                </div>
                                <p className={`text-deepgray-5 text-base font-[Roboto-Regular] font-normal`}>{categoryData?.name}</p>
                            </div>) : <p className='text-[#2f2e2e]'>No Category data available</p>}
                    </div>
                {/* </Tabs> */}


                {openCategoryModal ?
                    <div className={`bg-[#ece8e8] shadow-md w-[92%] rounded-b absolute left-[4rem] top-[4.9rem] py-[2%] px-[2%] z-20 grid grid-cols-6 gap-[1em] min-h-[25rem]`}
                    >
                        <CloseIcon
                            onClick={handleCloseCategoryModal}
                            sx={{ fontSize: "20px", position: 'absolute', top: '2rem', right: '20px', border: '1px solid black', borderRadius: '9999px', cursor: 'pointer' }}
                        />

                        {/* sub-category mapping  */}
                        {subCategoryData?.subCategory?.length > 0 ? subCategoryData?.subCategory?.map((subCategoryItem, index) =>
                            <div className='flex flex-col gap-5'>
                                <div className={`flex gap-3 cursor-pointer items-center ${props.subCatVendors.subCatId === subCategoryItem?.id ? `no-underline subCat_underline` : ``}`} key={index} onClick={() => handleSubCategoryVendor(subCategoryItem.id)}>
                                    <img
                                        crossOrigin='anonymous'
                                        src={subCategoryItem?.picture?.key !== null ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${subCategoryItem?.picture?.key}&height=60&width=60` : HyperlinkIcon}
                                        alt={subCategoryItem?.name}
                                        className={`h-10 w-10 rounded-full border border-slate-400`}
                                    />
                                    <p className={`text-[#2f2e2e] font-[Roboto-Regular]`}>{subCategoryItem?.name}</p>
                                </div>
                                <div className=' h-[20rem] overflow-y-scroll scrollbarRemover'>
                                    {/* vendors under sub-category  */}
                                    {
                                        props.subCatVendors && props.subCatVendors.subCatId === subCategoryItem?.id ? props.subCatVendors?.vendorArr && props.subCatVendors?.vendorArr.length > 0 ?  props.subCatVendors?.vendorArr?.map((subCatVendor) =>
                                            <div className='flex cursor-pointer' onClick={() => handleVendorDetail(subCatVendor?.userId)}>
                                        
                                                <p className='vendor_name font-[Poppins-Regular] text-sm vendor_name'>{subCatVendor?.name}</p>
                                            </div>
                                        ) : <p>No vendor found for {subCategoryItem?.name}</p>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        ) : 
                        <p className='text-[#2f2e2e]'>No Sub-Category data available</p>}
                    </div>
                    : null}
            </div>
        </ClickAwayListener>
    )
}

// eslint-disable-next-line react/no-typos
ScrollTab.propTypes = {
    categoriesArray: PropTypes.array
}

export default ScrollTab

