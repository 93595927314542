import React, { useEffect } from 'react'
import SearchResult from '../../Components/LandingPage/SearchResult'
import SnackBarComponent from '../../Common/Layout/Snackbar/SnackBar'
import { ForLanding } from './Hooks'
import { useSelector } from 'react-redux'
import { ForAdmin } from '../Admin/Hooks'
import { useParams } from 'react-router'

const SearchResulttMain = () => {
  const { getPostedReviews, postReviewsOfVendors, notification, messageClose } = ForLanding()

  const { getSingleVendorDetailsApi, checkEachVendorProductAdmin,
    getServiceByBusinessInAdmin,
    handleServices
  } = ForAdmin()

  const userId = useParams()
  const token = localStorage.getItem("token");
  // const BusinessDetails = useSelector((state) => state.landingData.businessDetails)
  useEffect(() => {
    getSingleVendorDetailsApi(userId?.id)
  }, [userId?.id])

  useEffect(() => {
    if (token) {
      checkEachVendorProductAdmin(userId?.id);
    }
  }, [userId?.id])
  
  // From reducer in admin, for business related data, where services can be found too
  const singleVendorsInAdminPage = useSelector((state) => state.totalVendorsInAdmin.singleVendor)

  useEffect(() => {
    getServiceByBusinessInAdmin(singleVendorsInAdminPage?.id);
  }, [singleVendorsInAdminPage?.id])
  useEffect(() => {
    getPostedReviews(singleVendorsInAdminPage?.id)
  }, [singleVendorsInAdminPage?.id])

  // From reducer in admin, for products related data
  const checkSingleVendorProductOffers = useSelector((state) => state.totalVendorsInAdmin.products)
  // for filtering checkSingleVendorProductOffers by productId only 
  const vendorProductOffersByProductId = checkSingleVendorProductOffers?.filter((item) => (item?.productId))
  const serviceData = useSelector((state) => state.totalVendorsInAdmin.services)
  
  return (
    <div>
      <SearchResult
        postReviewsOfVendors={postReviewsOfVendors}
        BusinessDetails={singleVendorsInAdminPage}
        serviceData={serviceData}
        handleServices={handleServices}
      />
      <SnackBarComponent
        messageOpen={notification.open}
        messageClose={messageClose}
        notificationText={notification.message}
        subText={notification.subText}
        alertType={notification.alertType}
        borderClass={notification.borderClass}
      />
    </div>
  )
}

export default SearchResulttMain