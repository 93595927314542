import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    totalVendors: [],
    businessData: [],
    singleVendor: {},
    services: [],
    products: [],
    requirements: [],
    servicesByServiceId: {},
    loading: false,
    error: false,
};

export const totalVendorInAdminSlice = createSlice({
    name: 'totalVendorsInAdmin',
    initialState,
    reducers: {
        getTotalVendors: (state, action) => {
            state.totalVendors = action.payload;
            state.loading = false;
            state.error = false;
        },
        getBusinessData: (state, action) => {
            state.businessData = action.payload;
            state.loading = false;
            state.error = false;
        },
        getSingleVendor: (state, action) => {
            state.singleVendor = action.payload;
            state.loading = false;
            state.error = false;
        },
        getSingleVendorProducts: (state, action) => {
            state.products = action.payload;
            state.loading = false;
            state.error = false;
        },
        getSingleVendorServices: (state, action) => {
            state.services = action.payload;
            state.loading = false;
            state.error = false;
        },
        getSingleVendorRequirements: (state, action) => {
            state.requirements = action.payload;
            state.loading = false;
            state.error = false;
        },
        getServicesByServiceId: (state, action) => {
            // console.log(action.payload,"#");
            state.servicesByServiceId = action.payload;
            state.loading = false;
            state.error = false;
        }
    }
},
)

export const { getTotalVendors, getBusinessData, getSingleVendor, getSingleVendorProducts, getSingleVendorServices, getSingleVendorRequirements, getServicesByServiceId } = totalVendorInAdminSlice.actions;
export default totalVendorInAdminSlice.reducer;