import React from 'react'
import Chatbox from '../../Components/VendorDetailsPage/Message/Chatbox'
import { ForVendor } from './Hooks'
import LandingHeader from '../../Common/Layout/LandingHeader/LandingHeader'
import Footer from '../../Common/Layout/Footer/Footer'
import { ForNotification } from '../Notification/Hooks'
import { useSelector } from 'react-redux'
import { ForUploadMediaHooks } from '../UploadMedia/Hooks'

const VendorMessageMain = () => {
    const { getChatsApiCAll, postChatApiCall, getChatbyChatIdApiCAll, getChatDetailsWithUserApiCAll, downloadPdfAttachments, downloadImage, chatMessageReadApi, handleSearchChat, chatSearchString } = ForVendor()
    const { showNotification, getNotification, handleshow, detailsPage, allnotification, readNotificationApi } = ForNotification()
    const { multipleMediaApiCall } = ForUploadMediaHooks()
    React.useEffect(() => {
        getNotification()
        getChatsApiCAll()
    }, [chatSearchString])

    const chatArr = useSelector((state) => state.vendor.chats)
    const singleChatDetail = useSelector((state) => state.vendor.chatById)
    const singleChatWith = useSelector((state) => state.vendor.chatWithByUserId)

    return (
        <>
            <Chatbox
                getChatbyChatIdApiCAll={getChatbyChatIdApiCAll}
                postChatApiCall={postChatApiCall}
                getChatDetailsWithUserApiCAll={getChatDetailsWithUserApiCAll}
                chatArr={chatArr}
                singleChatDetail={singleChatDetail}
                singleChatWith={singleChatWith}
                multipleMediaApiCall={multipleMediaApiCall}
                getChatsApiCAll={getChatsApiCAll}
                downloadPdfAttachments={downloadPdfAttachments}
                downloadImage={downloadImage}
                chatMessageReadApi={chatMessageReadApi}
                handleSearchChat={handleSearchChat}
            />
        </>
    )
}

export default VendorMessageMain