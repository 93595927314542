import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    totalUsers: [],
    selectedUserDetails: [],
    selectedVendorDetails: [],
    requirements: [],
    loading: false,
    error: false,
}

export const totalUsersSlice = createSlice({
    name: 'totalUsers',
    initialState,
    reducers: {
        getTotalUsers: (state, action) => {
            state.totalUsers = action.payload;
            state.loading = false;
            state.error = false;
        },
         getSelectedUserDetails: (state, action) => {
            state.selectedUserDetails = action.payload;
            state.loading = false;
            state.error = false;
        },
        getSelectedVendorDetails: (state, action) => {
            state.selectedVendorDetails = action.payload;
            state.loading = false;
            state.error = false;
        },
        getSingleUserRequirements: (state, action) => {
            state.requirements = action.payload;
            state.loading = false;
            state.error = false;
        },
    }
})

export const { getTotalUsers, getSelectedUserDetails, getSelectedVendorDetails, getSingleUserRequirements } = totalUsersSlice.actions;
export default totalUsersSlice.reducer;