import React, { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form';
import TextFieldInput from '../../../Common/FormFields/TextFieldInput';
import RadioButton from '../../../Common/UiComponents/RadioButton';
import ButtonField from '../../../Common/FormFields/ButtonField';
import text from '../../../Common/Languages/en_US.json'
import MuiDatePicker from '../../../Common/FormFields/MuiDatePicker';
import { useTranslation } from 'react-i18next';
import { ForUploadMediaHooks } from '../../../Containers/UploadMedia/Hooks';


const AddOfferForm = (props) => {
    const { cleanupMedia } = ForUploadMediaHooks()
    const { t } = useTranslation()
    const [radioValue, setRadioValue] = useState()

    // useEffect(() => {
    //     console.log(radioValue, "#")
    //   }, [radioValue])


    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm();

    const onSubmit = (data) => {
        props.createNewOffers(data)
        // resetting after submission
        props?.submitError?.error === false && reset({
            detail: '',
            type: '',
            percentage: 0,
            price: 0,
            offerTiming: null,
            // deliveryTime: '',
        })
    };

    // const [value, setValue] = React.useState('Percentage');

    // const handleChange = (event) => {
    //     setValue(event.target.value);
    // };

    const RadioLabel = [
        {
            value: "percentage",
            label: "Percentage"
        },
        {
            value: "amount",
            label: "Amount"
        },
    ]

    return (
        <div>
            <p className={`p-4`}>{t("addOffer")}</p>
            <div className={`border border-b`}></div>
            <div className={`px-[4%] py-[3%]`}>
                <form onSubmit={handleSubmit(onSubmit)}>

                    {/* Detail  */}
                    <Controller name={"detail"}
                        control={control}
                        render={({ field: { onChange, value }, formState: { error } }) => {
                            return (
                                (<TextFieldInput
                                    onlyValue
                                    // textinputname="Describeoffer"
                                    textnewclass={`w-full md:w-[60%] text-sm`}
                                    floatingLabel='*describeOffer'
                                    value={(value)}
                                    onChange={onChange}
                                />)
                            )
                        }}
                        rules={{
                            required: true, pattern: /^[a-zA-Z][a-zA-Z ]*/i, minLength: 5, maxLength: 100
                        }}
                    />
                    {/* details' error messages */}
                    {errors.detail && errors.detail.type === "required" && (
                        <span className="error-message text-red-400 text-xs">Required</span>
                    )}
                    {errors.detail && errors.detail.type === "minLength" && (
                        <span className="error-message text-red-400 text-xs">Too Short</span>
                    )}
                    {errors.detail && errors.detail.type === "maxLength" && (
                        <span className="error-message text-red-400 text-xs">Too Long</span>
                    )}
                    {errors.detail && errors.detail.type === "pattern" && (
                        <span className="error-message text-red-400 text-xs">Not Valid</span>
                    )}

                    <div className={`flex flex-col md:flex-row py-[5%] justify-between w-[60%] md:items-center`}>
                        {/* Type  */}
                        <div className={`pl-[2%]`}>
                            <Controller name={'type'}
                                control={control}
                                rules={{
                                    required: true
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <RadioButton
                                        name='type'
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                            setRadioValue(e.target.value)
                                        }}
                                        Radiolabel={RadioLabel}
                                    />
                                )}
                            />
                            <div>
                                {errors.type && errors.type.type === "required" && (
                                    <span className="error-message text-red-400 text-xs">Required</span>
                                )}
                            </div>
                        </div>

                        {/* Percentage or Price  */}
                        <div>
                            <div className='flex justify-center items-center gap-4'>
                                <Controller name={radioValue === "percentage" ? "percentage" : "price"}
                                    control={control}
                                    render={({ field: { onChange, value }, formState: { error } }) => {
                                        return (
                                            (<TextFieldInput
                                                onlyValue
                                                typeNumber={true}
                                                textinputname='totaloffer'
                                                textnewclass={`w-full text-sm h-9`}
                                                floatingLabel={radioValue === "percentage" ? 'totalOfferPercentage' : radioValue === "amount" ? "Amount" : ''}
                                                value={(value)}
                                                onChange={onChange}
                                            />)
                                        )
                                    }}
                                    rules={{
                                        required: true, pattern: /^[0-9]+$/, maxLength: radioValue === "percentage" ? 2 : 4
                                    }}
                                />
                                {radioValue === 'percentage' && <p className={`mt-2 `}>%</p>}
                            </div>
                            <div className='mt-3'>
                                {radioValue === "percentage" ?
                                    errors.percentage && errors.percentage.type === "required" && (
                                        <span className="error-message text-red-400 text-xs">Required</span>
                                    )
                                    :
                                    errors.price && errors.price.type === "required" && (
                                        <span className="error-message text-red-400 text-xs">Required</span>
                                    )
                                }
                                {radioValue === "percentage" ?
                                    errors.percentage && errors.percentage.type === "maxLength" && (
                                        <span className="error-message text-red-400 text-xs">Too Long</span>
                                    )
                                    :
                                    errors.price && errors.price.type === "maxLength" && (
                                        <span className="error-message text-red-400 text-xs">Too Long</span>
                                    )
                                }
                                {radioValue === "percentage" ?
                                    errors.percentage && errors.percentage.type === "pattern" && (
                                        <span className="error-message text-red-400 text-xs">Not Valid</span>
                                    )
                                    :
                                    errors.price && errors.price.type === "pattern" && (
                                        <span className="error-message text-red-400 text-xs">Not Valid</span>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    {/* Offer Timing  */}
                    <div className={`pt-[1%] pb-[4%] flex flex-col w-[30%] datepickerfield`}>
                        <Controller name={"offerTiming"}
                            control={control}
                            defaultValue={null}
                            render={({ field: { onChange, value }, formState: { error } }) => {
                                return (
                                    (
                                        <MuiDatePicker
                                            label='*offerTiming'
                                            value={value}
                                            format={'dd-MM-yyyy'}
                                            onChange={(offerTiming) => {
                                                onChange(offerTiming)
                                            }}
                                        />
                                    )
                                )
                            }}
                            rules={{ required: true }}
                        />
                        {errors.offerTiming && errors.offerTiming.type === "required" && (
                            <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                        )}
                    </div>

                    {/* Buttons  */}
                    <div className={`flex w-[65%] md:justify-end py-[5%]`}>
                        <ButtonField
                            buttonName={`cancel`}
                            type='reset'
                            buttonextracls={` !px-5 !py-2 !text-skyblue-5 bg-gray-0.5 mr-7 font-[Roboto-Medium]`}
                            onClick={() => {
                                props.goBackToPendingServiceOfferedTab();
                                cleanupMedia()
                            }}
                        />
                        <ButtonField
                            buttonName={`submitOffer`}
                            type='submit'
                            loading={props.loading}
                            buttonextracls={`!px-5 !py-2 !text-white bg-skyblue-6 font-[Roboto-Medium]`}
                            onClick={handleSubmit(onSubmit)}
                            disabled={props.loading === true ? true : false}
                        />
                    </div>

                    {/* Error message if the form is not submitted properly  */}
                    {props?.submitError?.error === true && <p className={`text-red-5 text-sm text-center font-[Roboto-Regular]`}>{props?.submitError?.message}</p>}
                </form>
            </div>
        </div>

    )
}

export default AddOfferForm