import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import TabComponent from '../../../Common/UiComponents/TabComponent'
import OfferActivePage from '../../VendorDetailsPage/Offers/OfferActivePage'
import OfferPendingPage from '../../VendorDetailsPage/Offers/OfferPendingPage'
import { ForAdmin } from '../../../Containers/Admin/Hooks'

const SingleVendorOfferTab = (props) => {

  const { value, setValue } = ForAdmin()

  // From reducer in admin, for products related data
  const checkSingleVendorProductOffers = useSelector((state) => state.totalVendorsInAdmin.products)
  // for filtering checkSingleVendorProductOffers by productId only 
  const vendorProductOffersByProductId = checkSingleVendorProductOffers?.filter((item) => (item?.productId))

  const categories = [
    {
      label: "Approved",
      value: "0",
      backgroundcolor: "bg-[#F7F7F7]",
      content: <OfferActivePage tabledata={vendorProductOffersByProductId} loading={props.loading}/>
    },
    {
      label: "pending",
      value: "1",
      backgroundcolor: "bg-[#F7F7F7]",
      content: <OfferPendingPage tabledata={vendorProductOffersByProductId} buttonLoading={props.buttonLoading} loading={props.loading}/>
    },
  ]

  useEffect(() => {
    if (value === '0') {
      props.checkEachVendorProductAdmin(props.userId?.id, 'active')
    } else if (value === '1') {
      props.checkEachVendorProductAdmin(props.userId?.id, 'pending')
    }
  }, [props.userId?.id, value])

  return (
    <div>
      <TabComponent
        mappingname={categories}
        button={false}
        value={value}
        setValue={setValue}
      // value={props.value}
      // setValue={props.setValue}
      />
    </div>
  )
}

export default SingleVendorOfferTab