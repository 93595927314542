import { ClickAwayListener, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import PropTypes from 'prop-types';
import dropArrow from '../../Images/CommonImages/dropArrow.svg'
import { useTranslation } from 'react-i18next';
function CustomDropDown(props) {

  const { t } = useTranslation()
  const [openDrop, setOpenDrop] = useState(false)

  const openDropDown = () => {
    setOpenDrop(!openDrop)
  }

  return (
    <div className={`relative`}>
      <div
        className={`flex justify-between items-center cursor-pointer ${props.dropDownRoot}`} onClick={() => openDropDown()}>
        {props.startIcon && <img src={props.src} alt={props.alt} className={`h-[16px] w-[16px]`} />}
        <Tooltip title={props.dropDownName?.name ? t(props.dropDownName?.name) : t(props.placeholder)}>
        <p
          value={props.dropDownValue?.value ? props.dropDownValue?.value : props.value}
          className={`font-medium font-[Roboto-Medium] tracking-[0.15px] text-white text-xs leading-5 capitalize ${props.nameExtraCls} truncate w-20`}>
          {props.extraContain ? <>{props.extraContain}</> : <>{props.dropDownName?.name ? t(props.dropDownName?.name) : t(props.placeholder)}</>}

        </p>
        </Tooltip>
        <img src={props.dropArrowSrc ? props.dropArrowSrc : dropArrow} alt='dropArrow' className={`${openDrop === true ? `rotate-180` : null} ${props.imgCls}`} />
      </div>
      {openDrop === true ?
        <ClickAwayListener onClickAway={() => setOpenDrop(false)}>
          <div
            className={`h-fit w-28 bg-white shadow-md absolute top-7 rounded-b z-10 right-0 pb-1.5 ${props.dropdownBoxExtraCls}`}
          >
            {props.dropDownArray.map((item, index) =>
              <p
                key={index}
                value={item.value}
                onClick={() => { props.setValue(item); setOpenDrop(false); props.changeLanguage(item.value) }}
                className={`font-medium text-gray-60 font-[Roboto-Medium] tracking-[0.15px] text-xs py-1 pl-3 border-b border-solid border-Gray-5  leading-5 cursor-pointer capitalize ${props.dropdownNameExtraCls}`}>
                {t(item.name)}
              </p>
            )}
          </div>
        </ClickAwayListener>
        : null}
    </div>
  )
}

// eslint-disable-next-line react/no-typos
CustomDropDown.propTypes = {
  startIcon: PropTypes.bool,
  src: PropTypes.string,
  alt: PropTypes.string,
  dropDownValue: PropTypes.any,
  dropDownName: PropTypes.any,
  dropDownArray: PropTypes.array,
  setValue: PropTypes.any,
  dropdownNameExtraCls: PropTypes.string,
  dropdownBoxExtraCls: PropTypes.string,
  nameExtraCls: PropTypes.string,
  dropDownRoot: PropTypes.string
}


export default CustomDropDown