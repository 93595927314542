import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';
import ButtonField from '../FormFields/ButtonField';

const Modal = (props) => {
  const [open, setOpen] = React.useState(false);

  const loading = useSelector((state) => state.upload.loading)
  // React.useEffect(() => {console.log(loading, "loading")}, [loading])

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <>
        <Dialog
          open={props.open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          className={` ${props.DialogMainExtraCls}`}
          onClick={props.handleClickOpen}
        >
          <DialogTitle id="responsive-dialog-title" className={`flex justify-between text-lg items-center px-5 py-3`}>
            {props.HeaderTxt}
            {/* <CloseIcon onClick={props.handleClose} className={`cursor-pointer text-slate-500`} /> */}
          </DialogTitle>
          <DialogContent className={`px-5 py-3 text-sm w-80`}>
            <DialogContentText >
              {props.DilogContent}
            </DialogContentText>
            {props.errorMsg &&
              <DialogContentText className={`text-xs text-red-500 pt-1 pl-1`}>
                {props.errorMsg}
              </DialogContentText>}
          </DialogContent>
         
            {/* <Button autoFocus onClick={props.handleClose} className={props.CancelBtncls}>
              {props.CancelmBtn}
            </Button> */}
        </Dialog>
      </>
    </div>
  )
}

export default Modal