import React from 'react'
import LandingHeader from '../../../Common/Layout/LandingHeader/LandingHeader'
import Footer from '../../../Common/Layout/Footer/Footer'
import { NavLink, Outlet } from 'react-router-dom'
import { List, ListItem, ListItemButton, ListItemIcon } from '@mui/material'
import TotalUsersHeader from '../../Admin/TotalUsers/TotalUsersHeader'
import Lock from '../../../Images/CommonImages/Lock.svg'
import World from '../../../Images/CommonImages/World.svg' 
import { useTranslation } from 'react-i18next'

const VendorSettings = () => {

    const { t } = useTranslation()

    const vendorsSettingSideMenu = [
        { name: 'changePass', icon: Lock, link: '/vendorsettings/changepassword' },
        { name: 'changeLang', icon: World, link: '/vendorsettings/languagechange' },
    ]

    const activeStyle = {
        backgroundColor: "#E8EFF5",
        fontFamily: 'Roboto-Regular',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '15px',
        margin: '0px',
        padding: '0px',
        paddingRight: '0px',
        color: '#585F71',
        borderRadius: '5px 5px 0px 0px',
    };

    const style = {
        color: '#585F71',
        fontFamily: 'Roboto-Regular',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '15px',
    }

    return (
        <div>
            <div className='sticky top-0 z-50'>
                <LandingHeader
                    searchBar={true}
                    goTomessage={true}
                    goToNotification={true}
                    border={true}
                    profile={true}
                />
            </div>
            <div className={`p-[3%] !bg-[#F7F7F7] `}>
                <TotalUsersHeader userName={`Settings`} dropDown={false} addBtn={false} csvBtn={false} />
                <div className={` flex w-full border border-slate-200 bg-white`}>
                    <div className={`w-[20%]`}>
                        {vendorsSettingSideMenu && vendorsSettingSideMenu?.length > 0 &&
                            vendorsSettingSideMenu?.map((item, index) => {
                                return (<nav key={index}>
                                    <List className={`navItems `} sx={{ marginBottom: "0.5rem", marginTop: "0.5rem", paddingTop: 0, paddingBottom: "2px" }}>
                                        <ListItem disablePadding >
                                            <NavLink
                                                to={item?.link}
                                                style={
                                                    ({ isActive }) => isActive ? activeStyle : style
                                                }
                                                className={`w-full`}
                                            >
                                                <ListItemButton className='py-4'>
                                                    <ListItemIcon>
                                                        <img src={item?.icon} alt="menu" className="menuImg" />
                                                    </ListItemIcon>
                                                    <div>
                                                        <p>{t(item?.name)}</p>
                                                    </div>
                                                </ListItemButton>
                                            </NavLink>
                                        </ListItem>
                                    </List>
                                </nav>)
                            })
                        }
                    </div>

                    {/* Here the child component is getting rendered  */}
                    <div className={`w-full`}>
                        <Outlet />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default VendorSettings