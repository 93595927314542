import React, { useEffect } from 'react'
import SeeAllPage from '../../Components/LandingPage/SeeAllPage'
import { ForSignup } from '../Authentication/Signup/Hook'
import { ForVendor } from '../VendorDetailPage/Hooks'
import { ForLanding } from './Hooks'
import SeeAllServiceOffer from '../../Components/LandingPage/SeeAllServiceOffer'

const SeeAllServiceIndex = () => {

    const {  onHandelOpeModal } = ForLanding()
    const { backtoPage } = ForVendor()

    return (
        <div>
            <SeeAllServiceOffer backtoPage={backtoPage}
                onHandelOpeModal={onHandelOpeModal}
            />
        </div>
    )
}

export default SeeAllServiceIndex