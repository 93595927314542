import React, { useState } from 'react'
import SettingSidebar from './SettingSidebar'
import HyperlinkIcon from '../../../Images/Setting/HyperlinkIcon.svg'
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import optionMenuIcon from '../../../Images/adminTable/optionMenu.svg'
import editIcon from '../../../Images/adminTable/editIcon.svg'
import deleteIcon from '../../../Images/adminTable/deleteIcon.svg'
import { CircularProgress, ClickAwayListener } from '@mui/material';

const HyperlinkList = (props) => {
    const [openMenu, setOpenMenu] = useState(false)
    const [rowId, setRowId] = useState(false)

    const allLinks = useSelector((state) => state.setting.hyperlinks)

    const goToLink = (myLink) => {
        // window.location.href = myLink;
        window.open(myLink, '_blank', 'noreferrer')
    }

    const threeDotOptions = (idx) => {
        // setOpenMenu(name, true)
        setOpenMenu(!openMenu)
        setRowId(idx)
    }

    return (
        <div className={`h-[65vh]`}>
            {props.loading === true ?
                <div className='flex justify-center h-full items-center'>
                    <CircularProgress size={20} color='success' />
                    <p className='pl-3'>Loading...</p>
                </div>
                :
                <div className={`px-[2%] py-[3%] bg-white gridContainer sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-5 min-h-[6rem]`}>
                    {/* hyperlink card  */}
                    {allLinks?.map((item, index) => (
                        <div key={index} className={`bg-white  px-[4%] py-[12%] border-2 rounded-md drop-shadow-sm border-gray-0.5 flex relative justify-center`}>
                            <div onClick={() => threeDotOptions(item.id)} className={`text-right flex justify-end p-2 cursor-pointer absolute  right-1 top-1`}>
                                <img src={optionMenuIcon} alt="option icon" className='text-start' />
                                {openMenu && rowId === item.id ?
                                    <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
                                        <div className={`bg-white shadow-md  w-[9rem] h-[2rem] rounded absolute right-4 top-0 flex p-1 items-center z-50`}>
                                            <div className={`flex items-center justify-center gap-2 `}>
                                                <div className='flex gap-1 items-center'
                                                    onClick={() => props.editHyperlinkForm(item)}>
                                                    <img src={editIcon} alt="editIcon" width='12px' height='6px' />
                                                    <span className={`text-xs text-gray-2 font-normal font-[Roboto-Regular]`}>Edit</span>
                                                </div>
                                                <div className={`border-l mx-4 border-solid border-gray-1 h-6`}></div>
                                                <div className='flex gap-1 items-center' onClick={() => props.deleteHyperlinkApi(item)}>
                                                    <img src={deleteIcon} alt="deleteIcon" width='12px' height='6px' />
                                                    <span className={`text-xs text-gray-2 font-normal font-[Roboto-Regular]`}>Delete</span>
                                                </div>
                                                <div className={`border-l mx-4 border-solid border-gray-1 h-6`}></div>
                                            </div>
                                        </div>
                                    </ClickAwayListener>
                                    :
                                    null
                                }
                            </div>
                            <div className='flex flex-col gap-8 pt-4 justify-center items-center cursor-pointer' onClick={() => goToLink(item?.url)}>
                                <img
                                    src={item?.picture?.key ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${item?.picture?.key}&height=20&width=auto` : HyperlinkIcon}
                                    alt='hyperlink-icon'
                                    className='hyperlinkImg rounded-full'
                                    crossOrigin='anonymous'
                                />
                                <p className='text-[17px] font-[Roboto-Regular] text-deepgrey-3 text-center'>{item?.name}</p>
                            </div>
                        </div>
                    ))
                    }
                </div>
            }
        </div>
    )
}

HyperlinkList.propTypes = {
    allLinks: PropTypes.bool,

}
export default HyperlinkList