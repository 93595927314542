import React from 'react'
import SeeAllPageForVendor from '../../Components/LandingPage/SeeAllPageForVendor'
import { ForLanding } from './Hooks'

const VendorDetailsindex = () => {
  const { backtoPage, onHandelOpeModal, getChatDetailsWithUserApiCAll } = ForLanding()

  return (
    <div>
      <SeeAllPageForVendor backtoPage={backtoPage} onHandelOpeModal={onHandelOpeModal} chatCheck={getChatDetailsWithUserApiCAll} />
    </div>
  )
}

export default VendorDetailsindex