import React from 'react'
import WriteAReview from '../../Common/Layout/ProductDetailPage/WriteAReview'
import { useSelector } from 'react-redux'

const SearchResult = (props) => {
    
    return (
        <WriteAReview
            BusinessDetails={props.BusinessDetails}
            postReviewsOfVendors={props.postReviewsOfVendors}
            serviceData={props.serviceData}
            handleServices={props.handleServices}
            reviewDate={true}
        // deleteReviews={props.deleteReviews}
        />
    )
}

export default SearchResult