import React, { useEffect } from 'react'
import TotalUserCompo from '../../Components/Admin/TotalUsers'
import { ForAdmin } from './Hooks';
import { Outlet, useNavigate } from 'react-router-dom';
import SnackBarComponent from '../../Common/Layout/Snackbar/SnackBar';
import { useJwt } from 'react-jwt';

const TotalUsersIndex = () => {
    const {
        getAllUsers,
        userForm,
        toggleUserForm,
        createNewUserByAdmin,
        editUserForm,
        editUserApi,
        editUserFormData,
        getTotalUserDetailsApi,
        approveUserApi,
        downloadExcelReport,
        userEnableDisableApi,
        setGovFieldData,
        searchString,
        handleSearchUser,
        totalUserCreateMsg,
        adminSnackbar,
        messageClose, 
        loading,
        giveBadgetoUser,
        buttonLoading
    } = ForAdmin()

    useEffect(() => {
        getAllUsers()
    }, [searchString]);

    const navigate = useNavigate()

    // For refresh token when actual token expires 
    let token = localStorage.getItem('token') ? localStorage.getItem('token') : "";
    const { isExpired } = useJwt(token);
    const checkTokenExpiration = () => {
        if (isExpired) {
            navigate('/login');
        }
    }

    return (
        <div onClick={() => checkTokenExpiration()}>
            <TotalUserCompo
                getAllUsers={getAllUsers}
                userForm={userForm}
                toggleUserForm={toggleUserForm}
                createNewUserByAdmin={createNewUserByAdmin}
                editUserForm={editUserForm}
                editUserApi={editUserApi}
                editUserFormData={editUserFormData}
                getTotalUserDetailsApi={getTotalUserDetailsApi}
                approveUserApi={approveUserApi}
                downloadExcelReport={downloadExcelReport}
                userEnableDisableApi={userEnableDisableApi}
                setGovFieldData={setGovFieldData}
                handleSearchUser={handleSearchUser}
                totalUserCreateMsg={totalUserCreateMsg}
                loading={loading}
                giveBadgetoUser = {giveBadgetoUser}
                buttonLoading={buttonLoading}
            />
            <Outlet />
            <SnackBarComponent
                messageOpen={adminSnackbar.open}
                messageClose={messageClose}
                notificationText={adminSnackbar.message}
                subText={adminSnackbar.subText}
                alertType={adminSnackbar.alertType}
                borderClass={adminSnackbar.borderClass}
            />
        </div>
    )
}

export default TotalUsersIndex