import React, { useEffect } from 'react'
import text from "../../../Common/Languages/en_US.json"
import TotalUsersHeader from '../TotalUsers/TotalUsersHeader'
import HyperlinkForm from './HyperlinkForm'
import HyperlinkList from './HyperlinkList'
import SettingSidebar from './SettingSidebar'
import SubAdminFileUpload from './SubAdminFileUpload'
import PropTypes from 'prop-types';


const HyperlinkMain = (props) => {
    return (
        <div className='flex'>

            {/* <SettingSidebar /> */}
            <div className='w-full bg-white'>
                {
                    props.hyperlinkForm === false ? (<div>
                        <TotalUsersHeader userName={`Add Hyperlink`} dropDown={false} addBtn={true} csvBtn={false} btnColor={false} toggleSubAdmin={props.showHyperlinkForm} />

                        <HyperlinkList createHyperlink={props.createHyperlink} allLinks={props.allLinks} editHyperlinkForm={props.editHyperlinkForm} deleteHyperlinkApi={props.deleteHyperlinkApi} loading={props.loading}/>

                    </div>) : (
                        <>
                            <TotalUsersHeader userName={`Add Hyperlink`} dropDown={false} csvBtn={false} btnColor={false} />
                            <div className='flex'>

                                <div className='w-[70%]'>
                                    <HyperlinkForm
                                        createHyperlink={props.createHyperlink}
                                        goToLinkTable={props.goToLinkTable}
                                        hyperlinkForm={props.hyperlinkForm}
                                        hyperlinkFormData={props.hyperlinkFormData}
                                        editHyperlinkForm={props.editHyperlinkForm}
                                        editHyperlinkApi={props.editHyperlinkApi}
                                        doUploadedLogo={props.doUploadedLogo}
                                        loading={props.loading}
                                    />
                                </div>
                            </div>
                        </>
                    )
                }


            </div>

        </div>
    )
}

// proptypes 
HyperlinkMain.propTypes = {
    showHyperlinkForm: PropTypes.func,
    hyperlinkForm: PropTypes.bool,
    createHyperlink: PropTypes.func,
    allLinks: PropTypes.array,
    goToLinkTable: PropTypes.func,
    uploadData: PropTypes.array,

}

export default HyperlinkMain