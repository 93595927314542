import React, { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form';
import TextFieldInput from '../../../Common/FormFields/TextFieldInput';
import DropDownField from '../../../Common/FormFields/DropDownField';
import MultipleSelect from '../../../Common/FormFields/MultipleSelect';
import { useDispatch, useSelector } from 'react-redux';
import MuiDatePicker from '../../../Common/FormFields/MuiDatePicker';
import MuiTimePicker from '../../../Common/FormFields/MuiTimePicker';
import UploadButton from '../../../Common/UiComponents/UploadButton';
import ButtonField from '../../../Common/FormFields/ButtonField';
import UploadMultipleImage from '../../../Common/Layout/UploadMultipleImage/UploadMultipleImage';
import CloseIcon from '@mui/icons-material/Close';
import fileUploadIcon from "../../../Images/SubAdmin/fileUploadIcon.svg"
// import locationicon from "../../../Images/Vendor/distance.svg"
import locationImage from "../../../Images/Setting/locationImg.svg"
import text from '../../../Common/Languages/en_US.json'
import moment from 'moment';
import pdficon from '../../../Images/CommonImages/pdficon.svg'
import { ForUploadMediaHooks } from '../../../Containers/UploadMedia/Hooks';
import { setUploadMultipleImg, setUploadedData, setUploadedLogo } from '../../../Containers/UploadMedia/reducer/UploadMediaReducer'
import { getLocationReducer } from '../../../Containers/Authentication/Signup/reducer/VendorAccountReducer';
import { useTranslation } from 'react-i18next';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { ForSignup } from '../../../Containers/Authentication/Signup/Hook';

const EditBusinessInfo = (props) => {
    const { multipleUpload, deleteUploadedMediaApiCall, cleanupMedia, documentUpload, logoUpload } = ForUploadMediaHooks()
    const dispatch = useDispatch()
    const { getLocationData, mapLocationTxt } = ForSignup()
    const { t } = useTranslation()
    const locationSelector = useSelector((state) => state.vendorAccount.location)
    // console.log(locationSelector, '# locationSelector')

    const { handleSubmit, reset, control, handleError, formState: { errors } } = useForm({
        defaultValues: {
            name: props.vendorProfileData?.name ? props.vendorProfileData?.name : '',
            governorate: props.vendorProfileData?.governorate ? props.vendorProfileData?.governorate : '',
            places: props.vendorProfileData?.places ? props.vendorProfileData?.places : '',
            location: props.vendorProfileData?.location ? props.vendorProfileData?.location : '',
            date: props.vendorProfileData?.establishmentYear ? props.vendorProfileData?.establishmentYear : null,
            lat: props.vendorProfileData?.lat ? props.vendorProfileData?.lat : '',
            lng: props.vendorProfileData?.long ? props.vendorProfileData?.long : '',
            fullAddress: props.vendorProfileData?.address ? props.vendorProfileData?.address : '',
            street: props.vendorProfileData?.street ? props.vendorProfileData?.street : '',
            block: props.vendorProfileData?.block ? props.vendorProfileData?.block : '',
            buildingNo: props.vendorProfileData?.buildingNo ? props.vendorProfileData?.buildingNo : '',
            floor: props.vendorProfileData?.floor ? props.vendorProfileData?.floor : '',
            officeNo: props.vendorProfileData?.officeNo ? props.vendorProfileData?.officeNo : null,
            startTime: props.vendorProfileData?.startTime ? (new Date(["1999-04-06", props.vendorProfileData?.startTime])) : null,
            endTime: props.vendorProfileData?.endTime ? new Date(["1999-04-06", props.vendorProfileData?.endTime]) : null
        }
    });
    const loading = useSelector((state) => state.upload.loading)

    // const [uploadDocuments, setUploadDocuments] = useState(props.vendorProfileData?.civilDocument)
    // const [uploadLogoProfile, setUploadLogoProfile] = useState(props.vendorProfileData?.logo)
    // const [thumbnailMulti, setThumbnailMulti] = useState([])

    const pdfMedia = documentUpload
    const logoVar = logoUpload
    // const multipleUploadVar = [...multipleUpload, ...editMultiUpload]
    const multipleUploadVar = [...multipleUpload]

    const [addLocation, setAddLocation] = useState(props.vendorProfileData?.governorate ? props.vendorProfileData?.governorate : '')
    const [input, setInput] = useState(props.vendorProfileData?.places ? props.vendorProfileData?.places : null);

    const [openMap, setOpenMap] = useState(false)
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`
    })
    const center = React.useMemo(() => ({
        lat: parseFloat(sessionStorage.getItem('latitude')),
        lng: parseFloat(sessionStorage.getItem('longitude'))
    }), [])
    const [marker, setMarker] = useState({})

    const onMapClick = React.useCallback((event) => {
        getLocationData(event.latLng.lat(), event.latLng.lng())
        setMarker({
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
            address: mapLocationTxt,
        });
    }, []);


    const onSubmit = (data) => {
        props.editVendorBusinessDetailsApi(data, marker)
        reset()
    }

    const removeParticularThumbnail = (idx, id, key, type) => {
        if (idx > -1) {
            let arr;
            arr = [...multipleUploadVar]
            arr.splice(idx, 1);
            dispatch(setUploadMultipleImg(arr))
            deleteUploadedMediaApiCall(id, key, "thumbnail")
        }
    }

    useEffect(() => { 
        if (props.vendorProfileData) {
            dispatch(setUploadedData(props.vendorProfileData?.civilDocument))
            dispatch(setUploadedLogo(props.vendorProfileData?.logo))
            dispatch(setUploadMultipleImg(props.vendorProfileData?.thumbnail))
        }
    }, [])

    const governorateArray = [
        {
            name: 'Farwaniya',
            value: 'Farwaniya',
            FarwaniyaLocations: [
                { name: 'Jaleeb Al Shuwaikh', value: 'Jaleeb Al Shuwaikh' },
                { name: 'Dajeej', value: 'Dajeej' },
                { name: 'Ardiya', value: 'Ardiya' },
                { name: 'Riggae', value: 'Riggae' },
                { name: 'Farwaniya', value: 'Farwaniya' },
                { name: 'Kaithan', value: 'Kaithan' },
                { name: 'Al Rai', value: 'Al Rai' }
            ]
        },
        {
            name: 'Al Asimah',
            value: 'Al Asimah',
            AlAsimahLocations: [
                { name: 'Shuwaikh', value: 'Shuwaikh' }
            ]
        },
        {
            name: 'Hawally',
            value: 'Hawally',
            HawallyLocations: [
                { name: 'Hawally', value: 'Hawally' },
                { name: 'Jabriya', value: 'Jabriya' },
                { name: 'Salmiya', value: 'Salmiya' },
                { name: 'Shaab', value: 'Shaab' }
            ]
        },
        {
            name: 'Mubarak Al Kabeer',
            value: 'Mubarak Al Kabeer',
            MubarakAlKabeerLocations: [
                { name: 'Sabah Al Salem', value: 'Sabah Al Salem' },
                { name: 'Kuwait City', value: 'Kuwait City' },
                { name: 'Qurain', value: 'Qurain' }
            ]
        },
        {
            name: 'Ahmadi',
            value: 'Ahmadi',
            AhmadiLocations: [
                { name: 'Mahboula', value: 'Mahboula' },
                { name: 'Mangaf', value: 'Mangaf' },
                { name: 'Fahaheel', value: 'Fahaheel' },
                { name: 'Ahmadi', value: 'Ahmadi' }
            ]
        },
        {
            name: 'Jahra', value: 'Jahra',
            JahraLocations: [
                { name: 'Jahra', value: 'Jahra' },
            ]
        },
    ]

    const noLocationFound = [
    ]    

    return (
        <div className={`flex w-full px-[5%] py-[4.5%] flex-col items-center md:items-stretch`}>
            <form onSubmit={handleSubmit(onSubmit, handleError, reset)}>
                <div className={`w-full flex justify-between`}>
                    <div className={`w-2/4`}>
                        <div className={`flex flex-col items-start justify-evenly  `}>

                            {/* Field for bussiness Name */}
                            <div className={`w-full my-2`}>
                                <Controller
                                    name={"name"}
                                    control={control}
                                    rules={{
                                        required: props.vendorProfileData?.name ? false : true
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextFieldInput
                                            onlyValue
                                            extracls={`h-11 text-sm `}
                                            floatingLabel={text.enterBusinessName}
                                            variant="outlined"
                                            type="text"
                                            onChange={onChange}
                                            value={value}
                                        // disabled={props.vendorProfileData?.name ? true : false}
                                        // defaultValue={(props.vendorProfileData?.name ? props.vendorProfileData?.name : value)}
                                        />
                                    )}
                                />
                                {errors.name && errors.name.type === "required" && (<span className="error-message text-red-400 text-xs">Required</span>)}
                            </div>

                            {/* Field for governorate */}
                            {/* <div className={`w-full my-3  flex flex-col`}>
                                <Controller
                                    name={"governorate"}
                                    control={control}
                                    rules={{
                                        required: props.vendorProfileData?.governorate ? false : true
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DropDownField
                                            name='governorate'
                                            id='governorate'
                                            dropDownRootCls={`governorateCls`}
                                            size="small"
                                            selectOption={governorateArray}
                                            placeholder={text.enterGovernorate}
                                            handleChange={(e) => { setAddLocation(e.target.value); onChange(e.target.value) }}
                                            defaultValue={(props.vendorProfileData?.governorate ?
                                                props.vendorProfileData?.governorate : value ?
                                                    value : addLocation
                                            )}
                                            option={value ? value : addLocation}
                                        />
                                    )}
                                />
                                {errors.governorate && errors.governorate.type === "required" && (<span className="error-message text-red-400 text-xs">Required</span>)}
                            </div> */}

                            {/* Field for selecting place */}
                            {/* <div className={`w-full  my-3 flex flex-col`}>
                                <Controller
                                    name={"places"}
                                    control={control}
                                    rules={{
                                        required: props.vendorProfileData?.places ? false : true
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <MultipleSelect
                                            multiple={false}
                                            name="places"
                                            id='places'
                                            value={value ? value : input}
                                            onChange={(e, v) => { setInput(v.name); onChange(v.name) }}
                                            label={text.selectPlace}
                                            isOptionEqualToValue={(option, value) => { return option?.name === value?.name; }}
                                            placeholder={text.selectPlace}
                                            autocompleteExtraCls={`selectLocationCls`}
                                            autocompleteArray={
                                                addLocation === 'Farwaniya' ? governorateArray[0].FarwaniyaLocations
                                                    :
                                                    addLocation === 'Al Asimah' ? governorateArray[1].AlAsimahLocations
                                                        :
                                                        addLocation === 'Hawally' ? governorateArray[2].HawallyLocations
                                                            :
                                                            addLocation === 'Mubarak Al Kabeer' ? governorateArray[3].MubarakAlKabeerLocations
                                                                :
                                                                addLocation === 'Ahmadi' ? governorateArray[4].AhmadiLocations
                                                                    :
                                                                    addLocation === 'Jahra' ? governorateArray[5].JahraLocations
                                                                        : noLocationFound}
                                        />
                                    )}
                                />
                                {errors.places && errors.places.type === "required" && (<span className="error-message text-red-400 text-xs">Required</span>)}
                            </div> */}

                            {/* Field for location */}
                            <div className={`w-full my-2`}>
                                <Controller
                                    name={"location"}
                                    control={control}
                                    rules={{
                                        required: props.vendorProfileData?.location ? false : true
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextFieldInput
                                            onlyValue
                                            extracls={`h-11 text-sm `}
                                            floatingLabel={text.enterlocation}
                                            variant="outlined"
                                            type="text"
                                            defaultValue={(props.vendorProfileData?.location ? props.vendorProfileData?.location : value)}
                                            // disabled={props.vendorProfileData?.location ? true : false}
                                            onChange={(e) => onChange(e.target.value)}
                                            value={locationSelector && locationSelector?.length > 0 ? locationSelector : value}
                                            endAdornment={
                                                <div className={`flex justify-center items-center`}>
                                                {locationSelector && locationSelector?.length > 0 &&
                                                    <CloseIcon
                                                        sx={{ fontSize: "20px", cursor: "pointer" }}
                                                        onClick={() => dispatch(getLocationReducer('')) }
                                                    />
                                                }
                                                {!value &&
                                                    // <img src={locationImage} alt="locationImage" className='cursor-pointer' onClick={props.handleClickLocation} />
                                                    <img src={locationImage} alt="locationImage" className='cursor-pointer' onClick={() => setOpenMap(true)} />
                                                   
                                                }
                                            </div>
                                            }

                                        />
                                    )}
                                />
                                {errors.location && errors.location.type === "required" && (<span className="error-message text-red-400 text-xs">Required</span>)}
                            </div>
                            {openMap &&
                                    <>
                                        {isLoaded &&
                                            <GoogleMap
                                                mapContainerStyle={{
                                                    height: '400px',
                                                    width: '100%',
                                                }}
                                                zoom={10}
                                                center={center}
                                                onClick={onMapClick}
                                            >
                                                <MarkerF
                                                    position={{ lat: marker.lat, lng: marker.lng }}
                                                />
                                            </GoogleMap>
                                        }
                                    </>
                                }

                            {/* Field for Full address */}
                            <div className={`w-full my-2`}>
                                <Controller
                                    name={"fullAddress"}
                                    control={control}
                                    rules={{
                                        required: props.vendorProfileData?.address ? false : true
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextFieldInput
                                            onlyValue
                                            extracls={`h-11 text-sm `}
                                            floatingLabel="*Enter Full Address"
                                            textinputname="fullAddress"
                                            variant="outlined"
                                            type="text"
                                            id="fullAddress"
                                            defaultValue={(props.vendorProfileData?.address ? props.vendorProfileData?.address : value)}
                                            // disabled={props.vendorProfileData?.address ? true : false}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                                {errors.fullAddress && errors.fullAddress.type === "required" && (<span className="error-message text-red-400 text-xs">Required</span>)}
                            </div>

                            {/* Field for Street */}
                            <div className={`w-full my-2`}>
                                <Controller
                                    name={"street"}
                                    control={control}
                                    rules={{
                                        required: props.vendorProfileData?.street ? false : true
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextFieldInput
                                            onlyValue
                                            extracls={`h-11 text-sm `}
                                            floatingLabel="*Enter Street"
                                            textinputname="street"
                                            variant="outlined"
                                            type="text"
                                            id="street"
                                            defaultValue={(props.vendorProfileData?.street ? props.vendorProfileData?.street : value)}
                                            // disabled={props.vendorProfileData?.street ? true : false}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                                <small className={`text-red-500`}>
                                    {errors?.street && errors?.street?.type === "required" && (<p>Required</p>)}
                                </small>
                            </div>

                            {/* Fields for entering block and building no. */}
                            <div className={`flex w-full gap-4`}>
                                {/* Field for entering block */}
                                <div className={props.vendorProfileData?.user?.type === "business" ? `w-full my-2` : `w-[49%] my-2`}>
                                    <Controller
                                        name={"block"}
                                        control={control}
                                        rules={{
                                            required: props.vendorProfileData?.block ? false : true
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <TextFieldInput
                                                onlyValue
                                                extracls={`h-11 text-sm `}
                                                floatingLabel="*Enter Block"
                                                textinputname="block"
                                                variant="outlined"
                                                type="text"
                                                id="block"
                                                defaultValue={(props.vendorProfileData?.block ? props.vendorProfileData?.block : value)}
                                                // disabled={props.vendorProfileData?.block ? true : false}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                    />
                                    <small className={`text-red-500`}>
                                        {errors?.block && errors?.block?.type === "required" && (<p>Required</p>)}
                                    </small>
                                </div>
                                {/* Field for building no. */}
                                {props.vendorProfileData?.user?.type === "business" ?
                                    <div className={`w-full my-2`}>
                                        <Controller
                                            name={"buildingNo"}
                                            control={control}
                                            rules={{
                                                required: props.vendorProfileData?.buildingNo ? false : true
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <TextFieldInput
                                                    onlyValue
                                                    extracls={`h-11 text-sm `}
                                                    floatingLabel="*Enter Building no."
                                                    textinputname="buildingNo"
                                                    variant="outlined"
                                                    type="text"
                                                    id="buildingNo"
                                                    // disabled={props.vendorProfileData?.buildingNo ? true : false}
                                                    defaultValue={(props.vendorProfileData?.buildingNo ? props.vendorProfileData?.buildingNo : value)}
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        />
                                        <small className={`text-red-500`}>
                                            {errors?.buildingNo && errors?.buildingNo?.type === "required" && (<p>Required</p>)}
                                        </small>
                                    </div> : null}
                            </div>

                            {/* Fields for entering floor and office no.  */}
                            {props.vendorProfileData?.user?.type === "business" ?
                                <div className={`flex w-full gap-4`}>
                                    {/* Field for Floor */}
                                    <div className={`w-full my-2`}>
                                        <Controller
                                            name={"floor"}
                                            control={control}
                                            rules={{
                                                required: props.vendorProfileData?.floor ? false : true
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <TextFieldInput
                                                    onlyValue
                                                    extracls={`h-11 text-sm `}
                                                    floatingLabel="*Enter Floor"
                                                    textinputname="floor"
                                                    variant="outlined"
                                                    type="text"
                                                    id="floor"
                                                    defaultValue={(props.vendorProfileData?.floor ? props.vendorProfileData?.floor : value)}
                                                    // disabled={props.vendorProfileData?.floor ? true : false}
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        />
                                        <small className={`text-red-500`}>
                                            {errors?.floor && errors?.floor?.type === "required" && (<p>Required</p>)}
                                        </small>
                                    </div>

                                    {/* Field for Office Number */}
                                    <div className={`w-full my-2`}>
                                        <Controller
                                            name={"officeNo"}
                                            control={control}
                                            rules={{
                                                required: props.vendorProfileData?.officeNo ? false : true
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <TextFieldInput
                                                    onlyValue
                                                    extracls={`h-11 text-sm `}
                                                    floatingLabel="Enter Office Number"
                                                    textinputname="officeNo"
                                                    variant="outlined"
                                                    type="text"
                                                    id="officeNo"
                                                    // disabled={props.vendorProfileData?.officeNo ? true : false}
                                                    defaultValue={(props.vendorProfileData?.officeNo ? props.vendorProfileData?.officeNo : value)}
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        />
                                        <small className={`text-red-500`}>
                                            {errors?.officeNo && errors?.officeNo?.type === "required" && (<p>Required</p>)}
                                        </small>
                                    </div>
                                </div> : null}

                            {/* For date picker  */}
                            <div className={`w-[49%] my-2`}>
                                <Controller name={"date"}
                                    control={control}
                                    // rules={{ required: props?.typecheck === "business" ? true : false }} 
                                    defaultValue={props.vendorProfileData?.establishmentYear ? props.vendorProfileData?.establishmentYear : null}
                                    render={({ field: { onChange, value }, formState: { error } }) => {
                                        return (
                                            (<MuiDatePicker
                                                label='*Year of Establishment'
                                                disabled={props.vendorProfileData?.establishmentYear && true}
                                                value={value}
                                                views={['year']}
                                                format={'yyyy'}
                                                onChange={(newDate) => {
                                                    onChange(newDate)
                                                }}
                                            />)
                                        )
                                    }}
                                />
                                {errors.date && errors.date.type === "required" && (
                                    <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                )}
                            </div>

                            {/* For time picker  */}
                            <div className={`flex w-full gap-4`}>
                                <div className={`w-full my-3`}>
                                    <Controller name={"startTime"}
                                        control={control}
                                        render={({ field: { onChange, value }, formState: { error } }) => {
                                            return (
                                                (<MuiTimePicker
                                                    label={"Business Start Time"}
                                                    onChange={(date) => { onChange(date); console.log(date, "# date") }}
                                                    value={value}
                                                />)
                                            )
                                        }}
                                    />
                                    {errors.startTime && errors.startTime.type === "required" && (
                                        <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                    )}
                                </div>

                                <div className={`w-full my-3`}>
                                    <Controller name={"endTime"}
                                        control={control}
                                        defaultValue={null}
                                        render={({ field: { onChange, value }, formState: { error } }) => {
                                            return (
                                                (<MuiTimePicker
                                                    label={"Business End Time"}
                                                    onChange={onChange}
                                                    value={value}
                                                />)
                                            )
                                        }}
                                    />
                                    {errors.endTime && errors.endTime.type === "required" && (
                                        <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                    )}
                                </div>
                            </div>

                            {/* Field for Displaying Name */}
                            {/* <div className={`w-full my-2`}>
                                <Controller
                                    name={"displayName"}
                                    control={control}
                                    rules={{
                                        required: props.vendorProfileData?.displayName ? false : true
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextFieldInput
                                            onlyValue
                                            extracls={`h-11 text-sm `}
                                            floatingLabel="*Enter your display name"
                                            textinputname="displayName"
                                            variant="outlined"
                                            type="text"
                                            id="displayName"
                                            defaultValue={(props.vendorProfileData?.displayName ? props.vendorProfileData?.displayName : value)}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                                <small className={`text-red-500`}>
                                    {errors?.displayName && errors?.displayName?.type === "required" && (<p>Required</p>)}
                                </small>
                            </div> */}
                        </div>

                        <div className={`flex flex-col items-start justify-evenly h-[22em] `}>
                            {/* Upload file section */}
                            <div className={`flex w-full justify-between `}>
                                <div>
                                    <p className={`text-Grey-900 font-[Roboto-Medium] font-normal text-[13px] `}>{t("clickToUploadCivilId")}</p>
                                    <p className={`text-Grey-900 font-[Roboto-Regular] font-normal text-[12px] `}>Upload license and civil ID <br />as PDF or JPG</p>
                                </div>
                                {
                                    <div className={`relative`}>
                                        {pdfMedia && (pdfMedia?.mimetype === 'image/jpeg' || pdfMedia?.mimetype === 'application/pdf') &&
                                            <div className={`absolute -top-3 left-20 -right-4 cursor-pointer`}>
                                                <CloseIcon
                                                    sx={{ fontSize: "12px", border: '1px solid red', borderRadius: '9999px' }}
                                                    onClick={() => dispatch(setUploadedData({}))}
                                                />
                                            </div>
                                        }
                                        <UploadButton
                                            sendData={(data) => {
                                                if (data[0]?.type === 'application/pdf' || data[0]?.type === 'image/jpeg') {
                                                    props.doUploadedMedia(data)
                                                }
                                            }}
                                            extracls='flex justify-center items-center bg-white items-center rounded-md'
                                            extratextcls='text-xs w-16'
                                            type='button'
                                            fileUploadIcon={
                                                pdfMedia && pdfMedia?.mimetype === 'image/jpeg' ?
                                                    `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${pdfMedia?.key}&height=60&width=80`
                                                    :
                                                    pdfMedia?.mimetype === 'application/pdf' ?
                                                        pdficon :
                                                        fileUploadIcon
                                            }
                                            accept={'image/jpeg, application/pdf'}
                                            multiple={false}
                                            disabled={pdfMedia && (pdfMedia?.mimetype === 'image/jpeg' || pdfMedia?.mimetype === 'application/pdf') ? true : false}
                                            fileName={pdfMedia?.mimetype === 'application/pdf' ? (pdfMedia?.name || pdfMedia?.originalname) : null}
                                            fileSize={pdfMedia?.mimetype === 'application/pdf' ? pdfMedia?.size : null}
                                        />
                                    </div>
                                }
                            </div>

                            {/* For Logo  */}
                            <div className={`flex w-full justify-between`}>
                                <div>
                                    <p className={`text-Grey-900 font-[Roboto-Medium] font-normal text-base `}>*Upload Logo</p>
                                    <p className={`text-Grey-900 font-[Roboto-Regular] font-normal text-sm `}>Drag and drop or <span className={`text-skyblue-6 underline`}>browse</span> <br />your files</p>
                                </div>
                                {
                                    <div className={`relative`}>
                                        {logoVar &&
                                            (logoVar?.mimetype === 'image/jpeg' || logoVar?.mimetype === 'image/png' ||
                                                logoVar[0]?.mimetype === 'image/jpeg' || logoVar[0]?.mimetype === 'image/png') &&
                                            <div className={`absolute -top-2 left-24 -right-4 cursor-pointer`}>
                                                <CloseIcon
                                                    sx={{ fontSize: "12px", border: '1px solid red', borderRadius: '9999px' }}
                                                    onClick={() => dispatch(setUploadedLogo([]))}
                                                />
                                            </div>
                                        }
                                        <UploadButton
                                            sendData={(data) => {
                                                if (data[0]?.type === 'image/png' || data[0]?.type === 'image/jpeg') {
                                                    props.doUploadedLogo(data)
                                                }
                                            }}
                                            extracls='flex justify-center items-center bg-white  items-center rounded-md '
                                            type='button'
                                            fileUploadIcon={
                                                logoVar &&
                                                    (logoVar?.mimetype === 'image/jpeg' || logoVar?.mimetype === 'image/png' || logoVar[0]?.mimetype === 'image/jpeg' || logoVar[0]?.mimetype === 'image/png') ?
                                                    `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${logoVar?.key || logoVar[0]?.key}&height=20&width=auto` :
                                                    fileUploadIcon
                                            }
                                            accept={'image/jpg, image/png'}
                                            multiple={false}
                                            disabled={logoVar &&
                                                (logoVar?.mimetype === 'image/jpeg' || logoVar?.mimetype === 'image/png' ||
                                                    logoVar[0]?.mimetype === 'image/jpeg' || logoVar[0]?.mimetype === 'image/png') ?
                                                true : false
                                            }
                                        />
                                    </div>
                                }
                            </div>

                            {/* continue button */}
                            <div className='flex'>
                                <ButtonField
                                    buttonextracls={`${(Object.keys(errors) && Object.keys(errors).length > 0) || loading === true ? `bg-gray-400` : `bg-skyblue-6`} border-[1.5px] border-solid border-deepgray-0.5  w-32 h-12 mr-5`}
                                    buttonName="Update"
                                    buttonnamecls={`font-[Roboto-Medium] text-white text-sm`}
                                    type="submit"
                                    onClick={handleSubmit(onSubmit)}
                                    loading={props.loading}
                                    disabled={(Object.keys(errors) && Object.keys(errors).length > 0) || loading === true ? true : false}
                                />
                                <ButtonField
                                    buttonextracls={`cursor-pointer  border-[1.5px] border-solid border-deepgray-0.5 capitalize w-24 ml-5  h-12`}
                                    buttonName="Cancel"
                                    buttonnamecls={`font-[Roboto-Medium] text-deepgray-0.5 font-medium text-sm`}
                                    type="reset"
                                    onClick={() => { props.toggleEditProfile(); cleanupMedia(); dispatch(getLocationReducer('')) }}
                                />
                            </div>

                        </div>
                    </div>
                    <div className={`w-[45%] pt-2 flex flex-col gap-2 justify-start items-center`}>
                        <UploadMultipleImage
                            sendData={(data) => {
                                if (data[0]?.type === 'image/png' || data[0]?.type === 'image/jpeg') {
                                    props.multipleMediaApiCall(data);
                                }
                            }}
                            disabled={multipleUploadVar && multipleUploadVar?.length >= 4 ? true : false}
                            text1="selectFile"
                            text2="dragAndDropItHereText"
                            text3={loading === true ? 'uploading' : "uploadImg"}
                            multiple={false}
                        />
                        <div className={`flex gap-4 pt-2 w-full relative`}>
                            {multipleUploadVar && multipleUploadVar?.length > 0 ?
                                multipleUploadVar?.map((multipleImg, idx) =>
                                    <div key={idx} className={`flex w-fit justify-between`}>
                                        <img
                                            crossOrigin="anonymous"
                                            src={multipleImg?.key && `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${multipleImg?.key}&height=60&width=80`}
                                            alt='thumbnail'
                                        />
                                        <div className={`cursor-pointer `}>
                                            <CloseIcon
                                                onClick={() => removeParticularThumbnail(idx, multipleImg?.id, multipleImg?.key, multipleImg?.mimetype)}
                                                sx={{ fontSize: "12px", position: 'absolute', top: '0px', border: '1px solid red', borderRadius: '9999px' }}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div className={`flex w-full justify-between`}>
                                        <img src={fileUploadIcon} alt='file upload icon' />
                                        <img src={fileUploadIcon} alt='file upload icon' />
                                        <img src={fileUploadIcon} alt='file upload icon' />
                                        <img src={fileUploadIcon} alt='file upload icon' />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </form>

        </div>
    )
}

export default EditBusinessInfo