import React, { useEffect } from 'react'
import CommonPrivacyAndPolicy from '../../Common/Layout/PrivacyPolicy/CommonPrivacyPolicy'
import { useSelector } from 'react-redux'
import { ForAdmin } from '../../Containers/Admin/Hooks'
import LandingHeader from '../../Common/Layout/LandingHeader/LandingHeader';
import Footer from '../../Common/Layout/Footer/Footer';

const PrivPol = (props) => {

    const { getFaqInAdminApiCall, getFaqDetailsApi, privacyDetails, loading } = ForAdmin()

    useEffect(() => {
        getFaqInAdminApiCall()
    }, [])
    
    const vendorPrivPolFaq = useSelector((state) => state.cmsPage.faqData)
    const vendorPrivPol = vendorPrivPolFaq?.filter((item) => item?.type === 'privacyPolicy')
    const eachFaqDetails = useSelector((state) => state.cmsPage.faqDetails)

    return (
        <div>
            <LandingHeader
                searchBar={true}
                goTomessage={true}
                goToNotification={true}
                border={true}
                profile={true}
                handleclickNotification={props.handleshow}
                showNotification={props.showNotification}
                detailsPage={props.detailsPage}
                allnotification={props.allnotification}
                readNotificationApi={props.readNotificationApi}
            />
            <CommonPrivacyAndPolicy
                privPolContentCls={`!h-[70vh]`}
                allPrivPol={vendorPrivPol}
                getFaqInAdminApiCall={getFaqInAdminApiCall}
                getFaqDetailsApi={getFaqDetailsApi}
                eachFaqDetails={eachFaqDetails}
                privacyDetails={privacyDetails}
                loading={loading}
            />
            <Footer/>
        </div>
    )
}

export default PrivPol