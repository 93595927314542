import React, { useEffect } from 'react'
import Home from '../../Components/LandingPage/Home'
import { ForSignup } from '../Authentication/Signup/Hook'
import { ForVendor } from '../VendorDetailPage/Hooks'
import { ForLanding } from './Hooks'
import { ForAdmin } from '../Admin/Hooks'
import { ForNotification } from '../Notification/Hooks'
import NotificationShow from '../../Components/Notification/NotificationShow'
import { useNavigate } from 'react-router-dom'
import { useJwt } from 'react-jwt'
const HomeIndex = () => {
    const { GetAllBusinessAddress } = ForSignup()
    const {
        getCreatedNewOffers,
        getCreatedServiceOffers
    } = ForVendor()
    const { handleClickForOfferSeeAll,
        handleClickForBusinessSeeAll,
        handleClickForProductSeeAll,
        onHandelOpeModal,
        handleClickForAllHyperlink,
        handleAllServiceOffer,
        backtoPage,
        handleSearchResult,
        openMap,
        lat,
        lng,
        handleSearchData,
        searchData,
        searchResult,
        scrollClickHandler,
        getVendorsUnderSubCategory,
        markers,
        handleSetMarkers,
        selected,
        handleSetSelected
    } = ForLanding()

    const { getCategory } = ForAdmin()

    const { handleshow, showNotification, allnotification, detailsPage, getNotification, readNotificationApi } = ForNotification()
    const { getAllHyperlinks } = ForAdmin();

    useEffect(() => {
        getCreatedNewOffers()
        GetAllBusinessAddress()
        getCategory()
        getNotification()
    }, [])

    useEffect(() => {
        getAllHyperlinks()
    }, []);
    useEffect(() => {
        getCreatedServiceOffers()
    }, [])

    //  useEffect(()=>{
    //     navigator.geolocation.getCurrentPosition((position) => {
    //         setCurrentLocation({
    //             text: 'This is your current location',
    //             lat: position.coords.latitude,
    //             lng: position.coords.longitude
    //         });
    //     })
    // }, [])

    const navigate = useNavigate()

    // For refresh token when actual token expires 
    let token = localStorage.getItem('token') ? localStorage.getItem('token') : "";
    const { isExpired } = useJwt(token);
    const checkTokenExpiration = () => {
        if (isExpired) {
            navigate('/login');
        }
    }
    return (
        <div>
            <Home
                detailsPage={detailsPage}
                allnotification={allnotification}
                showNotification={showNotification}
                handleshow={handleshow}
                handleClickForOfferSeeAll={handleClickForOfferSeeAll}
                handleClickForBusinessSeeAll={handleClickForBusinessSeeAll}
                handleClickForProductSeeAll={handleClickForProductSeeAll}
                onHandelOpeModal={onHandelOpeModal}
                handleClickForAllHyperlink={handleClickForAllHyperlink}
                readNotificationApi={readNotificationApi}
                handleAllServiceOffer={handleAllServiceOffer}
                backtoPage={backtoPage}
                handleSearchResult={handleSearchResult}
                openMap={openMap}
                lat={lat}
                lng={lng}
                handleSearchData={handleSearchData}
                searchData={searchData}
                searchResult={searchResult}
                scrollClickHandler={scrollClickHandler}
                getVendorsUnderSubCategory={getVendorsUnderSubCategory}
                markers={markers}
                handleSetMarkers={handleSetMarkers}
                selected={selected}
                handleSetSelected={handleSetSelected}
            />
        </div>
    )
}

export default HomeIndex