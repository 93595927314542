import React, { useEffect } from 'react'
import VendorComponent from '../../Components/Admin/TotalVendors'
import { ForSignup } from '../Authentication/Signup/Hook'
import { ForUploadMediaHooks } from '../UploadMedia/Hooks'
import { ForAdmin } from './Hooks'
import SnackBarComponent from '../../Common/Layout/Snackbar/SnackBar'
import { useNavigate } from 'react-router-dom'
import { useJwt } from 'react-jwt'

const TotalVendorIndex = () => {
    const {
        getTotalVendorsInAdmin,
        totalVendorsInAdminPage,
        showAddAddress,
        createVendorByAdmin,
        handleClickForOpenVendorTable,
        openVendorTable,
        editclick,
        deleteclick,
        handleeditclick,
        editVendorFormData,
        businessAddAddressByAdmin,
        editbusinessAddressDetails,
        downloadExcelReport,
        approveUserApi,
        userEnableDisableApi,
        getSingleVendorDetailsApi,
        giveBadgetoUser,
        getAllUsers,
        approveVendorService,
        setGovFieldData,
        checkEachVendorProductAdmin,
        searchString,
        handleSearchVendor,
        getServiceByBusinessInAdmin,
        value,
        setValue,
        totalVendorCreateMsg,
        adminSnackbar,
        messageClose,
        loading,
        buttonLoading,
        getSubscriptionPlansApiCall,
        createCorporateSubscription
    } = ForAdmin()



    const { doUploadedMedia, doUploadedLogo, multipleMediaApiCall } = ForUploadMediaHooks()
    const {
        gotoApproval,
        doneApproval,
        // getbusinessAddressDetails
    } = ForSignup()

    useEffect(() => {
        getTotalVendorsInAdmin()
    }, [searchString]
    )

    useEffect(() => {
        getSubscriptionPlansApiCall()
    }, []);

    const navigate = useNavigate()

    // For refresh token when actual token expires 
    let token = localStorage.getItem('token') ? localStorage.getItem('token') : "";
    const { isExpired } = useJwt(token);
    const checkTokenExpiration = () => {
        if (isExpired) {
            navigate('/login');
        }
    }
    return (
        <div onClick={() => checkTokenExpiration()}>
            <VendorComponent
                getTotalVendorsInAdmin={getTotalVendorsInAdmin}
                totalVendorsInAdminPage={totalVendorsInAdminPage}
                showAddAddress={showAddAddress}
                createVendorByAdmin={createVendorByAdmin}
                businessAddAddressByAdmin={businessAddAddressByAdmin}
                doUploadedMedia={doUploadedMedia}
                doUploadedLogo={doUploadedLogo}
                multipleMediaApiCall={multipleMediaApiCall}
                gotoApproval={gotoApproval}
                doneApproval={doneApproval}
                handleClickForOpenVendorTable={handleClickForOpenVendorTable}
                openVendorTable={openVendorTable}
                handleeditclick={handleeditclick}
                editVendorFormData={editVendorFormData}
                editclick={editclick}
                editbusinessAddressDetails={editbusinessAddressDetails}
                deleteclcik={deleteclick}
                downloadExcelReport={downloadExcelReport}
                approveUserApi={approveUserApi}
                userEnableDisableApi={userEnableDisableApi}
                getSingleVendorDetailsApi={getSingleVendorDetailsApi}
                giveBadgetoUser={giveBadgetoUser}
                approveVendorService={approveVendorService}
                setGovFieldData={setGovFieldData}
                checkEachVendorProductAdmin={checkEachVendorProductAdmin}
                handleSearchVendor={handleSearchVendor}
                getServiceByBusinessInAdmin={getServiceByBusinessInAdmin}
                value={value}
                setValue={setValue}
                totalVendorCreateMsg={totalVendorCreateMsg}
                loading={loading}
                buttonLoading={buttonLoading}
                createCorporateSubscription={createCorporateSubscription}
            />
            <SnackBarComponent
                messageOpen={adminSnackbar.open}
                messageClose={messageClose}
                notificationText={adminSnackbar.message}
                subText={adminSnackbar.subText}
                alertType={adminSnackbar.alertType}
                borderClass={adminSnackbar.borderClass}
            />

        </div>
    )
}

export default TotalVendorIndex