import React from "react";
import Footer from "../../Common/Layout/Footer/Footer";
import MainHeader from "../../Common/Layout/MainHheader/MainHeader";
import LandingHeader from "../../Common/Layout/LandingHeader/LandingHeader";
import Table from "./Table";
import { useSelector } from "react-redux";

const PayHistory = (props) => {
  const paymentHistoryList = useSelector(
    (store) => store.paymentHistory?.paymentHistory
  );

  return (
    <div>
      <div className="sticky top-0 z-50">
        <MainHeader />
        <LandingHeader
          searchBar={true}
          goTomessage={true}
          goToNotification={true}
          handleclickNotification={props.handleshow}
          border={true}
          profile={true}
          showNotification={props.showNotification}
          allnotification={props.allnotification}
          detailsPage={props.detailsPage}
          readNotificationApi={props.readNotificationApi}
        />
      </div>
      <Table paymentHistory={paymentHistoryList} />
      <Footer />
    </div>
  );
};

export default PayHistory;
