import React from 'react'
import RatingField from '../../../Common/UiComponents/Rating'
import defaultprofile from '../../../Images/vendors/defaultprofile.svg'
import star from '../../../Images/CommonImages/star.svg'


/**
 * @component CustomerReview
 * @description single user review details in vendor section
 * @param {*} props 
*/

const CustomerReview = (props) => {
    const ratingProgress = [
        { title: '5 star', per: '65%' },
        { title: '4 star', per: '21%' },
        { title: '3 star', per: '5%' },
        { title: '2 star', per: '1%' },
        { title: '1 star', per: '7%' }
    ]

    const ratingBreakdown = [
        { title: 'Vendor rating', star: '4.4' },
        { title: 'User Rating', star: '4.0' },
    ]

    return (
        <div className={`customerReviewMain`}>
            {/*header section */}
            <div className={`flex items-center gap-4 border-b-[1px] border-b-[#EBEBEB] p-4`}>
                <div className={`w-16 h-16`}>
                    {/* <img src={defaultprofile} alt="img" /> */}
                    <img
                        crossOrigin='anonymous'
                        src={props.BusinessDetails?.logo?.key !== null ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${props.BusinessDetails?.logo?.key}&height=60&width=60` : defaultprofile}
                        alt="defaultprofile"
                        className={` w-16 h-16 ${props.dataObject?.logo?.key !== null && 'rounded-full border'}`}
                    />
                </div>
                <div className={`flex flex-col items-start`}>
                    <p className={`text-[#434343] font-[Roboto-Medium] text-xl`}>{props.BusinessDetails?.user?.name}</p>
                    <p className={`text-[#43434399] text-base font-[Roboto-Light]`}>{props.BusinessDetails?.user?.email}</p>
                    {/* <RatingField
                        size="small"
                        highlightSelectedOnly={true}
                        readOnly={true}
                    /> */}
                </div>
            </div>

            {/*rating details section for admin*/}
            {/* <div className={`w-full mt-3 flex flex-col gap-3`}>
                {ratingProgress && ratingProgress?.map((item) => {
                    return (<div className={`w-full flex justify-between items-center gap-2`}>
                        <p className={`text-[#186AB2] text-sm font-[Roboto-Light]`}>{item?.title}</p>
                        <p className={`bg-[#F0F2F2] w-3/4 h-2 rounded-sm`}><p style={{ width: `${item?.per}` }} className={`bg-[#FFC315] z-10 h-2 rounded-sm`}></p></p>
                        <p className={`text-[#186AB2] text-sm font-[Roboto-Light]`}>{item?.per}</p>
                    </div>)
                })
                }
            </div> */}
        </div>
    )
}

export default CustomerReview