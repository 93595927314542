import React from 'react'
import BidCard from '../../../Common/Layout/Card/BidCard'
import DefaultProfileicon from '../../../Images/Vendor/DefaultProfileicon.svg'
import moment from 'moment'
import { CircularProgress } from '@mui/material'
import NoContentPage from '../../../Common/Layout/NoContentPage/NoContentPage'

const DeclinedBid = (props) => {

    return (
        <div className='overflow-y-scroll h-[78vh]'>
            {props.loading === true ?
                <div className='flex h-full justify-center items-center'>
                    <CircularProgress size={20} color='success' />
                    <p className='pl-3'>Loading...</p>
                </div>
                :
                <div>
                    {props.deniedBids && props.deniedBids?.length > 0 ?
                        <>
                            {props.deniedBids?.map((denyBids, idx) =>
                                <div key={idx}>
                                    <BidCard
                                        DefaultProfileicon={DefaultProfileicon}
                                        name={denyBids?.requirements.user?.name}
                                        email={denyBids?.requirements.user?.email}
                                        question={denyBids?.requirements.title}
                                        content={denyBids?.requirements.description}
                                        flex={true}
                                        showdate={true}
                                        date={moment(denyBids?.requirements.startDate).format('LL')}
                                        // peoplereplied={true}
                                        // numberofpeoplereplied={denyBids?.requirements.numberofpeoplereplied}
                                        uploadDocument={false}
                                        expiredtime={false}
                                        time={moment(denyBids?.requirements.endDate).format('LL')}
                                        item={denyBids}
                                    />
                                </div>
                            )}
                        </>
                        :
                        <NoContentPage
                            NoContentMainDiv={`h-[78vh]`}
                            text2={`There is no data`}
                        />
                    }
                </div>}
        </div>
    )
}

export default DeclinedBid