import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import ProfileUserDescriptionCard from '../../../Common/Layout/ProfileCard/ProfileUserDescriptionCard'
import ProfileUserImagesCard from '../../../Common/Layout/ProfileCard/ProfileUserImagesCard'
import ProfileUserInfoCard from '../../../Common/Layout/ProfileCard/ProfileUserInfoCard'
import TabComponent from '../../../Common/UiComponents/TabComponent'
import TotalUsersHeader from '../TotalUsers/TotalUsersHeader'
// import AllReviews from './AllReviews'
// import CustomerReview from './CustomerReview'
import text from '../../../Common/Languages/en_US.json'
import { useTranslation } from 'react-i18next'
import { ForAdmin } from '../../../Containers/Admin/Hooks'
// import SingleVendorServiceOfferedTab from './SingleVendorServiceOfferedTab'
import SingleUserRequirementTab from './SingleUserRequirementTab'
import { useParams } from 'react-router-dom'
import { ForLanding } from '../../../Containers/LandingPage/Hooks'

const UserDetailsPageAdmin = () => {
    const [value, setValue] = React.useState("0");
    const singleUserDataAdmin = useSelector((state) => state.totalUsers.selectedUserDetails)


    const { 
        getTotalUserDetailsApi,
        getVendorReqByVendorIdAdmin
    } = ForAdmin()

    // const { getPostedReviews } = ForLanding()
    const { t } = useTranslation()
    const userId = useParams()

    useEffect(() => {
        getTotalUserDetailsApi(userId?.id)
    }, [userId?.id])

    const reviewData = useSelector((state) => state.landingData.reviews)

    const categories = [
        {
            label: "Requirements",
            value: "0",
            backgroundcolor: "bg-white",
            content: <SingleUserRequirementTab getVendorReqByVendorIdAdmin={getVendorReqByVendorIdAdmin} userId={userId}/>
        }
    ]

    return (
        <div className={`h-full overflow-y-scroll`}>
            <TotalUsersHeader userName={`Profile`} />
            <Grid container spacing={2} className={`p-4`}>
                <Grid item xs={12} md={3}>
                    <div className={`w-full flex flex-col justify-start gap-4 `}>
                        <ProfileUserInfoCard
                            // dataObject={singleVendorsInAdminPage}
                            dataObject={singleUserDataAdmin}
                            forUser = 'forUser'
                        />
                        {/* <div className={`flex justify-between flex-col gap-4`}>
                            <div className={`w-full`}>
                                <ProfileUserImagesCard
                                    // imgArr={singleVendorsInAdminPage?.thumbnail}
                                    imgArr={singleUserDataAdmin?.thumbnail}
                                    // dataObject={singleVendorsInAdminPage}
                                    dataObject={singleUserDataAdmin}
                                />
                            </div>
                            <div className={`w-full`}>
                                <ProfileUserDescriptionCard
                                    description={text.description}
                                    profileUserCardDescText={text.profileUserCardDescText}
                                    // dataObject={singleVendorsInAdminPage}
                                    dataObject={singleUserDataAdmin}
                                />
                            </div>
                        </div> */}
                    </div>
                </Grid>
                <Grid item xs={12} md={9}>
                    <TabComponent
                        mappingname={categories}
                        button={false}
                        value={value}
                        setValue={setValue}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default UserDetailsPageAdmin