import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Chart, Line } from 'react-chartjs-2';
import text from "../../../Common/Languages/en_US.json"
import DropDownField from '../../../Common/FormFields/DropDownField';
import PropTypes from 'prop-types';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Design',
      data: labels.map(() => (Math.floor(Math.random() * (1000 - 0 + 1)) + 0)),
      borderColor: '#00BA34',
      backgroundColor: '#00BA34',
    },
    {
      label: 'Marketing',
      data: labels.map(() => Math.floor(Math.random() * (1000 - 0 + 1)) + 0),
      borderColor: '#0085FF',
      backgroundColor: '#0085FF',
    },
    {
      label: 'Uncategorized',
      data: labels.map(() => Math.floor(Math.random() * (1000 - 0 + 1)) + 0),
      borderColor: '#F98600',
      backgroundColor: '#F98600',
    },
  ],
};
const footer = (tooltipItems) => {
  // console.log(tooltipItems, "tooltip #")
  let a = tooltipItems.forEach(function (tooltipItem) {
    return tooltipItem.datasets.label = tooltipItem.parsed.y;
  });
  return a
};

const config = {
  type: 'line',
  data: data,
  options: {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: false,
        text: 'Chart'
      },
      // tooltip: {
      //     callbacks: {
      //       footer: footer,
      //     }
      // }
    },
    scales: {
      y: {
        ticks: {
          // For a category axis, the val is the index so the lookup via getLabelForValue is needed
          callback: function (value, index, ticks) {
            return '$' + value;
          },
          color: '#969696',
        }
      },
      x: {
        color: '#969696',
      }
    }
  },
};

const AdminLineChart = (props) => {
  const selectArr = [
    { name: "Months", value: "months" },
    { name: "Earning", value: "earning" },
  ]
  return (
    <div className={`w-full py-6 `}>
      <div style={{ boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 1px rgba(0, 0, 0, 0.08)' }}
        className={`flex flex-col gap-4 px-10 py-6 rounded-lg bg-white`}>
        <div className={`flex justify-between items-center w-full`}>
          <p className={`text-xl text-[#1C1C1C] font-medium`}>{text.totalearning}</p>
          <DropDownField
            selectOption={selectArr}
            dropDownRootCls={`h-10 pl-[25px] text-sm font-[Roboto-Medium]`}
            filterImg={true}
            // handleChange={}
            placeholder="Filter"
          />
        </div>
        <Line
          options={config.options}
          data={data}
          updateMode="resize"
        />
      </div>
    </div>
  )
}
AdminLineChart.propTypes = {
  data: PropTypes.array,
  options: PropTypes.object,
  selectOption: PropTypes.array,
  dropDownRootCls: PropTypes.string,
  filterImg: PropTypes.bool,
  placeholder: PropTypes.string,
}

export default AdminLineChart