import React from 'react'
import VendorSettings from '../../../Components/Authentication/Settings/VendorSettings'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'



const VendorSettingsMain = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/vendorsettings/changepassword')
  }, [])
  return (
    <div><VendorSettings /></div>
  )
}

export default VendorSettingsMain