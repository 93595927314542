import React from 'react'
import CategoryPreview from './categoryPreview'

const Category = (props) => {

    return (
        <div className='h-[85vh] overflow-y-scroll'>
            <CategoryPreview
                OpenModal={props.OpenModal}
                openModal={props.openModal}
                createCategory={props.createCategory}
                CategoryName={props.CategoryName}
                CategoryDescription={props.CategoryDescription}
                cancleModal={props.cancleModal}
                categoryData={props.categoryData}
                deleteCategory={props.deleteCategory}
                editCategory={props.editCategory}
                newCategoryName={props.newCategoryName}
                addNewCategoryName={props.addNewCategoryName}
                openEditName={props.openEditName}
                categoryId={props.categoryId}
                addSubCategory={props.addSubCategory}
                subCategoryModal={props.subCategoryModal}
                openSubCategoryModal={props.openSubCategoryModal}
                subCategoryModalClose={props.subCategoryModalClose}
                subCategoryId={props.subCategoryId}
                addNewSubCategoryName={props.addNewSubCategoryName}
                openEditSubCategoryName={props.openEditSubCategoryName}
                editSubCategory={props.editSubCategory}
                newSubCategoryName={props.newSubCategoryName}
                addCategoryName={props.addCategoryName}
                addCategoryDescription={props.addCategoryDescription}
                doUploadedLogo={props.doUploadedLogo}
                handelChange={props.handelChange}
                uploadLogoProfile={props.uploadLogoProfile}
                setUploadLogoProfile={props.setUploadLogoProfile}
                treeUploadLogoProfile={props.treeUploadLogoProfile}
                setTreeUploadLogoProfile={props.setTreeUploadLogoProfile}
                saveId={props.saveId}
                dataValue={props.dataValue}
                loading={props.loading}
                CategoryForm={props.CategoryForm}
                subCategoryForm={props.subCategoryForm}
                editSubCategoryData={props.editSubCategoryData}
                deleteSubCategoryApiCall={props.deleteSubCategoryApiCall}

            />
        </div>
    )
}

export default Category