import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    dashboardData: [],
    dashboardDataVendor: [],
    mostViews: [],
    loading: false,
    error: false,
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        getDashBoardData: (state, action) => {
            state.dashboardData = action.payload;
            state.loading = false;
            state.error = false;
        },
        getDashBoardDataVendor: (state, action) => {
            state.dashboardDataVendor = action.payload;
            state.loading = false;
            state.error = false;
        },
        mostViewedReducer: (state, action) => {
            state.mostViews = action.payload;
            state.loading = false;
            state.error = false;
        }
    }
})

export const { getDashBoardData, getDashBoardDataVendor, mostViewedReducer } = dashboardSlice.actions;
export default dashboardSlice.reducer;