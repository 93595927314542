import React, { useEffect, useState } from 'react'
import cross from '../../../Images/CommonImages/cross.svg'
import DigidialsLandingLogo from '../../../Images/CommonImages/exploreFooterLogo.svg'
import SandWatch from '../../../Images/CommonImages/SandWatch.png'
import { useTranslation } from 'react-i18next';

const ExpirationModal = (props) => {
    const { t, i18n } = useTranslation()

  return (
    <div className={`fixed md:w-[45rem] h-[24rem] w-[38rem] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-indigo-900 z-10 custom-shadow text-white`}>
        <div className={`flex justify-end p-3`}>
            <img src={cross} alt="cross" className={`h-4 w-4 cursor-pointer`} style={{filter: 'brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(2%) hue-rotate(208deg) brightness(114%) contrast(101%)'}} onClick={props.closeModal}/>
        </div>
        <div className={`h-[63%] flex gap-[22px]`}>
            <div className={`flex items-center justify-center mb-[20px] pl-4`}>
                <img src={SandWatch} alt={"time watch"} className={`w-[13em] h-[13em]`} />
            </div>
            <div className={`w-[64%] flex flex-col gap-8`}>
                <div className={`text-5xl font-medium`}> 5 {t("days")} </div>
                <div className={`text-2xl font-medium`}>{t("untilExpiration")}</div>
                <div className={`text-lg`}> {t("yourSubscriptionPlan")} </div>
            </div>
        </div>
        <div className={`h-[22%] border-t border-white mt-2 flex`}>
            <div className={`flex w-1/3 justify-center items-center`}>
                <img src={DigidialsLandingLogo} alt={"explore"} className={`w-[9em]`} />
            </div>
            <div className={`w-2/3 flex gap-4 items-center text-base justify-center font-medium`}>
                <div className={`px-12 py-2 rounded-md border border-white cursor-pointer`} onClick={props.closeModal} >{t("close")}</div>
                <div className={`px-12 py-2 rounded-md border border-white text-indigo-900 bg-white cursor-pointer`}>{t('renewNow')}</div>
            </div>
        </div>
    </div>
  )
}

export default ExpirationModal