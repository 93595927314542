import React, { useState, useEffect } from 'react'
import './index.css'
import searchicon from '../../Images/profileDashboardIcons/searchicon.svg'
import TextFieldInput from './TextFieldInput';
import searchrelatedicon from '../../Images/profileDashboardIcons/searchrelatedicon.svg'
import { Autocomplete, ClickAwayListener, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CustomDropDown from './CustomDropDown';
import { useTranslation } from 'react-i18next';
import dropArrowGray from '../../Images/CommonImages/dropArrowGray.svg'
import targetIcon from '../../Images/CommonImages/targetIcon.svg'
import { ForLanding } from '../../Containers/LandingPage/Hooks';
import { useNavigate } from 'react-router-dom';
import { getSearchDetails } from '../../Containers/LandingPage/LandingReducer';

const SearchField = (props) => {

    const { getTheSearchedQuery } = ForLanding()
    const dispatch = useDispatch()

    const { t } = useTranslation()
    const navigate = useNavigate()

    const [searchInput, setSearchInput] = useState("");

    const [select, setSelect] = useState('')
    const [selectGov, setSelectGov] = useState('')
    const [selectPlaces, setSelectPlaces] = useState('')

    const [open, setOpen] = useState(false)
    const handleSearchResult = () => {
        setOpen(true)
    }

    const dropdownArray = [
        { name: 'Business', value: 'business' },
        { name: 'service', value: 'service' },
        { name: 'category', value: 'category' },
        { name: 'Location', value: 'location' }
    ]

    const governorateArray = [
        {
            name: 'Farwaniya',
            value: 'Farwaniya',
            FarwaniyaLocations: [
                { name: 'Jaleeb Al Shuwaikh', value: 'Jaleeb Al Shuwaikh' },
                { name: 'Dajeej', value: 'Dajeej' },
                { name: 'Ardiya', value: 'Ardiya' },
                { name: 'Riggae', value: 'Riggae' },
                { name: 'Farwaniya', value: 'Farwaniya' },
                { name: 'Kaithan', value: 'Kaithan' },
                { name: 'Al Rai', value: 'Al Rai' }
            ]
        },
        {
            name: 'Al Asimah',
            value: 'Al Asimah',
            AlAsimahLocations: [
                { name: 'Shuwaikh', value: 'Shuwaikh' }
            ]
        },
        {
            name: 'Hawally',
            value: 'Hawally',
            HawallyLocations: [
                { name: 'Hawally', value: 'Hawally' },
                { name: 'Jabriya', value: 'Jabriya' },
                { name: 'Salmiya', value: 'Salmiya' },
                { name: 'Shaab', value: 'Shaab' }
            ]
        },
        {
            name: 'Mubarak Al Kabeer',
            value: 'Mubarak Al Kabeer',
            MubarakAlKabeerLocations: [
                { name: 'Sabah Al Salem', value: 'Sabah Al Salem' },
                { name: 'Kuwait City', value: 'Kuwait City' },
                { name: 'Qurain', value: 'Qurain' }
            ]
        },
        {
            name: 'Ahmadi',
            value: 'Ahmadi',
            AhmadiLocations: [
                { name: 'Mahboula', value: 'Mahboula' },
                { name: 'Mangaf', value: 'Mangaf' },
                { name: 'Fahaheel', value: 'Fahaheel' },
                { name: 'Ahmadi', value: 'Ahmadi' }
            ]
        },
        {
            name: 'Jahra', value: 'Jahra',
            JahraLocations: [
                { name: 'Jahra', value: 'Jahra' },
            ]
        },
    ]

    const showVendorDetails = (userId) => {
        navigate(`/searchresult/${userId}`)
    }

    const results = useSelector((state) => state.landingData.searchQuery)
    const resultsWithoutNull = results?.filter((item) => item?.hasOwnProperty('id'))

    const onChange = (e) => {
        e.preventDefault();
        if (e.target.value.trim().length > 0) {
            setSearchInput(e.target.value);
        } else {
            setSearchInput('');
        }
    };

    // const filteredData = results?.filter((el) => {
    //     if (searchInput === '') {
    //         return el;
    //     }
    //     else {
    //         return el?.name?.toLowerCase().includes(searchInput)
    //     }
    // })

    useEffect(() => {
        if (searchInput === '') {
            dispatch(getSearchDetails([]))
        }
    }, [searchInput])

    useEffect(() => {
        if (select?.value !== 'location') {
            setSelectGov(null)
            setSelectPlaces(null)
        }
    }, [select])

    return (
        <div className={`flex relative`}>
            {props.searchIcon &&
                <img src={searchicon} alt='searchicon' onClick={props.onclick} className={`cursor-pointer`} />
            }
            <div className={`bg-white h-10 rounded w-full flex items-center justify-between`}>
                {/* For Search Vendors and services  */}
                <div className={`searchbar`}>
                    <TextFieldInput
                        onlyValue
                        placeholder={"searchServiceAndVendors"}
                        // id="outlined-basic"
                        variant="outlined"
                        onChange={onChange}
                        value={searchInput}
                        autoComplete='off'
                        onKeyPress={() => {
                            if (searchInput !== '') {
                                getTheSearchedQuery(select?.value, searchInput)
                            }
                            handleSearchResult()
                        }}
                        srchCls={`searchBarfix`}
                    />
                </div>

                {props.dropDown && <div className={`border-l mx-2 border-solid border-gray-1 h-6`}></div>}

                {/* Custom dropdown  */}
                {props.dropDown && <div className={``}>
                    <CustomDropDown
                        dropdownBoxExtraCls={`top-8`}
                        dropdownNameExtraCls={`text-gray-60 text-base font-normal font-[Roboto-Regular] my-4`}
                        nameExtraCls={`text-gray-60 text-base font-normal font-[Roboto-Regular]`}
                        dropArrowSrc={dropArrowGray}
                        dropDownValue={select}
                        dropDownName={select}
                        dropDownArray={dropdownArray}
                        setValue={setSelect}
                        imgCls={`pt-[0.2em]`}
                        placeholder={`select`}
                    />
                </div>}

                {select?.value === 'location' && <div className={`border-l mx-2 border-solid border-gray-1 h-6`}></div>}

                {/* Custom dropdown for Governorate */}
                {select?.value === 'location' && <div className={``}>
                    <CustomDropDown
                        dropdownBoxExtraCls={`top-8`}
                        dropdownNameExtraCls={`text-gray-60 text-base font-normal font-[Roboto-Regular] my-4`}
                        nameExtraCls={`text-gray-60 text-base font-normal font-[Roboto-Regular]`}
                        dropArrowSrc={dropArrowGray}
                        dropDownValue={selectGov}
                        dropDownName={selectGov}
                        dropDownArray={governorateArray}
                        setValue={setSelectGov}
                        imgCls={`pt-[0.2em]`}
                        placeholder={`Governorate`}
                    />
                    </div>
                }

                {selectGov && selectGov?.value?.length > 0 && <div className={`border-l mx-2 border-solid border-gray-1 h-6`}></div>}

                {/* Custom dropdown for Places under Governorate */}
                {selectGov && selectGov?.value?.length > 0 && <div className={``}>
                    <CustomDropDown
                        dropdownBoxExtraCls={`top-8`}
                        dropdownNameExtraCls={`text-gray-60 text-base font-normal font-[Roboto-Regular] my-4`}
                        nameExtraCls={`text-gray-60 text-base font-normal font-[Roboto-Regular]`}
                        dropArrowSrc={dropArrowGray}
                        dropDownValue={selectPlaces}
                        dropDownName={selectPlaces}
                        dropDownArray={
                            selectGov?.value === 'Farwaniya' ? governorateArray[0].FarwaniyaLocations
                                :
                                selectGov?.value === 'Al Asimah' ? governorateArray[1].AlAsimahLocations
                                    :
                                    selectGov?.value === 'Hawally' ? governorateArray[2].HawallyLocations
                                        :
                                        selectGov?.value === 'Mubarak Al Kabeer' ? governorateArray[3].MubarakAlKabeerLocations
                                            :
                                            selectGov?.value === 'Ahmadi' ? governorateArray[4].AhmadiLocations
                                                :
                                                selectGov?.value === 'Jahra' ? governorateArray[5].JahraLocations : []
                        }
                        setValue={setSelectPlaces}
                        imgCls={`pt-[0.2em]`}
                        placeholder={`place`}
                    />
                    </div>
                }

                <div
                    onClick={() => {
                        if (searchInput !== '') {
                            getTheSearchedQuery(select?.value, searchInput)
                        } else if (select?.value) {
                            getTheSearchedQuery(select?.value, selectPlaces?.value)
                        }
                        handleSearchResult()
                    }}
                    className={`bg-seconderyColour-5 text-base text-white font-normal font-[Roboto-Regular] w-[15%] h-full flex items-center justify-center rounded-r cursor-pointer ml-4`}
                >
                    {t("Search")}
                </div>
            </div>

            {/* Filtered data is mapped  */}
            {open &&
                <ClickAwayListener
                    onClickAway={() => setOpen(false)}
                >
                    <div className={`flex flex-col absolute top-[42px] bg-white w-full cursor-pointer max-h-[30vh] overflow-y-scroll z-50 shadow-2xl`}>
                        {(searchInput?.length > 1 || selectPlaces?.value?.length > 1) && resultsWithoutNull.length > 0 ?
                            resultsWithoutNull?.map((result) => (
                                <div
                                    key={result?.id}
                                    onClick={() => {
                                        setSearchInput(result?.name);
                                        showVendorDetails(result?.user?.id)
                                    }}
                                    className={`p-2 border-b border-[#949494]`}
                                >
                                    <p className={`font-[Roboto-Regular] text-sm `}>{result?.name}</p>
                                </div>
                            ))
                            :
                            <div className='flex justify-center items-center'>No result found</div>
                        }
                    </div>
                </ClickAwayListener>
            }

            {props.searchRecent &&
                <img src={searchrelatedicon} alt='searchicon' onClick={props.onclick} className={`cursor-pointer`} />
            }
        </div>
    )
}

export default SearchField