import React, { useEffect, useState } from 'react'
import { ExpandMore } from '@mui/icons-material'
import PropTypes from 'prop-types'
import CheckBox from '../FormFields/CheckBox';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

const CollapsibleUi = (props) => {
  const [showChildren, setShowChildren] = useState(false);

  const handleToggleShowChildren = () => {
    setShowChildren(prev => !prev)
  }

  useEffect(() => {
    props.isDisabled && handleToggleShowChildren()
  }, [props.isDisabled])

  return (
    <div className={`${props.CollapsibleMainCls ? props.CollapsibleMainCls : "bg-[#F2F2F2] rounded-md h-full"} ${showChildren && `pb-5`} ${props.containerCls}`}>
      <div className={`flex items-center h-[4rem] ${(props.arrowEnd || props.arrow) && `w-full justify-between`} ${showChildren && props.conditionalheaderCls} ${props.headerCls}`} gap={2}>
        {!props.arrowEnd && !props.arrow && <ExpandMore className={`text-[#032974] ${props.iconCls} ${showChildren ? `rotate-180` : ''}`} />}
        <div className={`gap-2 flex items-center`}>
          {props.showCheckBox && <CheckBox sx={{ padding: '0px' }} checked={props.checked} handleBoxChange={(e) => props.handleCheckbox(e)} />}
          <p className={`${props.titleCls ? props.titleCls : `text-[#032974] font-semibold `}`}>{props.title}</p>          
        </div>
        <div className={`flex gap-2`}>
          {props?.isEndTitle && <p className={`${props?.endTitleCls} ${props.isDisabled && 'pointer-events-none'}`} onClick={(e) => { props?.endTitleClick(e) }}>{props?.endTitle}</p>}
          {props.arrowEnd && (
            <ArrowDropDownCircleIcon className={`text-[#032974] cursor-pointer ${props.iconCls} ${showChildren ? `rotate-180` : ''} ${props.isDisabled && 'pointer-events-none'}`} onClick={handleToggleShowChildren} />
          )}
        </div>
      </div>

      <div className={props.contentCls}>
        {showChildren && props.children}
      </div>
    </div>
  )
}

export default CollapsibleUi

CollapsibleUi.propTypes = {
  title: PropTypes.string,
  containerCls: PropTypes.string,
  iconCls: PropTypes.string,
  titleCls: PropTypes.string,
  children: PropTypes.node,
  arrowEnd: PropTypes.bool,
  arrow: PropTypes.bool,
  contentCls: PropTypes.string,
  headerCls: PropTypes.string,
  conditionalheaderCls: PropTypes.string,
  showCheckBox: PropTypes.bool,
  isEndTitle: PropTypes.bool,
  checked: PropTypes.bool,
  handleCheckbox: PropTypes.func,
  endTitleClick: PropTypes.func,
  showRefreshIcon: PropTypes.bool,
  CollapsibleMainCls: PropTypes.string,
  endTitleCls: PropTypes.string,
  endTitle: PropTypes.string,
  showDropdown: PropTypes.bool,
  isDisabled: PropTypes.bool
}