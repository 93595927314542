import { getPaymentHistory } from "./PaymentHistoryReducer";
import { useDispatch } from "react-redux";
import { doGetApiCall } from "../../Utils/ApiConfig";

export const ForPaymentHistory = () => {
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");

  const getPaymentHistoryDetails = async (userId) => {
    if (userId) {
      let data;
      if (token) {
        data = {
          url: `${process.env.REACT_APP_DG_BASE_URL}/payment/users/${userId}`,
        };
      }
      doGetApiCall(data)
        .then((res) => {
          if (!res.error) {
            dispatch(getPaymentHistory(res.result));
          } else {
            dispatch(getPaymentHistory([]));
          }
        })
        .catch((err) => {
          console.log(err, "error");
        });
    }
  };

  return {
    getPaymentHistoryDetails,
  };
};
