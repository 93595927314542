import React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import FavouriteVendor from '../../Common/Layout/FavouriteVendor/FavouriteVendor'
import { ForLanding } from './Hooks'
import SnackBarComponent from '../../Common/Layout/Snackbar/SnackBar'

const FavouriteVendorMain = () => {
  const { getFavVendorApiCall, handleFavouriteVendor, deleteFavVendorApiCall, notification, messageClose, loading } = ForLanding()
  const userId = localStorage.getItem('userId')

  const favVendorData = useSelector((state) => state.landingData.favVendors)
  
  useEffect(() => {
    if(localStorage.getItem('token') && userId) {
      getFavVendorApiCall(userId)
    }
  }, [userId])

  return (
    <div>
      <FavouriteVendor
        favVendorDetails={favVendorData}
        handleFavouriteVendor={handleFavouriteVendor}
        deleteFavVendorApiCall={deleteFavVendorApiCall}
        loading={loading}
      />
      <SnackBarComponent
        messageOpen={notification.open}
        messageClose={messageClose}
        notificationText={notification.message}
        subText={notification.subText}
        alertType={notification.alertType}
        borderClass={notification.borderClass}
      />
    </div>
  )
}

export default FavouriteVendorMain