import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    allNotification: [],
    loading: false,
    error: false,
};

export const notificationSlice = createSlice({
    name: 'notificationData',
    initialState,
    reducers: {
        getAllNotification: (state, action) => {
            state.allNotification = action.payload;
            state.loading = false;
            state.error = false;
        },
       
    }
})

export const { getAllNotification,} = notificationSlice.actions;
export default notificationSlice.reducer;