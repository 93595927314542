import React, { useState } from 'react'
import { useForm, Controller } from "react-hook-form"
import RatingField from '../../UiComponents/Rating';
import TextFieldInput from '../../FormFields/TextFieldInput';
import ButtonField from '../../FormFields/ButtonField';

const OverallRatingReview = (props) => {

    const {
        handleSubmit,
        control,
        reset
    } = useForm({
        defaultValues: {
            rating: 0,
            writereview: ''
        }
    });

    const onSubmit = (data) => {
        props.postReviewsOfVendors(data, props.BusinessDetails?.id)
        props.setWriteReview(false)
        reset({
            rating: 0,
            writereview: ''
        })
    };
    
    return (
        <div>
            <div className={` pt-[4%] h-screen`}>
                <form onSubmit={handleSubmit(onSubmit, reset)}>
                    <div className={`w-full`}>
                        <div className={`mb-[4%]`}>
                            <p className={`text-deepgray-4 text-base font-[Roboto-Medium]`}>
                                Overall rating
                            </p>
                            <div className={`border w-full p-5`}>
                                <Controller name={"rating"}
                                    control={control}
                                    render={({ field: { onChange, value }, formState: { error } }) => {
                                        return (
                                            <RatingField
                                                name="rating"
                                                size='large'
                                                SendRating={true}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )
                                    }}
                                    rules={{
                                        required: true
                                    }}
                                />
                            </div>
                        </div>
                        <div className={`w-full`}>
                            <p className={`text-deepgray-4 text-base font-[Roboto-Medium]`}>Write Review</p>
                            <Controller name={"writereview"}
                                control={control}
                                render={({ field: { onChange, value }, formState: { error } }) => {
                                    return (
                                        <TextFieldInput
                                            textinputname="writereview"
                                            multiline={true}
                                            minRows={8}
                                            onlyValue
                                            textnewclass={`w-full text-sm`}
                                            placeholder='Write something'
                                            value={(value)}
                                            onChange={onChange}
                                        />
                                    )
                                }}
                                rules={{
                                    required: true, minLength: 3
                                }}
                            />
                        </div>
                        <div className={`flex justify-end mt-5`}>
                            <ButtonField
                                buttonName={"cancel"}
                                buttonInsidecls={`gap-2`}
                                alt='backArrow'
                                type='reset'
                                buttonextracls={` !px-6 !py-2 !text-deepgrey-3 bg-Gray-5 mr-5 font-[Roboto-Medium]`}
                                onClick={() => props.setWriteReview(false)}
                            />
                            <ButtonField
                                buttonName={`Post Review`}
                                alt='rightArrow'
                                type='submit'
                                buttonInsidecls={`!flex-row-reverse gap-2 `}
                                buttonextracls={` !px-6 !py-2 !text-white bg-skyblue-6`}
                                onClick={handleSubmit(onSubmit)}
                            />
                        </div>
                    </div>
                </form>
            </div>
            {/* <SnackBarComponent
                messageOpen={notification.open}
                messageClose={messageClose}
                notificationText={notification.message}
                subText={notification.subText}
                alertType={notification.alertType}
                borderClass={notification.borderClass}
            /> */}
        </div>

    )
}

export default OverallRatingReview