import React from 'react'
import TotalUsersHeader from '../TotalUsers/TotalUsersHeader'
import text from '../../../Common/Languages/en_US.json'
import AddSubAdmin from './AddSubAdmin'
import HyperlinkList from './HyperlinkList'
import HyperlinkMain from './HyperlinkMain'
import PropTypes from 'prop-types';
import SettingSidebar from './SettingSidebar'
import { Outlet } from 'react-router-dom'



const SettingComponent = (props) => {
    return (
        <div>
            <TotalUsersHeader userName={text.settings} dropDown={false} addBtn={false} csvBtn={false} />
            <div className='flex w-full'>
                <div className='w-[16%]'>
                    <SettingSidebar
                        subAdminCreate={props.subAdminCreate}
                        showSubAdminForm={props.showSubAdminForm}
                        setShowSubAdminForm={props.setShowSubAdminForm}
                        toggleSubAdmin={props.toggleSubAdmin}
                        subAdminUser={props.subAdminUser}
                        goBackToTable={props.goBackToTable}
                        hyperlinkForm={props.hyperlinkForm}
                        setHyperlinkForm={props.setHyperlinkForm}
                        showHyperlinkForm={props.showHyperlinkForm}
                        createHyperlink={props.createHyperlink}
                        allLinks={props.allLinks}
                        goToLinkTable={props.goToLinkTable}
                        uploadData={props.uploadData}
                        sendData={props.sendData}
                    />
                </div>
                {/* <AddSubAdmin
                    subAdminCreate={props.subAdminCreate}
                    showSubAdminForm={props.showSubAdminForm}
                    setShowSubAdminForm={props.setShowSubAdminForm}
                    getAllUsers={props.getAllUsers}
                    toggleSubAdmin={props.toggleSubAdmin}
                    subAdminUser={props.subAdminUser}
                    goBackToTable={props.goBackToTable}
                />
                <HyperlinkMain
                    subAdminCreate={props.subAdminCreate}
                    showSubAdminForm={props.showSubAdminForm}
                    setShowSubAdminForm={props.setShowSubAdminForm}
                    getAllUsers={props.getAllUsers}
                    toggleSubAdmin={props.toggleSubAdmin}
                    subAdminUser={props.subAdminUser}
                    goBackToTable={props.goBackToTable}
                    hyperlinkForm={props.hyperlinkForm}
                    setHyperlinkForm={props.setHyperlinkForm}
                    showHyperlinkForm={props.showHyperlinkForm}
                    createHyperlink={props.createHyperlink}
                    allLinks={props.allLinks}
                    goToLinkTable={props.goToLinkTable}
                    uploadData={props.uploadData}
                    sendData={props.sendData}
                /> */}
                <div className='w-full'>
                    <Outlet />
                </div>
            </div>


        </div>
    )
}

SettingComponent.propTypes = {
    allLinks: PropTypes.array,
    goToLinkTable: PropTypes.func,
    uploadData: PropTypes.array,
    subAdminCreate: PropTypes.func,
    getAllUsers: PropTypes.func,
    subAdminUser: PropTypes.string,
    showSubAdminForm: PropTypes.bool,
    goBackToTable: PropTypes.func,
    toggleSubAdmin: PropTypes.func,
    setShowSubAdminForm: PropTypes.func,
}

export default SettingComponent