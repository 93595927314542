import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';

const RadioButton = (props) => {
    const {t} = useTranslation()
    const [value, setValue] = React.useState('female');

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    return (
        <FormControl>
            {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
            <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name={t(props.name)}
                value={props.value}
                onChange={props.onChange}
            >
                {props.Radiolabel.map((item) =>
                    <FormControlLabel value={item.value} control={<Radio />} label={t(item.label)} />
                )}
            </RadioGroup>
        </FormControl>)
}

export default RadioButton