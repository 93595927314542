import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    subscriptionDetail: {},
    loading: false,
    error: false,
};

export const subscriptionAccountSlice = createSlice({
    name: 'subscriptionData',
    initialState,
    reducers: {
        getSubscriptionDetails: (state, action) => {
            state.subscriptionDetail = action.payload;
            state.loading = true;
            state.error = false;
        },
    }
})

export const { getSubscriptionDetails } = subscriptionAccountSlice.actions;
export default subscriptionAccountSlice.reducer;