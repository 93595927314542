import React, { useEffect } from 'react'
import SeeAllPage from '../../Components/LandingPage/SeeAllPage'
import { ForVendor } from '../VendorDetailPage/Hooks'
import { ForLanding } from './Hooks'
import AllHyperlinkCompo from '../../Components/LandingPage/AllHyperlinkCompo'
import { ForAdmin } from '../Admin/Hooks'
import { ForNotification } from '../Notification/Hooks'

const SeeAllHyperlink = () => {
    const { getAllHyperlinks } = ForAdmin();
    const { backtoPage, onHandelOpeModal } = ForLanding()
    const { handleshow, showNotification, allnotification, detailsPage, getNotification, readNotificationApi } = ForNotification()

    useEffect(() => {
        getAllHyperlinks()
        getNotification()
    }, [])

    return (
        <div>
            <AllHyperlinkCompo backtoPage={backtoPage}
                onHandelOpeModal={onHandelOpeModal}
                detailsPage={detailsPage}
                allnotification={allnotification}
                showNotification={showNotification}
                handleshow={handleshow}
                readNotificationApi={readNotificationApi}
            />
        </div>
    )
}

export default SeeAllHyperlink