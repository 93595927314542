import React, { useEffect } from 'react'
import TabComponent from '../../../Common/UiComponents/TabComponent'
import VendorServiceOfferActive from '../../VendorDetailsPage/ServiceOffer/VendorServiceOfferActive.jsx'
import VendorServiceOfferPending from '../../VendorDetailsPage/ServiceOffer/VendorServiceOfferPending.jsx'
import { useSelector } from 'react-redux'
import { ForAdmin } from '../../../Containers/Admin/Hooks'

const SingleVendorServiceOfferedTab = (props) => {
    const {value, setValue} = ForAdmin()

    const checkSingleVendorServicesOffered = useSelector((state) => state.totalVendorsInAdmin.services)

    const categories = [
        {
            label: "approved",
            value: "0",
            backgroundcolor: "bg-[#F7F7F7]",
            content: <VendorServiceOfferActive userData={checkSingleVendorServicesOffered} loading={props.loading}/>
        },
        {
            label: "pending",
            value: "1",
            backgroundcolor: "bg-white",
            content: <VendorServiceOfferPending
                // vendorServicePendingTableRowCls={`!h-[60vh]`}
                // toggleAddOffer={props.toggleAddOffer}
                // selectedService={props.selectedService}
                userData={checkSingleVendorServicesOffered}
                buttonLoading={props.buttonLoading}
                loading={props.loading}
            />
        },
    ]

    useEffect(() => {
        if (value === '0') {
            props.getServiceByBusinessInAdmin(props.singleVendorsInAdminPage?.id, 'active')
        } else if (value === '1') {
            props.getServiceByBusinessInAdmin(props.singleVendorsInAdminPage?.id, 'pending')
        }
    }, [props.singleVendorsInAdminPage, value])
    
    return (
        <div>
            <TabComponent
                mappingname={categories}
                button={false}
                value={value}
                setValue={setValue}
            />
        </div>
    )
}

export default SingleVendorServiceOfferedTab