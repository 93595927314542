import React from 'react'
import CommonCard from '../../Common/Layout/Card/CommonCard'
import img1 from '../../Images/Vendor/offerimg1.png'
import img2 from '../../Images/Vendor/offerimg2.png'
import img3 from '../../Images/Vendor/offerimg3.png'
import contactvendorimg from '../../Images/Vendor/contactvendorimage.svg'
import trackicon from '../../Images/Vendor/trackicon.svg'
import text from "../../Common/Languages/en_US.json"
const SeeAllProductOfferPAge = (props) => {

const itemContents = [
        {
          image: img1
        },
        {
          image: img2
        },
        {
          image: img3
        },
        {
          image: img3
        }
      ]
  return (
<div className={`p-[4%] bg-white-5`}>
     <div className={`flex flex-col lg:flex-row justify-center lg:justify-between items-center pb-[3%]`}>
         <p className={`text-left text-3xl font-[Roboto-Regular] font-normal text-deepgray-5 pb-2 lg:pb-0`}onClick={props.backtoPage}>{"ProductOffer"}</p>
     </div>
     <div className={` h-full gridcls`}>
         {props.vendorDetails?.map((item, idx) =>
             <div key={idx}>
                 <CommonCard
                     carousel={true}
                     carouselimg={itemContents}
                     itemname={item.name ? item.name : "--"}
                     carouselHeight="150px"
                     noContentCls={`h-[150px]`}
                     Shareicon={true}
                     rating={true}
                     offerpercentage={item.percentage ? item.percentage: "--"}
                     phone={true}
                     phonetext={item.user?.phoneNo ? item.user?.phoneNo : "--"}
                     location={true}
                     locationtext={(item?.lat !== null && item?.long !== null) && item?.location ? item?.location : "--"}
                     distance={true}
                     distancetext={item.distance ? item.distance : "--"}
                     hoverbutton={item?.user?.id !== parseInt(localStorage.getItem('userId')) ? true : false}
                    //  hoverbuttonTrack={(item?.lat === null && item?.long === null) ? false : true}
                    hoverbuttonTrack={false}
                     buttonName2={"Contact Vendor"}
                     buttonimg={contactvendorimg}
                     buttonName3={"Track"}
                     buttonimg2={trackicon}
                     item={item}
                     extracls={`min-h-[30rem]`}
                 />
             </div>
         )}

     </div>

 </div>  )
}

export default SeeAllProductOfferPAge