import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    faqData: [],
    faqDetails: {},
    loading: false,
    error: false,
}

export const cmsPageSlice = createSlice({
    name: 'cmsPage',
    initialState,
    reducers: {
        faqInAdmin: (state, action) => {
            state.faqData = action.payload;
            state.loading = false;
            state.error = false;
        },
        getFaqDetails: (state, action) => {
            state.faqDetails = action.payload;
            state.loading = false;
            state.error = false;
        }
    }
})

export const { faqInAdmin, getFaqDetails } = cmsPageSlice.actions;
export default cmsPageSlice.reducer;