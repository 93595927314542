import React, { useEffect } from "react";
import PayHistory from "../../Components/PayHistory";
import { ForSignup } from "../Authentication/Signup/Hook";
import { ForVendor } from "../VendorDetailPage/Hooks";
import { ForLanding } from "../LandingPage/Hooks";
import { ForAdmin } from "../Admin/Hooks";
import { ForNotification } from "../Notification/Hooks";
import { useNavigate } from "react-router-dom";
import { useJwt } from "react-jwt";
import { ForPaymentHistory } from "./Hooks";

const PaymentHistory = () => {
  const { GetAllBusinessAddress } = ForSignup();
  const { getCreatedNewOffers, getCreatedServiceOffers } = ForVendor();
  const {
    handleClickForOfferSeeAll,
    handleClickForBusinessSeeAll,
    handleClickForProductSeeAll,
    onHandelOpeModal,
    handleClickForAllHyperlink,
    handleAllServiceOffer,
    backtoPage,
    handleSearchResult,
    openMap,
    lat,
    lng,
    handleSearchData,
    searchData,
    searchResult,
    scrollClickHandler,
    getVendorsUnderSubCategory,
    markers,
    handleSetMarkers,
    selected,
    handleSetSelected,
  } = ForLanding();

  const { getCategory } = ForAdmin();

  const {
    handleshow,
    showNotification,
    allnotification,
    detailsPage,
    getNotification,
    readNotificationApi,
  } = ForNotification();
  const { getAllHyperlinks } = ForAdmin();

  const { getPaymentHistoryDetails } = ForPaymentHistory();

  useEffect(() => {
    getCreatedNewOffers();
    GetAllBusinessAddress();
    getCategory();
    getNotification();
  }, []);

  useEffect(() => {
    getAllHyperlinks();
  }, []);
  useEffect(() => {
    getCreatedServiceOffers();
  }, []);

  let userId;

  if (typeof window !== "undefined") {
    userId = localStorage.getItem("userId");
  } else {
    console.log("localStorage is not available");
  }

  useEffect(() => {
    getPaymentHistoryDetails(userId);
  }, []);

  //  useEffect(()=>{
  //     navigator.geolocation.getCurrentPosition((position) => {
  //         setCurrentLocation({
  //             text: 'This is your current location',
  //             lat: position.coords.latitude,
  //             lng: position.coords.longitude
  //         });
  //     })
  // }, [])

  const navigate = useNavigate();

  // For refresh token when actual token expires
  let token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";
  const { isExpired } = useJwt(token);
  const checkTokenExpiration = () => {
    if (isExpired) {
      navigate("/login");
    }
  };

  return (
    <div>
      <PayHistory
        detailsPage={detailsPage}
        allnotification={allnotification}
        showNotification={showNotification}
        handleshow={handleshow}
        handleClickForOfferSeeAll={handleClickForOfferSeeAll}
        handleClickForBusinessSeeAll={handleClickForBusinessSeeAll}
        handleClickForProductSeeAll={handleClickForProductSeeAll}
        onHandelOpeModal={onHandelOpeModal}
        handleClickForAllHyperlink={handleClickForAllHyperlink}
        readNotificationApi={readNotificationApi}
        handleAllServiceOffer={handleAllServiceOffer}
        backtoPage={backtoPage}
        handleSearchResult={handleSearchResult}
        openMap={openMap}
        lat={lat}
        lng={lng}
        handleSearchData={handleSearchData}
        searchData={searchData}
        searchResult={searchResult}
        scrollClickHandler={scrollClickHandler}
        getVendorsUnderSubCategory={getVendorsUnderSubCategory}
        markers={markers}
        handleSetMarkers={handleSetMarkers}
        selected={selected}
        handleSetSelected={handleSetSelected}
      />
    </div>
  );
};

export default PaymentHistory;
