import React, { useState } from 'react'
import TotalUsersHeader from '../TotalUsers/TotalUsersHeader'
import text from '../../../Common/Languages/en_US.json'
import TotalVendorsTable from './TotalVendorsTable'
// import VendorDetailsPage from './VendorDetailsPage'
import AddVendorFromForAdmin from './AddVendorFromForAdmin'
import StepperLayout from '../../../Common/Layout/StepperLayout/StepperLayout'


const VendorComponent = (props) => {

    return (
        <div>
            {!props.openVendorTable ?
                <>
                    <TotalUsersHeader userName={text.totalVendors}
                        csvBtn={true}
                        dropDown={true}
                        addBtn={true}
                        downloadExcelReport={props.downloadExcelReport} 
                        toggleSubAdmin={props.handleClickForOpenVendorTable} 
                        getTotalVendorsInAdmin={props.getTotalVendorsInAdmin}
                        forComponent= 'totalVendor'
                        setGovFieldData={props.setGovFieldData}
                        onChange={props.handleSearchVendor}
                        search={true}
                    />
                    <div className={`h-[75vh] !overflow-auto `}>
                        <TotalVendorsTable
                            getTotalVendorsInAdmin={props.getTotalVendorsInAdmin}
                            totalVendorsInAdminPage={props.totalVendorsInAdminPage}
                            handleeditclick={props.handleeditclick}
                            deleteclcik={props.deleteclick}
                            approveUserApi={props.approveUserApi}
                            userEnableDisableApi={props.userEnableDisableApi}
                            getSingleVendorDetailsApi={props.getSingleVendorDetailsApi}
                            checkEachVendorProductAdmin={props.checkEachVendorProductAdmin}
                            giveBadgetoUser={props.giveBadgetoUser}
                            approveVendorService={props.approveVendorService}
                            getServiceByBusinessInAdmin={props.getServiceByBusinessInAdmin}
                            loading={props.loading}
                            buttonLoading={props.buttonLoading}
                            createCorporateSubscription={props.createCorporateSubscription}
                        />
                    </div>
                </>
                :
                <div>
                    <AddVendorFromForAdmin
                        cancelclick={props.handleClickForOpenVendorTable}
                        cancelAddressclick={props.handleClickForOpenVendorTable}
                        showAddAddress={props.showAddAddress}
                        createVendorByAdmin={props.createVendorByAdmin}
                        businessAddAddressByAdmin={props.businessAddAddressByAdmin}
                        GetAllVendorByAdmin={props.GetAllVendorByAdmin}
                        doUploadedMedia={props.doUploadedMedia}
                        doUploadedLogo={props.doUploadedLogo}
                        // doUploadedMultipleImages={props.doUploadedMultipleImages}
                        editclick={props.editclick}
                        editVendorFormData={props.editVendorFormData}
                        editbusinessAddressDetails={props.editbusinessAddressDetails}
                        multipleMediaApiCall={props.multipleMediaApiCall}
                        totalVendorCreateMsg={props.totalVendorCreateMsg}
                        loading={props.loading}
                    />
                    {/* <CreateAddress/> */}
                </div>


            }



        </div>
    )
}

export default VendorComponent