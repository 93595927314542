import React, { useEffect } from 'react'
import img1 from '../../../Images/Vendor/offerimg1.png'
import img2 from '../../../Images/Vendor/offerimg2.png'
import img3 from '../../../Images/Vendor/offerimg3.png'
import contactvendorimg from '../../../Images/Vendor/contactvendorimage.svg'
import trackicon from '../../../Images/Vendor/trackicon.svg'
import { useTranslation } from 'react-i18next'
import CommonCard from '../../../Common/Layout/Card/CommonCard'
import { useSelector } from 'react-redux'

const FavouriteVendorUser = (props) => {
  const favVendorData = useSelector((state) => state.landingData?.favVendors)
  const userId = localStorage.getItem('userId')

  const { t } = useTranslation()
  const itemContents = [
    {
      image: img1
    },
    {
      image: img2
    },
    {
      image: img3
    },
    {
      image: img3
    }
  ]
  return (
      <div className={`py-2 min-h-screen`}>
        <div className={`grid gap-2 md:gap-5 grid-cols-1  md:grid-cols-3  grid-rows-1`}>
          {favVendorData?.map((item, idx) =>
            <div key={idx}>
              <CommonCard
                carousel={item?.business?.thumbnail && item?.business?.thumbnail?.length > 0 ? true : false}
                noContentImg={item?.business?.thumbnail && item?.business?.thumbnail?.length === 0 && true}
                carouselimg={item?.business?.thumbnail && item?.business?.thumbnail?.length > 0 ? item?.business?.thumbnail : itemContents}
                onHandelOpeModal={() => props.handleFavouriteVendor(item?.business?.user?.id)}
                itemname={item.business?.user?.name ? item.business?.user?.name : "--"}
                carouselHeight="150px"
                noContentCls={`h-[150px]`}
                Shareicon={true}
                height={false}
                rating={false}
                profile={true}
                profileicon={item?.business?.logo?.key !== null && item?.business?.logo?.key}
                itememail={true}
                email={item?.business?.user?.email}
                phone={true}
                phonetext={item?.business?.user?.phoneNo}
                location={true}
                locationtext={(item?.business?.lat !== null && item?.business?.long !== null) && item?.business?.location ? item?.business?.location : "--"}
                itemtext={true}
                text1={item?.name}
                hoverbutton={true}
                hoverbuttonTrack={(item?.business?.lat === null && item?.business?.long === null) ? false : true}
                buttonName2={"Contact Vendor"}
                buttonimg={contactvendorimg}
                buttonName3={"Track"}
                buttonimg2={trackicon}
                showLikeIcon={true}
                favValue={1}
                onDelChange={() => {
                  props.deleteFavVendorApiCall(item?.id, userId, 'disable')
                }}
                item={item}
              />
            </div>
          )}
        </div>
      </div>
  )
}

export default FavouriteVendorUser