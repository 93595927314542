import React, { useEffect, useState } from 'react'

import text from "../../../Common/Languages/en_US.json"
import TotalUsersHeader from '../TotalUsers/TotalUsersHeader';
import ButtonField from '../../../Common/FormFields/ButtonField';
import SubAdminFileUpload from './SubAdminFileUpload';
import SubAdminForm from './SubAdminForm';
import SubAdminTable from './SubAdminTable';
import { ForAdmin } from '../../../Containers/Admin/Hooks';
import SettingSidebar from './SettingSidebar';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';



const AddSubAdmin = (props) => {



    return (
        <div className='bg-white flex'>
            {/* setting drawer  */}
            {/* <SettingSidebar /> */}
            {/* setting content  */}
            <div className='w-full'>

                {props.showSubAdminForm ? (
                    <>
                        <TotalUsersHeader
                            userName={text.addSubAdmin}
                            dropDown={false}
                            addBtn={false}
                        />
                        <div className='flex'>
                            <div className='w-[70%]'>
                                <SubAdminForm
                                    subAdminCreate={props.subAdminCreate}
                                    getAllUsers={props.getAllUsers}
                                    subAdminUser={props.subAdminUser}
                                    showSubAdminForm={props.showSubAdminForm}
                                    goBackToTable={props.goBackToTable}
                                    editSubAdminFormData={props.editSubAdminFormData}
                                    editSubAdminApiCall={props.editSubAdminApiCall}
                                    subAdminCreateMsg={props.subAdminCreateMsg}
                                    loading={props.loading}
                                />
                            </div>
                        </div>
                    </>

                ) : (
                    <div className={`h-[75vh]`}>
                        <TotalUsersHeader
                            userName={`Sub-Admin`}
                            dropDown={false}
                            addBtn={localStorage.getItem('role') === 'admin' && true}
                            toggleSubAdmin={props.toggleSubAdmin}
                            btnColor={true}
                        />
                        <SubAdminTable
                            getAllUsers={props.getAllUsers}
                            subAdminUser={props.subAdminUser}
                            subAdminData={props.subAdminData}
                            editSubAdminForm={props.editSubAdminForm}
                            deleteSubAdminApiCall={props.deleteSubAdminApiCall}
                            loading={props.loading}
                        />
                    </div>
                )}
                {/* subAdmin form component  */}

            </div>
        </div>
    )
}

AddSubAdmin.propTypes = {
    subAdminCreate: PropTypes.func,
    getAllUsers: PropTypes.func,
    subAdminUser: PropTypes.string,
    showSubAdminForm: PropTypes.bool,
    goBackToTable: PropTypes.func,
    toggleSubAdmin: PropTypes.func,
}

export default AddSubAdmin