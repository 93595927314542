import React from 'react'
import PrivacyPolicy from './PrivacyPolicy'
import FAQ from './FAQ'
import TabComponent from '../../../Common/UiComponents/TabComponent'
import { useSelector } from 'react-redux'

const CMSComponent = (props) => {
    const allPrivPolFaq = useSelector((state) => state.cmsPage.faqData)
    const allPrivPol = allPrivPolFaq?.filter((item) => item?.type === 'privacyPolicy')
    const allFaq = allPrivPolFaq?.filter((item) => item?.type === 'faq')
    const eachFaqDetails = useSelector((state) => state.cmsPage.faqDetails)

    const categories = [
        {
            label: "Privacy Policy",
            value: "0",
            backgroundcolor: "bg-[#F7F7F7]",
            content: <PrivacyPolicy 
            createFaqApiCall={props.createFaqApiCall}
            // handleTextEditor= {props.handleTextEditor}
            handlePrivPolTextEditor={props.handlePrivPolTextEditor}
            showPrivPolTextEditor={props.showPrivPolTextEditor}
            value={props.value}
            allPrivPol={allPrivPol}
            // showTextEditor = {props.showTextEditor}
            // allFaq= {allFaq}
            getFaqInAdminApiCall = {props.getFaqInAdminApiCall}
            getFaqDetailsApi = {props.getFaqDetailsApi}
            eachFaqDetails = {eachFaqDetails}
            privacyDetails = {props.privacyDetails}
            />
        },
        {
            label: "FAQs",
            value: "1",
            backgroundcolor: "bg-[#F9FBFD]",
            content: <FAQ
                createFaqApiCall={props.createFaqApiCall}
                handleFaqTextEditor={props.handleFaqTextEditor}
                showFaqTextEditor={props.showFaqTextEditor}
                value={props.value}
                allFaq={allFaq}
            />
        },
    ]

    return (
        <div className='overflow-y-auto h-[86vh]'>
            <TabComponent
                mappingname={categories}
                button={false}
                value={props.value}
                setValue={props.setValue}
                faqTab={'faqtab'}
            />
        </div>
    )
}

export default CMSComponent