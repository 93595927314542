import React, { useEffect } from 'react'
import VendorSettingChangePass from '../../../Components/Authentication/Settings/VendorSettingChangePass'
import { ForVendor } from '../../VendorDetailPage/Hooks'
import SnackBarComponent from '../../../Common/Layout/Snackbar/SnackBar'

const VendorSettingPasswordMain = () => {
  const { changePasswordForVendorApiCall, getBusiness, notification, messageClose, changePasswordLoader } = ForVendor()
  useEffect(() => {
    getBusiness()
  }, [])

  return (
    <div>
      <VendorSettingChangePass changePasswordForVendorApiCall={changePasswordForVendorApiCall} changePasswordLoader={changePasswordLoader}  />
      <SnackBarComponent
        messageOpen={notification.open}
        messageClose={messageClose}
        notificationText={notification.message}
        subText={notification.subText}
        alertType={notification.alertType}
        borderClass={notification.borderClass}
      />
    </div>
  )
}

export default VendorSettingPasswordMain