import React, { useEffect } from 'react'
import Footer from '../../Common/Layout/Footer/Footer'
import LandingHeader from '../../Common/Layout/LandingHeader/LandingHeader'
import MainHeader from '../../Common/Layout/MainHheader/MainHeader'
import AllNotificationShow from '../../Components/Notification/AllNotificationShow'
import NotificationShow from '../../Components/Notification/NotificationShow'
import { ForNotification } from './Hooks'

const NotificationIndex = (props) => {
  const { backtohomePage, getNotification, readNotificationApi } = ForNotification()

  useEffect(() => {
    getNotification()
  }, [])

  return (
    <div>
      <div className='sticky top-0 z-50'>
        <MainHeader />
        <LandingHeader
          searchBar={true}
          goTomessage={true}
          goToNotification={true}
          handleclickNotification={props.handleshow}
          border={true}
          profile={true}
          showNotification={props.showNotification}
          allnotification={props.allnotification}
          detailsPage={props.detailsPage}
          readNotificationApi={props.readNotificationApi}
        />
      </div>
      <NotificationShow backtohomePage={backtohomePage} readNotificationApi={readNotificationApi} />
      <Footer />

    </div>
  )
}

export default NotificationIndex