import React, { useEffect } from 'react'
import DashboardCompo from '../../Components/Admin/Dashboard/index.jsx'
import { ForAdmin } from './Hooks'
import { useNavigate } from 'react-router-dom'
import { useJwt } from 'react-jwt'

const DashboardIndex = () => {
    const {
        getAllUsers,
        getTotalVendorsInAdmin,
        downloadExcelReportDashboard,
        dateState,
        setDateState,
        getDashboardData,
        handleOpenDate,
        dateOpen,
        getMostViewedVendorsApiCall,
        dateForViews,
        setDateForViews,
        dateOpenViews,
        setDateOpenViews
    } = ForAdmin()

    useEffect(() => {
        getAllUsers();
        getTotalVendorsInAdmin();
    }, []);

    useEffect(() => {
        getDashboardData()
    }, [dateState[0]?.endDate]);

    useEffect(() => {
        getMostViewedVendorsApiCall()
    }, [dateForViews[0]?.startDate, dateForViews[0]?.endDate])

    const navigate = useNavigate()

    // For refresh token when actual token expires 
    let token = localStorage.getItem('token') ? localStorage.getItem('token') : "";
    const { isExpired } = useJwt(token);

    const checkTokenExpiration = () => {
        if (isExpired) {
            navigate('/login');
        }
    }

    return (
        <div onClick={() => checkTokenExpiration()}>
            <DashboardCompo
                downloadExcelReportDashboard={downloadExcelReportDashboard}
                dateState={dateState}
                setDateState={setDateState}
                getDashboardData={getDashboardData}
                handleOpenDate={handleOpenDate}
                dateOpen={dateOpen}
                dateForViews={dateForViews}
                setDateForViews={setDateForViews}
                dateOpenViews={dateOpenViews}
                setDateOpenViews={setDateOpenViews}
            />
        </div>
    )
}

export default DashboardIndex