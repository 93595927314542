import React from "react";

const NoContentPage = (props) => {
  return (
    <div className={`flex flex-col justify-center items-center ${props.NoContentMainDiv}`}>
      {props.nocontentimg && 
        <img src={props.nocontentimg} height={props.height} width={props.width} alt="noContent"/>
      }
      <div className="pt-3">
        <p className={`text-center text-xl font-[Roboto-Medium] ${props.Nocontent1sttext}`}>{props.text1}</p>
        <p className={`text-center text-sm font-[Roboto-Regular] ${props.Nocontent2ndtext}`}>{props.text2}</p>
      </div>
      <div className="pt-6">{props.button}</div>
    </div>
  );
}

export default NoContentPage;
