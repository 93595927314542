import React, { useEffect } from 'react'
import ManageNotificationsMain from '../../Components/Admin/Settings/ManageNotificationsMain'
import { ForAdmin } from './Hooks'
import { useSelector } from 'react-redux'
import SnackBarComponent from '../../Common/Layout/Snackbar/SnackBar';
import { useNavigate } from 'react-router-dom';
import { useJwt } from 'react-jwt';

const ManageNotificationsIndex = () => {

  const {
    checkSpecialAnnouncement,
    checkAccountExpiry,
    checkNewOffer,
    postNotificationSeting,
    checkNewUser,
    checkSubscriptionActivity,
    checkBidConfirmation,
    specialAnnouncement,
    accountExpiry,
    newOffer,
    newUser,
    subscriptionActivity,
    bidConfirmation,
    selectTypeMsg,
    dropdownName,
    getNotificationSetingData,
    notificationFound,
    notificationNotFound,
    updateNotifications,
    settingNotificationUpdate,
    adminSnackbar,
    messageClose
  } = ForAdmin()

  useEffect(() => {
    getNotificationSetingData()
  }, [])

  const navigate = useNavigate()

  // For refresh token when actual token expires 
  let token = localStorage.getItem('token') ? localStorage.getItem('token') : "";
  const { isExpired } = useJwt(token);
  const checkTokenExpiration = () => {
    if (isExpired) {
      navigate('/login');
    }
  }
  return (
    <div onClick={() => checkTokenExpiration()}>
      <ManageNotificationsMain
        checkSpecialAnnouncement={checkSpecialAnnouncement}
        checkAccountExpiry={checkAccountExpiry}
        checkNewOffer={checkNewOffer}
        postNotificationSeting={postNotificationSeting}
        checkNewUser={checkNewUser}
        checkSubscriptionActivity={checkSubscriptionActivity}
        checkBidConfirmation={checkBidConfirmation}
        specialAnnouncement={specialAnnouncement}
        accountExpiry={accountExpiry}
        newOffer={newOffer}
        newUser={newUser}
        subscriptionActivity={subscriptionActivity}
        bidConfirmation={bidConfirmation}
        selectTypeMsg={selectTypeMsg}
        dropdownName={dropdownName}
        notificationFound={notificationFound}
        notificationNotFound={notificationNotFound}
        updateNotifications={updateNotifications}
        settingNotificationUpdate={settingNotificationUpdate}
      />
      <SnackBarComponent
        messageOpen={adminSnackbar.open}
        messageClose={messageClose}
        notificationText={adminSnackbar.message}
        subText={adminSnackbar.subText}
        alertType={adminSnackbar.alertType}
        borderClass={adminSnackbar.borderClass}
      />

    </div>
  )
}

export default ManageNotificationsIndex