import React from 'react'
import { Box } from '@mui/material'
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { adminSideMenu } from './sideBarData'
import "./index.css"
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AdminSideBar = () => {
  const location = useLocation();

  const role = localStorage.getItem('role')
  const selectedUserDetailsData = JSON.parse(localStorage.getItem('sections'))
  const filteredSideMenu = adminSideMenu?.filter((itemMenu) => selectedUserDetailsData?.some((filMenu) => filMenu?.name === itemMenu?.name))

  const activeStyle = {
    backgroundColor: "#FFE3D4",
    fontFamily: 'Roboto-Regular',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '15px',
    margin: '0px',
    padding: '0px',
    paddingRight: '0px',
    color: '#FE5B00',
    borderRadius: '5px 5px 0px 0px',
    // filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(0deg)'
  };

  const style = {
    color: '#585F71',
    fontFamily: 'Roboto-Regular',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '15px',
  }

  return (
    <Box className={`sideMenuMain !w-full h-full drop-shadow-md bg-white`}>
      {role === 'admin' && adminSideMenu && adminSideMenu?.length > 0 ?
        adminSideMenu?.map((item, index) => {
          return (
            <nav key={index}>
              <List className={`navItems `} sx={{ marginBottom: "0.5rem", marginTop: "0.5rem", paddingTop: 0, paddingBottom: "2px" }}>
                <ListItem disablePadding >
                  <NavLink
                    to={item?.link}
                    style={
                      ({ isActive }) => isActive ? activeStyle : style
                    }
                    className={`w-full`}
                  >
                    <ListItemButton className='py-4'>
                      <ListItemIcon>
                        <img src={location?.pathname === item?.link ? item?.colorIcon : item?.icon} alt="menu" className="menuImg" />
                      </ListItemIcon>
                      <p className={`menuTxt capitalize`}>{item?.name}</p>
                    </ListItemButton>
                  </NavLink>
                </ListItem>
              </List>
            </nav>
          )
        })
        : 
        role === 'subAdmin' && filteredSideMenu?.length > 0 &&
        filteredSideMenu?.map((item, index) => {
          return (
            <nav key={index}>
              <List className={`navItems `} sx={{ marginBottom: "0.5rem", marginTop: "0.5rem", paddingTop: 0, paddingBottom: "2px" }}>
                <ListItem disablePadding >
                  <NavLink
                    to={item?.link}
                    style={
                      ({ isActive }) => isActive ? activeStyle : style
                    }
                    className={`w-full`}
                  >
                    <ListItemButton className='py-4'>
                      <ListItemIcon>
                        <img src={location?.pathname === item?.link ? item?.colorIcon : item?.icon} alt="menu" className="menuImg" />
                      </ListItemIcon>
                      <p className={`menuTxt capitalize`}>{item?.name}</p>
                    </ListItemButton>
                  </NavLink>
                </ListItem>
              </List>
            </nav>
          )
        })
      }
    </Box>
  )
}

export default AdminSideBar