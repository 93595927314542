import io from 'socket.io-client';
import getLocalStorageData from "./GetLocalStoregeData"

export default function socketConnection(topic,getFun) {
    try {
        let socket = null;
        let userId = getLocalStorageData("userId");// Get user data from local storage
        console.log(userId, "userId #")
        if (userId) { // User id checking is available or not
            // Socket connection 
            socket = io.connect(process.env.REACT_APP_SOCKET_URL,
                {
                    query: {
                        userId: userId
                    },
                    withCredentials: true,
                    extraHeaders: {
                        "my-custom-header": "abcd"
                    }
                })
            console.log(socket, "socket #")
            socket.on(topic, (data) => {
                console.log(data, "<<< 001 socket data received #")
                if(data?.data?.chatId){
                    console.log('call >> #')
                    getFun(data?.data?.chatId)
                }
                return data;

            })
        } else {
            console.log("user id not found", userId)
        }

        //Socket disconnect function
        // return function cleanup() {
        //     if (socket !== null) {
        //         socket.disconnect();
        //     }
        // };
    }
    catch (error) {
        console.log(error, "<-- Socket connection error")
    }
}