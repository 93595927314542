import React, { useEffect } from 'react'
import CMSComponent from '../../Components/Admin/CMS'
import { ForAdmin } from './Hooks'

const AdminCMSIndex = () => {
  const { value, setValue, createFaqApiCall, handlePrivPolTextEditor, showPrivPolTextEditor, getFaqInAdminApiCall, showFaqTextEditor, handleFaqTextEditor, getFaqDetailsApi, privacyDetails} = ForAdmin()

  useEffect(() => {
    getFaqInAdminApiCall()
  }, [])

  return (
    <div>
      <CMSComponent
        value={value}
        setValue={setValue}
        createFaqApiCall={createFaqApiCall}
        handlePrivPolTextEditor={handlePrivPolTextEditor}
        showPrivPolTextEditor = {showPrivPolTextEditor}
        getFaqInAdminApiCall={getFaqInAdminApiCall}
        showFaqTextEditor={showFaqTextEditor}
        handleFaqTextEditor={handleFaqTextEditor}
        getFaqDetailsApi = {getFaqDetailsApi}
        privacyDetails = {privacyDetails}
      />
    </div>
  )
}

export default AdminCMSIndex