import img_404 from "../../../Images/404.jpg"
import React from 'react'

export default function Custom404() {
    return (
        <div className="man404">
            <div className="man404Text">
                <p className="man404TextOOPS">oops!</p>
                <p className="man404TextNot">Page not found</p>
            </div>
            <img src={img_404} alt='img_404' className="man404Img" />
        </div>
    )
}
