const Json = () => {
  const columns = [
    { id: "id", label: "Id", minWidth: 40, align: "center" },
    { id: "paymentId", label: "Payment Id", minWidth: 120, align: "center" },
    {
      id: "transactionId",
      label: "Transaction Id",
      minWidth: 140,
      align: "center",
    },
    {
      id: "createdAt",
      label: "Created at",
      minWidth: 120,
      format: (value) => new Date(value).toLocaleDateString(),
      align: "center",
    },
    { id: "trackId", label: "Track Id", minWidth: 100, align: "center" },
    {
      id: "amount",
      label: "Amount",
      minWidth: 80,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "referenceNo",
      label: "Reference No.",
      minWidth: 130,
      align: "center",
    },
    { id: "authId", label: "Auth Id", minWidth: 100, align: "center" },
    { id: "currency", label: "Currency", minWidth: 30, align: "center" },
    { id: "type", label: "Type", minWidth: 70, align: "center" },
    { id: "status", label: "Status", minWidth: 70, align: "center" },
    { id: "planId", label: "Plan Id", minWidth:80, align: "center" },
  ];

  return {
    columns,
  };
};

export default Json;
