import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import RowModal from "./RowModal";
import Json from "./Json";

const { columns } = Json();

export default function PaymentTable(props) {
  const payments = props.paymentHistory;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCellClick = (row) => {
    setSelectedRow(row);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedRow(null);
  };

  return (
    <div className="px-10 py-8 flex flex-col gap-1">
      <div className="flex justify-center items-center pb-6">
        <p className={`font-[Poppins-Bold] text-4xl`}>Payment History</p>
      </div>
      {payments && payments?.length > 0 ? (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      className={`bg-primeryColour-4 text-[13px] text-white font-[Poppins-SemiBold]`}
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {payments
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((payments) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={payments.id}
                    >
                      {columns.map((column) => {
                        const value = payments[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={`center`}
                            className="cursor-pointer text-[13px] font-[Poppins-Regular]"
                            onClick={() => handleCellClick(payments)}
                          >
                            {column.format && typeof value === "string"
                              ? column.format(value)
                              : value === null
                              ? "N/A"
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={payments.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <div className="p-4 flex justify-center items-center h-[300px] lg:h-[400px]">
          <p className="font-[Poppins-Bold] text-xl">No Payment Found</p>
        </div>
      )}

      {/* Modal Component */}
      <RowModal
        open={modalOpen}
        onClose={handleCloseModal}
        rowData={selectedRow || {}}
      />
    </div>
  );
}
