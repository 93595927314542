import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    services: [],
    myrequirements: [],
    offers: [],
    serviceOffers: [],
    servicesByBusiness: [],
    singleVendorDetails: {},
    productOffers: [],
    myRequirementByVendorId : [],
    bidsByReqId : [],
    reviews: [],
    allInterestedBids: [],
    chats: [],
    chatById: [],
    chatWithByUserId: {},
    deniedBidByUserId: [],
    loading: false,
    error: false,
};

export const vendorSlice = createSlice({
    name: 'vendor',
    initialState,
    reducers: {
        getServices: (state, action) => {
            state.services = action.payload;
            state.loading = false;
            state.error = false;
        },
        getMyRequirements: (state, action) => {
            state.myrequirements = action.payload;
            state.loading = false;
            state.error = false;
        },
        getOffers: (state, action) => {
            state.offers = action.payload;
            state.loading = false;
            state.error = false;
        },
        getServiceOffers: (state, action) => {
            state.serviceOffers = action.payload;
            state.loading = false;
            state.error = false;
        },
        getSingleVendorDetails: (state, action) => {
            state.singleVendorDetails = action.payload;
            state.loading = false;
            state.error = false;
        },
        getSingleVendorProductOffers: (state, action) => {
            state.productOffers = action.payload;
            state.loading = false;
            state.error = false;
        },
        getServicesByBusiness: (state, action)=>{
            state.servicesByBusiness = action.payload;
            state.loading = false;
            state.error = false;
        },
        getReqByVendorId: (state, action) => {
            state.myRequirementByVendorId = action.payload;
            state.loading = false;
            state.error = false;
        },
        getBidByReqId: (state, action) => {
            state.bidsByReqId = action.payload;
            state.loading = false;
            state.error = false; 
        },
        getReviewsByBusIdVendor: (state, action) => {
            state.reviews = action.payload;
            state.loading = false;
            state.error = false; 
        },
        getAllInterestedBids: (state, action) => {
            state.allInterestedBids = action.payload;
            state.loading = false;
            state.error = false; 
        },
        getChats: (state, action) => {
            state.chats = action.payload;
            state.loading = false;
            state.error = false; 
        },
        getChatbyChatId: (state, action) => {
            state.chatById = action.payload;
            state.loading = false;
            state.error = false; 
        },
        getChatWithbyUserId: (state, action) => {
            state.chatWithByUserId = action.payload;
            state.loading = false;
            state.error = false; 
        },
        getDeniedBidByUserId : (state, action) => {
            state.deniedBidByUserId = action.payload;
            state.loading = false;
            state.error = false; 
        }
    }
})

export const {getServicesByBusiness, getServices, getMyRequirements, getOffers, getSingleVendorDetails, getSingleVendorProductOffers, getReqByVendorId, getBidByReqId, getReviewsByBusIdVendor, getAllInterestedBids, getChats, getServiceOffers, getChatbyChatId, getChatWithbyUserId, getDeniedBidByUserId } = vendorSlice.actions;
export default vendorSlice.reducer;