import React, { useState } from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const MuiDatePicker = (props) => {

    const { t } = useTranslation()

    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}>
            <DatePicker
                label={t(props.label)}
                value={props.value}
                defaultValue={props.defaultValue}
                views={props.views}
                onChange={props.onChange}
                disabled={props.disabled}
                maxDate={props.maxDate}
                minDate={props.minDate}
                defaultCalendarMonth={props.defaultCalendarMonth}
                inputFormat={props.format}
                renderInput={(params) => <TextField {...params} placeholder={t(props.placeholder)} className={props.dateClsName} />}
            />
        </LocalizationProvider>

    )
}

MuiDatePicker.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    direction: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    views: PropTypes.array,
    defaultValue: PropTypes.string,
}

export default MuiDatePicker