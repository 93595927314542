import React, { useEffect, useState } from 'react'
import hamBurgerIcon from '../../../Images/category/hamBurgerIcon.svg'
import camera from '../../../Images/category/camera.svg'
import addImg from '../../../Images/category/addImg.svg'
import editImg from '../../../Images/category/editImg.svg'
import deleteImg from '../../../Images/category/deleteImg.svg'
import InputField from '../../../Common/FormFields/InputField'
import TextFieldInput from '../../../Common/FormFields/TextFieldInput'
import Modal from '../../../Common/UiComponents/Modal'
import UploadButton from '../../../Common/UiComponents/UploadButton'
import { width } from '@mui/material/node_modules/@mui/system'
import CloseIcon from '@mui/icons-material/Close';
import { setUploadedLogo } from '../../../Containers/UploadMedia/reducer/UploadMediaReducer'
import { useDispatch } from 'react-redux'
import { ForUploadMediaHooks } from '../../../Containers/UploadMedia/Hooks'
import ButtonField from '../../../Common/FormFields/ButtonField'
import fileUploadIcon from "../../../Images/SubAdmin/fileUploadIcon.svg"

const CategoryTree = (props) => {
    const dispatch = useDispatch()
    const [showSubCategory, setShowSubCategory] = useState('')
    // const [uploadLogoProfile, setUploadLogoProfile] = useState([])

    const { logoUpload, cleanupMedia } = ForUploadMediaHooks()

    const showSubCategorys = (id) => {
        if (showSubCategory === id) {
            setShowSubCategory('')
        } else {
            setShowSubCategory(id)
        }
    }
    const logoObj = props.editSubCategoryData?.picture

    const logoVar = logoUpload

    // useEffect(() => {
    //     dispatch(setUploadedLogo(logoObj))
    // }, [])
    useEffect(() => {
        if (props.editSubCategoryData) {
            dispatch(setUploadedLogo(props.editSubCategoryData?.picture))
        }
    }, [props.editSubCategoryData])
    // for last index of the array
    const lastIndexOfCategory = props.categoryData[props.categoryData?.length - 1]

    return (
        <>
            {props.categoryData.map((item, idx) =>
                <div className={`flex flex-col gap-4 ml-[2%] my-[2%] h-full relative`} key={idx}>
                    {!(item.id === lastIndexOfCategory?.id) && <div className={`border-l border-solid border-neutralSecondary  h-full absolute left-[7px] top-8`}></div>}
                    <div className={`flex items-center`}>
                        <div className={`border border-solid rounded-full border-neutralSecondary h-4 w-4 flex justify-center items-center text-neutralSecondary text-base cursor-pointer bg-white z-10`} onClick={() => showSubCategorys(item?.id)}>{(showSubCategory === item?.id) ? '-' : '+'}</div>
                        <div className={`border-b border-solid border-neutralSecondary w-8 relative`}>
                            {!(item.id === lastIndexOfCategory?.id) && <div className={`border-l border-solid border-neutralSecondary h-8 absolute right-[40px] top-0`}></div>}
                            {!(item.id === props.categoryData[0]?.id) && <div className={`border-l border-solid border-neutralSecondary h-8 absolute right-[40px] bottom-0`}></div>}
                        </div>
                        <div className={`h-fit w-1/3 flex z-10 shadow-md`}>
                            <div className={`bg-skyblue-6 w-11 h-10 flex items-center justify-center rounded-tl rounded-bl cursor-pointer`} onClick={() => showSubCategorys(item?.id)}>
                                <img src={hamBurgerIcon} alt='hamBurgerIcon' />
                            </div>
                            <div className={`group border-y border-r border-solid bg-white border-neutralLighter h-10 w-full rounded-r flex justify-between items-center px-2`}>
                                {/* {(props.categoryId === item.id) ?
                                    <TextFieldInput
                                        type={'text'}
                                        variant="standard"
                                        defaultValue={props.newCategoryName || item?.name}
                                        name={'name'}
                                        handelChange={(e) => props.addNewCategoryName(e.target.value)}
                                        onBlur={() => { !((props.categoryId === item.id) && props.newCategoryName.length > 10) && props.editCategory(item?.id) }}
                                        autoFocus={true}
                                        onKeyPress={() => { !((props.categoryId === item.id) && props.newCategoryName.length > 10) && props.editCategory(item?.id) }}
                                    />
                                    : */}
                                <p className={`truncate w-20`}>{item?.name}</p>
                                {/* } */}
                                <div className={`flex gap-4 items-center `}>
                                    {item?.picture?.key !== null && <img
                                        src={item?.picture?.key !== null ?
                                            `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${item?.picture?.key}&height=25&width=25&objectfit=cover`
                                            :
                                            camera
                                        }
                                        crossOrigin="anonymous" alt='fileUploadIcon' height={25} width={25} className={`px-[2px]`}
                                    />}
                                    <img src={addImg} alt='addImg' width={20} height={18} className={`cursor-pointer`} onClick={() => props.subCategoryModal(item)} />
                                    <img src={editImg} alt='editImg' width={20} height={18} className={`cursor-pointer`} onClick={() => { props.openEditName(item); props.OpenModal(true) }} />
                                    <img src={deleteImg} alt='deleteImg' width={20} height={18} className={`cursor-pointer`} onClick={() => props.deleteCategory(item?.id)} />
                                </div>
                            </div>
                        </div>
                        {((props.categoryId === item.id) && props.newCategoryName.length > 10) && <p className={`text-xs text-red-500 pl-4`}>put the name below 10 carecter</p>}
                    </div>
                    {(showSubCategory === item?.id) ? <>
                        {item.subCategory.map((subitem, index) =>
                            <div className={`flex items-center ml-[3.75rem]`} key={index}>

                                <div className={`border border-solid rounded-full border-neutralSecondary h-4 w-4 flex justify-center items-center text-neutralSecondary text-base cursor-pointer`} >-</div>
                                <div className={`border-b border-solid border-neutralSecondary w-8 relative`}>
                                    <div className={`border-l border-solid border-neutralSecondary h-10 absolute right-10 -top-12`}></div>
                                </div>
                                <div className={`h-fit w-fit flex z-10 shadow-md`}>
                                    <div className={`bg-skyblue-5 w-11 h-10 flex items-center justify-center rounded-tl rounded-bl`}>
                                        <img src={hamBurgerIcon} alt='hamBurgerIcon' />
                                    </div>
                                    <div className={`group border-y border-r border-solid bg-white border-neutralLighter h-10 w-full rounded-r flex justify-between items-center px-2`}>
                                        {/* {(props.subCategoryId === subitem?.id) ?
                                            <TextFieldInput
                                                type={'text'}
                                                variant="standard"
                                                defaultValue={props.newSubCategoryName || subitem?.name}
                                                name={'name'}
                                                handelChange={(e) => props.addNewSubCategoryName(e.target.value)}
                                                onBlur={() => { !((props.subCategoryId === subitem.id) && props.newSubCategoryName.length > 10) && props.editSubCategory(subitem?.id) }}
                                                autoFocus={true}
                                                onKeyPress={() => { !((props.subCategoryId === subitem.id) && props.newSubCategoryName.length > 10) && props.editSubCategory(subitem?.id) }}
                                            />
                                            : */}
                                        <p className={`truncate w-[15rem]`}>{subitem?.name}</p>
                                        {/* } */}
                                        <div className={`flex gap-4 items-center `}>
                                            {subitem?.picture?.key !== null &&
                                                <img
                                                    src={subitem?.picture?.key !== null ?
                                                        `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${subitem?.picture?.key}&height=25&width=25&objectfit=cover`
                                                        :
                                                        camera
                                                    }
                                                    crossOrigin="anonymous" className={`border rounded`} alt='SubCategoryIcon' height={25} width={25}
                                                />}
                                            <img src={editImg} alt='editImg' width={20} height={18} className={`cursor-pointer`} onClick={() => { props.openEditSubCategoryName(subitem); props.subCategoryModal(subitem) }} />
                                            <img src={deleteImg} alt='deleteImg' width={20} height={18} className={`cursor-pointer`} onClick={() => props.deleteSubCategoryApiCall(subitem?.id)} />
                                        </div>
                                    </div>
                                </div>
                                {/* {((props.subCategoryId === itm.id) && props.newSubCategoryName.length > 10) && <p className={`text-xs text-red-500 pl-4`}>put the name below 10 carecter</p>} */}
                            </div>)}
                    </> : null}
                </div>)
            }
            <Modal
                open={props.openSubCategoryModal.state}
                HeaderTxt={props.editSubCategoryData ? `Update sub category` : `Add sub category`}
                // ConfirmBtn={`add`}
                // CancelmBtn={`cancel`}
                // handleClick={() => props.createCategory()}
                // handleClose={() => props.subCategoryModalClose()}
                // saveButtonExtraCls={props.addCategoryName.length > 10 && `!bg-neutralSecondary`}
                // errorMsg={props.addCategoryName === '' && '*You can not add an empty name field'}
                DilogContent={
                    <form onSubmit={props.subCategoryForm?.handleSubmit}>
                        <TextFieldInput
                            onlyValue
                            textnewclass={`w-full text-sm textFieldHeightforCategory`}
                            floatingLabel='*Enter name'
                            id={"name"}
                            textinputname={"name"}
                            onChange={props.subCategoryForm?.handleChange}
                            onBlur={props.subCategoryForm?.handleBlur}
                            value={props.subCategoryForm?.values.name}
                            error={
                                props.subCategoryForm?.touched.name &&
                                Boolean(props.subCategoryForm?.errors.name)
                            }
                            errorText={
                                props.subCategoryForm?.touched.name &&
                                props.subCategoryForm?.errors.name
                            }
                            clickEnter={props.subCategoryForm?.handleSubmit}
                        />
                        {/* {props.addCategoryName.length > 10 && <p className={`text-xs text-red-500`}>put the name below 10 carecter</p>} */}
                        <TextFieldInput
                            onlyValue
                            textnewclass={`w-full text-sm textFieldHeightforCategory`}
                            floatingLabel='*Write Description'
                            id={"description"}
                            textinputname={"description"}
                            onChange={props.subCategoryForm?.handleChange}
                            onBlur={props.subCategoryForm?.handleBlur}
                            value={props.subCategoryForm?.values.description}
                            error={
                                props.subCategoryForm?.touched.description &&
                                Boolean(props.subCategoryForm?.errors.description)
                            }
                            errorText={
                                props.subCategoryForm?.touched.description &&
                                props.subCategoryForm?.errors.description
                            }
                            clickEnter={props.subCategoryForm?.handleSubmit}
                        />
                        <div>
                            {
                                <div className={`relative pt-4`}>
                                    <UploadButton
                                        dropzoneGetRootPropsCls={`w-[5em]`}
                                        sendData={(data) => {
                                            // props.doUploadedLogo(data)
                                            // props.setUploadLogoProfile(data)
                                            if (data[0]?.type === 'image/jpeg' || data[0]?.type === 'image/png') {
                                                props.doUploadedLogo(data)
                                            }
                                        }}

                                        extracls='flex justify-center items-center bg-white  items-center rounded-md '
                                        type='button'
                                        fileUploadIcon={
                                            // props.uploadLogoProfile && props.uploadLogoProfile?.length > 0 &&
                                            //     (props.uploadLogoProfile[0]?.type === 'image/jpeg' || props.uploadLogoProfile[0]?.type === 'image/png') ?
                                            //     window.URL.createObjectURL(props.uploadLogoProfile[0]) :
                                            //     fileUploadIcon

                                            logoUpload && (logoUpload?.mimetype === 'image/jpeg' || logoUpload?.mimetype === 'image/png') ?
                                                `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${logoUpload?.key}&height=75&width=100` :
                                                fileUploadIcon
                                        }

                                        accept={'image/jpeg, image/svg, image/png'}
                                        multiple={false}
                                        disabled={logoUpload && (logoUpload.mimetype === 'image/jpeg' || logoUpload?.mimetype === 'image/png') ? true : false}

                                    />
                                    {logoUpload && (logoUpload?.mimetype === 'image/jpeg' || logoUpload?.mimetype === 'image/png') &&
                                        <div className={`absolute -top-0 left-[4.5rem] -right-4 cursor-pointer`}>
                                            <CloseIcon
                                                sx={{ fontSize: "12px", border: '1px solid red', borderRadius: '9999px' }}
                                                onClick={() => dispatch(setUploadedLogo({}))}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                            <p className='text-xs'>
                                Upload your picture here
                            </p>
                        </div>
                        <div className={`px-1.5 py-3 flex justify-end`}>
                            <ButtonField
                                loading={props.loading}
                                buttonName={props.editSubCategoryData ? 'update' : 'add'}
                                type='submit'
                                buttonextracls={` bg-skyblue-6 capitalize font-normal border-solid border px-3 py-1.5 mr-2 text-white rounded`}
                            // disabled={loading === true ? true : false}
                            />
                            <ButtonField
                                buttonName={`cancel`}
                                buttonextracls={`capitalize font-normal !text-black border border-black border-solid px-3 py-1.5`}
                                onClick={() => { props.subCategoryModalClose(); cleanupMedia() }}
                                type='reset'
                            />
                        </div>
                    </form>
                }
            />
        </>
    )
}

export default CategoryTree