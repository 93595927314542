import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    totalCategories: [],
    totalSubCategory: [],
    loading: false,
    error: false,
};

export const totalCategoryInAdminSlice = createSlice({
    name: 'totalCategoryInAdmin',
    initialState,
    reducers: {
        getTotalCategory: (state, action) => {
            state.totalCategories = action.payload;
            state.loading = false;
            state.error = false;
        },
        getTotalSubCategory: (state, action) => {
            state.totalSubCategory = action.payload;
            state.loading = false;
            state.error = false;
        },


    }
},
)

export const { getTotalCategory, getTotalSubCategory} = totalCategoryInAdminSlice.actions;
export default totalCategoryInAdminSlice.reducer;