import React, { useState } from 'react'
import DropDownField from '../../../Common/FormFields/DropDownField'
import { useTranslation } from 'react-i18next';

const VendorSettingChangeLang = () => {
  const { t, i18n } = useTranslation();

  const [lang, setLang] = useState()
  const languageArray = [
    { name: "English", value: "en_US" },
    { name: "Arabic", value: "ar" },
    { name: "Hindi", value: "hin" }
  ]

  return (
    <div className={`flex justify-between h-screen p-[5%]`}>
      <div className={`w-3/4`}>
        <p className={`text-deepgray-5 font-[Roboto-Medium] text-lg`}>Alternate language(s)</p>
        <p className={`text-sm text-[#737373]`}>
          Specify the alternate language(s) that this site will support. Users navigating to this site will be
          able to change the display language of the site to any one these languages
        </p>
      </div>
      <div>
        <p>Default Languages</p>
        <DropDownField
          selectOption={languageArray}
          dropDownRootCls={`h-11  text-sm !text-gray-60 font-[Roboto-Regular]`}
          filterImg={false}
          handleChange={(event) => {
            setLang(event.target.value);
            i18n.changeLanguage(event.target.value);
          }}
          option={lang}
          placeholder={i18n.language === "en_US" ? "English" : i18n.language === "ar" ? "Arabic" : i18n.language === "hin" ? "Hindi" : "Select language"}
        />
      </div>
    </div>
  )
}

export default VendorSettingChangeLang