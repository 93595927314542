import React, { useEffect } from 'react'
import SettingComponent from '../../Components/Admin/Settings'
import { ForAdmin } from './Hooks'
import { useNavigate } from 'react-router-dom'
import { useJwt } from 'react-jwt'

const SettingsIndex = () => {
    const { subAdminCreate,
        showSubAdminForm,
        setShowSubAdminForm,
        toggleSubAdmin,
        subAdminUser,
        goBackToTable,
        hyperlinkForm,
        showHyperlinkForm,
        createHyperlink,
        allLinks,
        getAllHyperlinks,
        goToLinkTable,
        uploadData,
        sendData,
         } = ForAdmin()

    const navigate = useNavigate()

    // For refresh token when actual token expires 
    let token = localStorage.getItem('token') ? localStorage.getItem('token') : "";
    const { isExpired } = useJwt(token);
    const checkTokenExpiration = () => {
        if (isExpired) {
            navigate('/login');
        }
    }
    useEffect(() => {
        navigate('/admin/settings/subAdmin')
    },[])
    return (
        <div onClick={() => checkTokenExpiration()}>
            <SettingComponent
                subAdminCreate={subAdminCreate}
                showSubAdminForm={showSubAdminForm}
                setShowSubAdminForm={setShowSubAdminForm}
                toggleSubAdmin={toggleSubAdmin}
                subAdminUser={subAdminUser}
                goBackToTable={goBackToTable}
                hyperlinkForm={hyperlinkForm}
                showHyperlinkForm={showHyperlinkForm}
                createHyperlink={createHyperlink}
                allLinks={allLinks}
                goToLinkTable={goToLinkTable}
                uploadData={uploadData}
                sendData={sendData}
            />
        </div>
    )
}

export default SettingsIndex