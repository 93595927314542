import React from 'react'
import LoginPage from './Login/LoginPage'
import SnackBarComponent from '../../Common/Layout/Snackbar/SnackBar'

const AuthenticationIndex = (props) => {
    return (
        <div>
            <LoginPage
                Login={props.Login}
                setEmail={(e) => props.setEmail(e)}
                setPassword={(e) => props.setPassword(e)}
                emailErrorMessage={props.emailErrorMessage}
                message={props.message}
                passwordErrorMessage={props.passwordErrorMessage}
                loginError={props.loginError}
                setLoginError={props.setLoginError}
                loading={props.loading}
                checked={props.checked}
                handleCheck={props.handleCheck}
            />
        </div>
    )
}

export default AuthenticationIndex