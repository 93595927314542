import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import TextFieldInput from '../../../Common/FormFields/TextFieldInput';
import ButtonField from '../../../Common/FormFields/ButtonField'
import { useNavigate } from 'react-router-dom';
import { Icon, IconButton, InputAdornment } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ForVendor } from '../../../Containers/VendorDetailPage/Hooks';

const VendorSettingChangePass = (props) => {
  const navigate = useNavigate()
  const role = localStorage.getItem('role');
  const errorTextCss = `error-message text-red-400 text-xs`
  const buttonClass =`cursor-pointer  border-[1.5px] border-solid border-deepgray-0.5 capitalize h-12`
  const [formdata, setFormData] = useState({ oldPass: "", newPass: "", confNewPass: "" })

  const [showPassword, setShowPassword] = useState(true)
  const [showNPassword, setShowNPassword] = useState(true)
  const [showCNPassword, setShowCNPassword] = useState(true)
  const [passErrorMsg, setPassErrorMsg] = useState("")

  const { goBackFromUserProfileEdit } = ForVendor()
  const { handleSubmit, reset, control, getValues, formState: { errors } } = useForm();
  const onSubmit = (data) => {
    let obj = { ...formdata, data }
    if (obj?.newPass === obj?.confNewPass) {
      setPassErrorMsg('')
      props.changePasswordForVendorApiCall(data)
      setFormData({ ...formdata, data })
      // props.forUserEdit === 'forUserEdit' ? navigate('/user') : navigate('/vendor')
      // role === 'user' ? props.goBackFromUserProfileEdit() : navigate('/vendor')
      reset()
    }
    else {
      setPassErrorMsg('Password must match')
    }
  }

  return (
    <div className={props.forUserEdit === 'forUserEdit' ? 'px-[5%] py-[1%] min-h-screen' : 'p-[5%] w-[65%]'}>
      <form onSubmit={handleSubmit(onSubmit, reset)} className='flex flex-col gap-5'>

        <div>
          {/* Old password  */}
          <div className={`pb-[2%]`}>
            <Controller
              name={"oldPass"}
              control={control}
              rules={{
                minLength: 2,
                maxLength: 30,
                required: true
              }}
              render={({ field: { onChange, value } }) => (
                <TextFieldInput
                  onlyValue
                  textnewclass={`w-full text-sm`}
                  floatingLabel="Old password"
                  onChange={onChange}
                  value={value}
                  typePassword={showPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        tabIndex={-1}
                      >
                        <Icon className="text-20" color="action" tabIndex={-1}>
                          {showPassword ?
                            <VisibilityOffIcon sx={{ color: '#747774' }} />
                            :
                            <RemoveRedEyeIcon sx={{ color: '#747774' }} />}
                        </Icon>
                      </IconButton>
                    </InputAdornment>}
                />
              )}
            />
            {errors.oldPass && errors.oldPass.type === "required" && (
              <span className={errorTextCss}>Please enter your old password</span>
            )}
          </div>

          {/* New password  */}
          <div className={`pb-[2%]`}>
            <Controller
              name={"newPass"}
              control={control}
              rules={{
                minLength: 2,
                maxLength: 30,
                required: true
              }}
              render={({ field: { onChange, value } }) => (
                <TextFieldInput
                  onlyValue
                  textnewclass={`w-full text-sm`}
                  typePassword={showNPassword}
                  floatingLabel="New password"
                  onChange={onChange}
                  value={value}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowNPassword(!showNPassword)}
                        tabIndex={-1}
                      >
                        <Icon className="text-20" color="action" tabIndex={-1}>
                          {showNPassword ?
                            <VisibilityOffIcon sx={{ color: '#747774' }} />
                            :
                            <RemoveRedEyeIcon sx={{ color: '#747774' }} />}
                        </Icon>
                      </IconButton>
                    </InputAdornment>}
                />
              )}
            />
            {errors.newPass && errors.newPass.type === "required" && (
              <span className={errorTextCss}>Please enter your new password</span>
            )}
          </div>

          {/* Confirm new password  */}
          <div className={`pb-[2%]`}>
            <Controller
              name={"confNewPass"}
              control={control}
              rules={{
                required: true,
                validate: (value) => value === getValues("newPass")
              }}
              render={({ field: { onChange, value } }) => (
                <TextFieldInput
                  onlyValue
                  textnewclass={`w-full text-sm`}
                  floatingLabel="Confirm new password"
                  typePassword={showCNPassword}
                  onChange={onChange}
                  value={value}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowCNPassword(!showCNPassword)}
                        tabIndex={-1}
                      >
                        <Icon className="text-20" color="action" tabIndex={-1}>
                          {showCNPassword ?
                            <VisibilityOffIcon sx={{ color: '#747774' }} />
                            :
                            <RemoveRedEyeIcon sx={{ color: '#747774' }} />}
                        </Icon>
                      </IconButton>
                    </InputAdornment>}
                />
              )}
            />
            {errors.confNewPass && errors.confNewPass.type === "validate" && (
              <span className={errorTextCss}>Doesn't match</span>
            )}
          </div>
        </div>
        <div>
          {/* Buttons  */}
          <div className={`flex gap-4`}>
            <ButtonField
              buttonextracls={buttonClass}
              buttonName="Cancel"
              buttonnamecls={`font-[Roboto-Medium] text-deepgray-0.5 font-medium text-sm`}
              onClick={() => props.forUserEdit === 'forUserEdit' ? props.goBackFromUserProfileEdit() : navigate(`/vendor`)}
              type="reset"
            />
            <ButtonField
              buttonextracls={`bg-skyblue-6 w-fit ${buttonClass}`}
              buttonName="Save Password"
              type="submit"
              buttonnamecls={`font-[Roboto-Regular] text-white font-medium text-sm`}
              onClick={handleSubmit(onSubmit)}
              loading={props.changePasswordLoader}
              disabled={props.changePasswordLoader}
            />
          </div>
        </div>
        {passErrorMsg ? <p>{passErrorMsg}</p> : null}
      </form>
    </div>
  )
}

export default VendorSettingChangePass