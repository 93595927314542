import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    uploadedData: {},
    uploadedLogo: {},
    uploadMultipleImg: [],
    editMultiImg: [],
    loading: false,
    error: false,
};

export const mediaSlice = createSlice({
    name: 'upload',
    initialState,
    reducers: {
        setUploadedData: (state, action) => {
            state.uploadedData = action.payload;
            state.loading = false;
            state.error = false;
        },
        setUploadedLogo: (state, action) => {
            state.uploadedLogo = action.payload;
            state.loading = false;
            state.error = false;
        },
        setUploadMultipleImg: (state, action) => {
            state.uploadMultipleImg = action.payload;
            state.loading = false;
            state.error = false;
        },
        setEditMultipleImg: (state, action) => {
            state.editMultiImg = action.payload;
            state.loading = false;
            state.error = false;
        },
        setMediaLoading : (state, action) => {
            state.loading = action.payload;
            state.error = false;
        },
        resetAllMedia: (state) => {
            state.uploadedData = [];
            state.uploadedLogo = [];
            state.uploadMultipleImg = [];
            state.loading = false;
            state.error = false;
        },
    }
})

export const { setUploadedData, setUploadedLogo, resetAllMedia, setUploadMultipleImg, setMediaLoading, setEditMultipleImg } = mediaSlice.actions;
export default mediaSlice.reducer;