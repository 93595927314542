import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Divider, ListItemIcon, ListItemText } from '@mui/material';

export default function HamList(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={
                    {
                        width: '1em'
                    }
                }
            >
                {props.button}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {
                    props?.hamListArr?.map((menuItem, idx) => {
                        return <div key={idx} >
                            <MenuItem onClick={menuItem.clickHandler}>
                                <ListItemIcon>
                                    <img src={menuItem.imgSrc} alt={menuItem.name} />
                                </ListItemIcon>
                                <ListItemText>
                                    {menuItem.name}
                                </ListItemText>
                            </MenuItem>
                            <Divider className='!m-0' />
                        </div> 
                    })
                }
            </Menu>
        </div>
    );
}