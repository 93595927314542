import moment from "moment";
import { useState } from "react";
import { ForUploadMediaHooks } from "../../UploadMedia/Hooks";
import { doGetApiCall, doPostApiCall } from "../../../Utils/ApiConfig";
import { useDispatch } from "react-redux";
import { getMyReqUser, getReqByUserId } from "../reducer/UserReducer";

export const ForUser = () => {
    const [buttonLoader, setButtonLoader] = useState(false)
    const businessId = localStorage.getItem('businessId')
    const [showMyReqrFormUser, setShowMyReqrFormUser] = useState(false);
    const { documentUpload, logoUpload, multipleUpload, cleanupMedia, editMultiUpload } = ForUploadMediaHooks()
    const [value, setValue] = useState("0");
    const dispatch = useDispatch()
    const userId = localStorage.getItem('userId')
    const [loadingUser, setLoadingUser] = useState(false)

    // These below three functionalities are only for snackbar of all kinds of form fillup's post api calls in vendor
    const [notification, setNotification] = useState({
        open: false,
        message: "",
        subText: "",
        alertType: "",
        borderClass: "",
    });
    const messageClose = () => {
        setNotification({
            open: false,
            message: "",
            subText: "",
            alertType: "",
            borderClass: "",
        });
    };
    const openFormMessage = (alertType, message, subText, borderClass) => {
        if (alertType) {
            setNotification({
                open: true,
                message: message,
                subText: subText,
                alertType: alertType,
                borderClass: borderClass,
            });
            setTimeout(messageClose, 5000);
        }
    };
    ////////

    // For submit errors while posting or adding new product offers in the offer section
    const [reqError, setReqError] = useState({
        code: "",
        error: false,
        message: ""
    })

    const toggleUserMyRequirementForm = () => {
        setShowMyReqrFormUser(!showMyReqrFormUser)
    }
    const [saveReqData, setSaveReqData] = useState(null);

    // state and function for changing in live requirements of my requirements
    const [showUserMyReqrLiveDetails, setShowUserMyReqrLiveDetails] = useState(false)
    const goToUserMyReqrLiveDetails = (data) => {
        setSaveReqData(data)
        setShowUserMyReqrLiveDetails(!showUserMyReqrLiveDetails)
    }

    const goBackFromMyReqrLiveDetails = () => {
        setSaveReqData(null)
        setShowUserMyReqrLiveDetails(!showUserMyReqrLiveDetails)
    }
    /**
   *@uplRanabir
   *@description- This function is used for making api call to create myRequirements for user
   **/
    const myRequirementCreateUser = (formData) => {
        setButtonLoader(true)
        let data = {
            url: `${process.env.REACT_APP_DG_BASE_URL}/requirements`,
            bodyData: {
                title: formData?.title,
                startDate: moment.parseZone(formData?.startDate).utc(true).format(),
                endDate: moment.parseZone(formData?.endDate).utc(true).format(),
                startTime: moment(formData?.startTime).format('HH:mm'),
                endTime: moment(formData?.endTime).format('HH:mm'),
                notify: formData?.type,
                // businessId: parseInt(businessId),
                categoryId: formData?.selectCategory,
                subCategoryId: formData?.selectSubCategory,
                // zipCode: formData?.zip,
                // location: formData?.location,
                description: formData?.description,
                // requiredDocument: {
                //   name: documentUpload[0]?.originalname,
                //   key: documentUpload[0]?.key,
                //   mimetype: documentUpload[0]?.mimetype,
                //   location: documentUpload[0]?.location,
                //   size: documentUpload[0]?.size
                // },
                requiredDocument: multipleUpload
            }
        }
        doPostApiCall(data)
            .then((res) => {
                if (!res.error) {
                    setButtonLoader(false)
                    openFormMessage("success", "Success", res?.message, "success")
                    setShowMyReqrFormUser(!showMyReqrFormUser)
                    // getVendorRequirementsByVendorId()
                    getUserRequirementsByUserId()
                    setValue('1')
                } else {
                    setButtonLoader(false)
                    openFormMessage("error", "Error", res?.message, "error")
                    setReqError({
                        code: res?.code,
                        error: res?.error,
                        message: res?.message
                    })
                }
            })
            .catch(err => {
                console.log(err, "error")
            })
    }

    /**
*@uplRanabir
*@description- This function is used for making api call for getting all the requirements of all users'
**/
    const getAllRequirementsUser = () => {
        let data = {
            url: `${process.env.REACT_APP_DG_BASE_URL}/requirements?dataPerPage=200`
        }
        doGetApiCall(data).then((res) => {
            if (!res.error) {
                dispatch(getMyReqUser(res.result))
            }

        })
            .catch(err => {
                console.log(err, "error")
            })
    }

    /**
    *@uplRanabir
    *@description- This function is used for making api call for getting all the requirements of each user
    **/
    const getUserRequirementsByUserId = (status) => {
        setLoadingUser(true)
        if (userId) {
            let data = {
                url: `${process.env.REACT_APP_DG_BASE_URL}/requirements/user?userId=${userId}&dataPerPage=200&status=${status}`
            }
            doGetApiCall(data).then((res) => {
                if (!res.error && res.status === 200) {
                    setLoadingUser(false)
                    dispatch(getReqByUserId(res.result))
                }
                else {
                    setLoadingUser(false)
                    dispatch(getReqByUserId([]))
                }
            })
                .catch(err => {
                    console.log(err, "error")
                })
        }
    }


    return {
        toggleUserMyRequirementForm,
        showMyReqrFormUser,
        myRequirementCreateUser,
        getAllRequirementsUser,
        getUserRequirementsByUserId,
        goToUserMyReqrLiveDetails,
        showUserMyReqrLiveDetails,
        goBackFromMyReqrLiveDetails,
        loadingUser,
        saveReqData
    }
}