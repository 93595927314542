import React from 'react'
import text from '../../Languages/en_US.json'
import PropTypes from 'prop-types';

const ProfileUserDescriptionCard = (props) => {
  return (
    <div className={`!bg-white p-5 border`}>
      <p className={`text-base font-[Roboto-Regular] text-black pb-5`}>{props.description}:</p>
      <p className={`text-[15px] leading-7 font-[Roboto-Regular] text-grey-600`}>
        {props.dataObject?.description === null ?
          "No Description found"
          :
          props.dataObject?.description
        }</p>
    </div>
  )
}

ProfileUserDescriptionCard.propTypes = {
  description: PropTypes.string,
  profileUserCardDescText: PropTypes.string,
  dataObject: PropTypes.object,
}

export default ProfileUserDescriptionCard