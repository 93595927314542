import React from 'react'
import CollapsibleUi from '../../../Common/UiComponents/CollapsibleUi'
import SubscriptionPlanCards from './SubscriptionPlanCards'
import { CircularProgress, Tabs, tabsClasses } from '@mui/material'
import { useSelector } from 'react-redux'
import NoContentPage from '../../../Common/Layout/NoContentPage/NoContentPage'

const PlanComponentIndex = (props) => {

  const subscriptionPlanData = useSelector((state) => state.subscription.plans)

  // Plans for general type 
  const vendorPlansData = subscriptionPlanData?.filter(item => item?.planType === 'general')

  // Plans for corporate type 
  const corporatePlansData = subscriptionPlanData?.filter(item => item?.planType === 'corporate')

  // Plans for Add-ons type 
  const addOnsData = subscriptionPlanData?.filter(item => item?.planType === 'addOns')


  // main data that is being mapped
  const subscriptionData = [
    { tag: 'General', data: vendorPlansData },
    { tag: 'Corporate-Plans', data: corporatePlansData },
    { tag: 'Add-ons', data: addOnsData },
  ]

  return (
    <div className={`flex flex-col gap-5 subscriptionCard`}>
      {subscriptionData && subscriptionData?.length > 0 && subscriptionData?.map((subscripdata, idx) =>
        <CollapsibleUi
          key={idx}
          title={subscripdata?.tag}
          titleCls={`text-lg `}
          containerCls={`max-w-full px-[2%]`}
          arrowEnd
        >
          {props.loading && subscripdata?.data?.length === 0 ?
            <div className='flex h-full justify-center items-center'>
              <CircularProgress size={20} color='success' />
              <p className='pl-3'>Loading...</p>
            </div>
            :
            subscripdata?.data && subscripdata?.data?.length > 0 ?
              <Tabs
                variant="scrollable"
                scrollButtons
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    '&.Mui-disabled': { opacity: 0.3 },
                  },
                }}
              >
                <div className={`flex gap-5 w-full`}>
                  {subscripdata?.data?.map((data, idx) =>
                    <SubscriptionPlanCards
                      key={idx}
                      data={data}                      
                      editClickHandler={() => props.editSubscriptionFormHandler(data)}
                      deleteClickApiCall={props.deleteSubscriptionPlanApiCall}
                    />
                  )}
                </div>
              </Tabs>
              :
              <div className=' border-2 border-white w-full '>
                <NoContentPage
                  NoContentMainDiv={`h-[20vh]`}
                  text2={`No Data Found`}
                />
              </div>
          }
        </CollapsibleUi>
      )}
    </div>
  )
}

export default PlanComponentIndex