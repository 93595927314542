import React from 'react'
import PropTypes from 'prop-types';
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@mui/material'

const MuiCarousel = (props) => {

    return (
        <>
            <Carousel
                sx={props.sx}
                duration={props.duration}
                height={props.height}
                navButtonsProps={props.navButtonsProps}
                autoPlay={props.autoPlay}
                swipe={props.swipe}
                animation={props.animation}
                strictIndexing={props.strictIndexing}
                indicators={props.indicators}
                stopAutoPlayOnHover={props.stopAutoPlayOnHover}
                interval={props.interval}
                fullHeightHover={props.fullHeightHover}
                navButtonsAlwaysVisible={props.navButtonsAlwaysVisible}
                navButtonsAlwaysInvisible={props.navButtonsAlwaysInvisible}
                cycleNavigation={props.cycleNavigation}
                onChange={props.handleChange}
                navButtonsWrapperProps={props.navButtonsWrapperProps}
            >
                {props.itemContents && props.itemContents?.length > 0 && props.itemContents?.map((item, id) =>
                    <div key={id} className={`flex justify-center items-center`}>
                        <Paper>
                            {item?.key && <div className='w-full h-[200px]'>
                                <img crossOrigin='anonymous' src={`${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${item?.key}&height=50&width=auto`} alt="carousel-img" style={{width: '100%', height: '100%', objectFit: 'cover'}} />
                            </div>
                                
                            }
                        </Paper>
                    </div>
                )}
            </Carousel>
        </>
    )
}

MuiCarousel.propTypes = {
    sx: PropTypes.number,
    duration: PropTypes.number,
    height: PropTypes.string,
    navButtonsProps: PropTypes.string,
    navButtonsWrapperProps: PropTypes.string,
    autoPlay: PropTypes.bool,
    swipe: PropTypes.bool,
    animation: PropTypes.string,
    strictIndexing: PropTypes.bool,
    indicators: PropTypes.bool,
    stopAutoPlayOnHover: PropTypes.bool,
    interval: PropTypes.number,
    fullHeightHover: PropTypes.bool,
    navButtonsAlwaysVisible: PropTypes.bool,
    navButtonsAlwaysInvisible: PropTypes.bool,
    cycleNavigation: PropTypes.bool,
    onChange: PropTypes.func,
    itemContents: PropTypes.array
};

export default MuiCarousel