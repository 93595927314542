import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    userDetail: [],
    loading: false,
    error: false,
};

export const loggedInAccountSlice = createSlice({
    name: 'loggedIn',
    initialState,
    reducers: {
        getLoggedInDetails: (state, action) => {
            state.userDetail = action.payload;
            state.loading = true;
            state.error = false;
        },
    }
})

export const { getLoggedInDetails } = loggedInAccountSlice.actions;
export default loggedInAccountSlice.reducer;