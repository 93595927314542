import React from 'react'
import { Grid } from '@mui/material'
import ButtonField from '../../../Common/FormFields/ButtonField'
import { Navigate, useNavigate, useLocation, Link } from 'react-router-dom'

const ApprovalPandingPage = (props) => {
    const navigate = useNavigate()
    let subscriptionInfo = JSON.parse(localStorage.getItem("subscriptionInfo"));

    const handleClick = () => {
        if (subscriptionInfo) {
            navigate(`/signup/subscriptionPlans?name=${subscriptionInfo?.name}&address=${subscriptionInfo?.address}&userId=${subscriptionInfo?.userId}`)
        }
    }
    return (
        <Grid container spacing={4}>
          
            <Grid item xs={12} md={12}>
                <div className={`flex flex-col justify-center items-center h-screen`}>
                    <div className={`border rounded-2xl border-solid border-Grey-200 flex flex-col justify-center items-center text-center py-[6%] px-[5%] md:px-[8%] w-[68%]`}>
                        <p className={`font-[Roboto-Bold] text-2xl text-Grey-900`}>Approval Pending from Admin</p>
                        <p className={`w-full md:w-[60%] font-[Roboto-Regular] text-base text-text-60 pb-5 pt-4`}>Don't hurry, your profile will be verified by Admin. It will take some time.</p>
                        <p className={`w-full md:w-[60%] font-[Roboto-Regular] text-base text-text-60 pb-5 pt-4`}>Your verification is successful. You need a subscription plan. Please click on the link below to see the plans. </p>
                        {/* <Link to='/signup/subscriptionPlans'> */}
                        <ButtonField
                            buttonextracls={`bg-skyblue-6 border-[1.5px] border-solid border-deepgray-0.5 capitalize w-32 h-10`}
                            buttonName="Go to plans >"
                            buttonnamecls={`font-[Roboto-Regular] text-white font-medium text-sm`}
                            type="submit"
                        onClick={handleClick}
                        />
                        {/* </Link> */}
                    </div>
                </div>

            </Grid>
            {/* rightside end*/}

        </Grid>
        )
}

export default ApprovalPandingPage