import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
} from "@mui/material";

const RowModal = ({ open, onClose, rowData }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const keyLabels = {
    id: "Id",
    user: "User",
    paymentId: "Payment Id",
    transactionId: "Transaction Id",
    createdAt: "Created at",
    trackId: "Track Id",
    amount: "Amount",
    referenceNo: "Reference No.",
    authId: "Auth Id",
    currency: "Currency",
    type: "Type",
    status: "Status",
    planId: "Plan Id",
    plan: "Plan",
    subscription: "Subscription",
  };

  const filteredData = Object.entries(rowData)
    .filter(
      ([key]) => !["userId", "updatedAt", "message", "resultCode", "endDate"].includes(key)
    )
    .map(([key, value]) => {
      if (key === "createdAt") {
        value = formatDate(value);
      }
      const label = keyLabels[key] || key;
      return { key: label, value };
    });

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle className="flex justify-between items-center border-b-2">
        <p className={`font-bold text-xl`}>Payment Details</p>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogTitle>
      <DialogContent className="p-7">
        <Table component={Paper} className={`drop-shadow-2xl rounded-xl`}>
          <TableBody>
            {filteredData.map(({ key, value }) => (
              <TableRow key={key}>
                <TableCell align="center" className={`font-[Poppins-Bold]`}>
                  {key}
                </TableCell>
                <TableCell align="center" className={`font-[Poppins-Regular]`}>{value !== null ? value : `N/A`}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

export default RowModal;
