import moment from "moment";

// This file is only for the common fumctions
export const distanceFunc = (lat1, lat2, lon1, lon2) => {
    let result;
    if (lat1 && lat2 && lon1 && lon2) {
        lon1 = lon1 * Math.PI / 180;
        lon2 = lon2 * Math.PI / 180;
        lat1 = lat1 * Math.PI / 180;
        lat2 = lat2 * Math.PI / 180;

        // dlon represent the latitude, and dlat represent the longitude
        let dlon = lon2 - lon1;
        let dlat = lat2 - lat1;

        //Formula:- a = sin²(Δφ/2) + cos φ1 ⋅ cos φ2 ⋅ sin²(Δλ/2)
        let a = Math.pow(Math.sin(dlat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);
        let c = 2 * Math.asin(Math.sqrt(a));

        // r is radius of the earth in KM 
        let r = 6371;
        result = (c * r).toFixed(2)
    } else {
        result = '--'
    }
    return result
}

export const calculateDateTimeDifference = (startDate, startTime, endDate, endTime) => {
    // Formatting date to YYYY-MM-DD format
    // const sdate = moment(startDate).format('YYYY-MM-DD');
    const edate = moment(endDate).format('YYYY-MM-DD');

    // Formatting date to HH:mm format
    // const stime = startTime;
    // const etime = endTime;

    // Combining date and time into a new time string like YYYY-MM-DDTHH:mm:ss
    // const startdateTimeString = sdate + 'T' + startTime + ':00';
    const enddateTimeString = edate + 'T' + endTime + ':00';

    // Creating a new Date object with the combined date & time
    const combinedStartDateTime = new Date();
    const combinedEndDateTime = new Date(enddateTimeString);

    let result;

    if (combinedEndDateTime > combinedStartDateTime) {
        // Calculation of the time difference in milliseconds
        const timeDifference = Math.abs(combinedEndDateTime - combinedStartDateTime);

        // Conversion of the time difference to appropriate units (days, hours, minutes, seconds)
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
        const seconds = Math.floor((timeDifference / 1000) % 60);

        result = `Time Left: ${days} D : ${hours} Hr : ${minutes} Min : ${seconds} S`
    } else {
        result = `Time Left: ${`--`} D : ${`--`} Hr : ${`--`} Min : ${`--`} S`
    }

    return result;
}

export const priceAfterDiscount = (price1, price2, percentage) => {
    let result;
    if (price1 && price2) {
        result = price1 - price2
    } 
    else if (price1 && percentage) {
        result = price1 * (1 - percentage / 100)
    }
    return result.toFixed(2)
}