import React from 'react'
import TextEditor from '../../../Common/UiComponents/TextEditor/TextEditor'
import CommonPrivacyAndPolicy from '../../../Common/Layout/PrivacyPolicy/CommonPrivacyPolicy'
import PrivacyPolicyAndFaqForm from './PrivacyPolicyAndFaqForm'

const PrivacyPolicy = (props) => {
  return (
    <div className='px-[1%] '>
      {props.showPrivPolTextEditor === true ?
          <PrivacyPolicyAndFaqForm
            createFaqApiCall={props.createFaqApiCall}
            handleTextEditor={props.handlePrivPolTextEditor}
            value={props.value}
          />
          :
          <CommonPrivacyAndPolicy handleTextEditor={props.handlePrivPolTextEditor} allPrivPol = {props.allPrivPol} getFaqInAdminApiCall = {props.getFaqInAdminApiCall} getFaqDetailsApi = {props.getFaqDetailsApi} eachFaqDetails = {props.eachFaqDetails} privacyDetails = {props.privacyDetails} />

      }

    </div>
  )
}

export default PrivacyPolicy