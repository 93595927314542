import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { GoogleMap, Marker, useLoadScript, MarkerF, InfoWindow, InfoWindowF, DirectionsRenderer } from "@react-google-maps/api";
import { useMemo } from "react";
import './LandingPage.css'
import profileIcon from '../../Images/Vendor/DefaultProfileicon.svg'
import { useLocation } from 'react-router';

const RenderGoogleMap = () => {
  const routeLocation = useLocation()
  const [markerData, setMarkerData] = React.useState();
  const [openWindow, setOpenWindow] = React.useState(false)
  const [directionsResponse, setDirectionsResponse] = useState(null);

  const myLatLngCenter = {
    lat: parseFloat(sessionStorage.getItem('latitude')),
    lng: parseFloat(sessionStorage.getItem('longitude'))
  }

  const googleMapsApiKey = `${process.env.REACT_APP_GOOGLE_API_KEY}`
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey,
  });

  const center = useMemo(() => ({
    lat: routeLocation?.state?.lat ? routeLocation?.state?.lat : routeLocation?.state?.business ? routeLocation?.state?.business?.lat : routeLocation?.state?.services ? routeLocation?.state?.services?.business?.lat : routeLocation?.state?.product ? routeLocation?.state?.product.business?.lat : 0,
    lng: routeLocation?.state?.long ? routeLocation?.state?.long : routeLocation?.state?.business ? routeLocation?.state?.business?.long : routeLocation?.state?.services ? routeLocation?.state?.services?.business?.long : routeLocation?.state?.product ? routeLocation?.state?.product.business?.long : 0
  }), []);

  React.useEffect(() => {
    if (routeLocation?.state !== null) {
      setMarkerData({
        name: routeLocation?.state?.name ? routeLocation?.state?.name : routeLocation?.state?.business ? routeLocation?.state?.business?.name : routeLocation?.state?.services ? routeLocation?.state?.services?.business?.name : routeLocation?.state?.product && routeLocation?.state?.product.business?.name,
        logoKey: routeLocation?.state?.logo?.key ? routeLocation?.state?.logo?.key : routeLocation?.state?.business ? routeLocation?.state?.business?.logo?.key : routeLocation?.state?.services ? routeLocation?.state?.services?.business?.logo?.key : routeLocation?.state?.product && routeLocation?.state?.product.business?.logo?.key,
        lat: routeLocation?.state?.lat ? routeLocation?.state?.lat : routeLocation?.state?.business ? routeLocation?.state?.business?.lat : routeLocation?.state?.services ? routeLocation?.state?.services?.business?.lat : routeLocation?.state?.product ? routeLocation?.state?.product.business?.lat : 0,
        lng: routeLocation?.state?.long ? routeLocation?.state?.long : routeLocation?.state?.business ? routeLocation?.state?.business?.long : routeLocation?.state?.services ? routeLocation?.state?.services?.business?.long : routeLocation?.state?.product ? routeLocation?.state?.product.business?.long : 0,
        address: routeLocation?.state?.location ? routeLocation?.state?.location : routeLocation?.state?.business ? routeLocation?.state?.business?.location : routeLocation?.state?.services ? routeLocation?.state?.services?.business?.location : routeLocation?.state?.product ? routeLocation?.state?.product.business?.location : 'Oops! Your location was not appropiate',
        email: routeLocation?.state?.user?.email,
        time: new Date(),
      });
    }
  }, [])

   async function calculateRoute() {
     const directionsService = new window.google.maps.DirectionsService();
     const directionsRenderer = new window.google.maps.DirectionsRenderer();

    const routeLine = {
      origin: `${myLatLngCenter?.lat}, ${myLatLngCenter?.lng}`,
      destination: `${center?.lat}, ${center?.lng}`,
      travelMode: window.google.maps.TravelMode.DRIVING,
    }

    // let map = new window.google.maps.Map(document.getElementById("map"), {
    //   zoom: 14,
    //   center: myLatLngCenter,
    // });

  const results = await directionsService.route(routeLine,
    function(response, status) { 
      if (status === 'OK') {
        directionsRenderer.setDirections(response); // Add route to the map
        // let directionsData = response.routes[0].legs[0]; // Get data about the mapped route
        // console.log(directionsData, '# directionsData')
      } else {
        window.alert('Directions request failed due to ' + status);
        return;
      }
    });
    
    // console.log(results, "# results")
    setDirectionsResponse(results);
  }

  useEffect(()=>{
    calculateRoute();
  }, [])
  return (
    <>
      {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <>
          <GoogleMap
            mapContainerClassName="map-container"
            center={center}
            zoom={15}
          >
            {directionsResponse && (
              <DirectionsRenderer directions={directionsResponse} />
            )}
            <MarkerF
              position={center}
              onClick={() => setOpenWindow(true)}
            />
            {/* <MarkerF
              position={myLatLngCenter}
            /> */}
            {openWindow &&
              <InfoWindowF
                position={{ lat: markerData?.lat, lng: markerData?.lng }}
                onCloseClick={() => setOpenWindow(false)}
              >
                <div className={`flex gap-4 items-center border p-2`}>
                  <img
                    crossOrigin='anonymous'
                    src={markerData.logoKey !== undefined ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${markerData?.logoKey}&height=60&width=60` : profileIcon}
                    alt="alt"
                    className={`h-fit w-fit rounded-full`}
                  />
                  <div>
                    <p>Business Name:-&nbsp;{markerData?.name}</p>
                    <p>Email:-&nbsp;{markerData?.email}</p>
                    <p>Address:-&nbsp;{markerData?.address}</p>
                  </div>
                </div>
                {/* <p>Lat:&nbsp;{markerData?.lat}</p> */}
                {/* <p>Long:&nbsp;{markerData?.lng}</p> */}
              </InfoWindowF>
            }
          </GoogleMap>
        </>
      )}
    </>
  )
}

export default RenderGoogleMap