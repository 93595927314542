import React, { useEffect, useState } from 'react'
import cross from '../../../Images/category/cross.svg'
import TextFieldInput from '../../FormFields/TextFieldInput'
// import roundedPlus from '../../../Images/category/roundedPlus.svg'
import pictureIcon from '../../../Images/CommonImages/pictureIcon.svg'
import { Grid } from '@mui/material'
import greentick from '../../../Images/accountcreate/greentick.svg'

const CategoryPopUp = (props) => {

  // let cat1 = props.categoryArrId.map((item) => { return item.id })
  // let cat2 = props.categoryArr.map((item) => { return item.id })


  // console.log(cat1, cat2, '#');

  // useEffect(() => {
  //   if (props.categoryArrId.every((e) => props.categoryArr.includes(e))) {
  //     setSt(true)
  //   } else {
  //     setSt(false)
  //   }
  // }, [])

  return (
    <div className={`w-[85vw] p-[5%]`}>
      {/* header */}
      <div className={`w-full flex justify-between items-center`}>
        <p className={`text-deepgray-4 font-[Roboto-Regular] font-semibold text-2xl`}>Add Category</p>
        <img src={cross} alt='cross' className='cursor-pointer' onClick={props.handleClose} height={16} width={16} property />
      </div>

      {/* search bar */}
      <div className={`my-4`}>
        <TextFieldInput
          placeholder="searchCategoryName"
          textnewclass={`landingSearchBar border border-solid border-gray-1.1 rounded w-full h-10`}
        />
      </div>

      {/* cards */}
      <Grid container gap={3}>
        {props.categoryArr.map((item, idx) =>
          <div
            key={idx}
            className={`cursor-pointer w-[15rem] border border-solid border-gray-1.1 rounded-[5px] flex justify-between items-center p-4 group hover:bg-skyblue-0.5 ${props.addCategory.some((matchedItems) => matchedItems.id === item?.id) && 'border border-green-600 bg-green-0.5'}`}
            onClick={() => { props.getCategoryForUse(item?.id, item?.name, item?.subCategory); }}
          >
            <div className={`flex gap-2 items-center`} >
              <img src={pictureIcon} alt='pictureIcon' height={46} width={46} className={`border-2 border-solid border-blue-700 h-10 w-10 rounded-full ${props.addCategory.some((matchedItems) => matchedItems.id === item?.id) && 'border-green-600'}`} />
              <p className={`text-deepgray-5 font-[Roboto-Regular] text-base`}>{item?.name}</p>
            </div>
            {props.addCategory.some((matchedItems) => matchedItems.id === item?.id) ?
              <img src={greentick} alt="green" className={`h-4 w-4`} />
              :
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={`group-hover:bg-skyblue-6 rounded-full `} >
                <path
                  d="M10.9167 5.41634H9.08337V9.08301H5.41671V10.9163H9.08337V14.583H10.9167V10.9163H14.5834V9.08301H10.9167V5.41634ZM10 0.833008C4.94004 0.833008 0.833374 4.93967 0.833374 9.99967C0.833374 15.0597 4.94004 19.1663 10 19.1663C15.06 19.1663 19.1667 15.0597 19.1667 9.99967C19.1667 4.93967 15.06 0.833008 10 0.833008ZM10 17.333C5.95754 17.333 2.66671 14.0422 2.66671 9.99967C2.66671 5.95717 5.95754 2.66634 10 2.66634C14.0425 2.66634 17.3334 5.95717 17.3334 9.99967C17.3334 14.0422 14.0425 17.333 10 17.333Z"
                  className={`fill-deepgrey-3 group-hover:fill-white`}
                />
              </svg>
            }
          </div>
        )}
      </Grid>
    </div>
  )
}

export default CategoryPopUp