import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import React, { useState } from 'react'
import PropTypes from 'prop-types';


const ReactDateRange = (props) => {


    return (
        <div style={props.customStyles}>
            <DateRangePicker
                // shownDate
                minDate={props.minDate}
                maxDate={props.maxDate}
                direction={props.direction}
                onChange={props.onChange}
                showSelectionPreview={props.showSelectionPreview}
                showMonthAndYearPickers={props.showMonthAndYearPickers}
                dateDisplayFormat={props.dateDisplayFormat}
                moveRangeOnFirstSelection={props.moveRangeOnFirstSelection}
                showMonthArrow={props.showMonthArrow}
                months={props.months}
                ranges={props.ranges}
                preventSnapRefocus={props.preventSnapRefocus}
                calendarFocus={props.calendarFocus}
                value={props.value}
               ref={props.wrapperRef}
            />
        </div>
    )

}
ReactDateRange.propTypes ={
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    direction: PropTypes.string,
    onChange: PropTypes.func,
    showSelectionPreview: PropTypes.bool,
    showMonthAndYearPickers: PropTypes.bool,
    dateDisplayFormat: PropTypes.string,
    moveRangeOnFirstSelection: PropTypes.bool,
    showMonthArrow: PropTypes.bool,
    ranges: PropTypes.object,
    preventSnapRefocus: PropTypes.bool,
    calendarFocus: PropTypes.string,
    months: PropTypes.number
}

export default ReactDateRange