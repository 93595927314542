import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import english from "./Languages/en_US.json";
import arabic from "./Languages/arabic.json";
import hindi from "./Languages/hindi.json";

// Default language that will be shown
const defaultLanguage = "en_US"

// supported languages 
const supportedLanguages = [
    { code: "en_US", name: "English" },
    { code: "hin", name: "Hindi" },
    { code: "ar", name: "عربي" },
]

const getUserLanguage = () => window.navigator.language;

// i18n.use(LanguageDetector).use(LanguageDetector).use(initReactI18next).init({
//     lng: "en_US",
//     fallbackLng: "en_US",

//     resources: {
//         en_US: {
//             translations: english
//         },
//         arabic: {
//             translations: arabic
//         }
//     },
//     debug: true,
//     ns: ["translations"],
//     defaultNS: "translations",
//     saveMissing: false,

//     interpolation: {
//         formatSeparator: ',',
//         format(value, format) {
//             if (format === 'uppercase') return value.toUpperCase();
//             return value;
//         },
//     },

//     react: {
//         defaultTransParent: 'div',
//         transSupportBasicHtmlNodes: true,
//         transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
//     },
// });

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
    // lng will override the browser detector if provided, so it is best to comment it out
    // lng: defaultLanguage,

    // supported languages that are allowed
    supportedLngs: supportedLanguages.map(
        (lang) => lang.code,
    ),
    debug: true,

    // provide a fallback when loading translations from a back-end, to avoid unsuccessful attempts to load default fallbackLng
    fallbackLng: defaultLanguage,

    // calls save missing key function on backend if key not found, it will send a valid defaultValue
    saveMissing: false,

    // path of the allowed languages to be mentioned here using a namespace
    resources: {
        en_US: {
            translations: english
        },
        ar: {
            translations: arabic
        },
        hin: {
            translations: hindi
        },
    },

    // name of the custom namespace to be given 
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,

    // React will escape output values, so we don't need i18next to do it.
    interpolation: {
        escapeValue: false,
        formatSeparator: ",",
        // format: (value, format, lng) => {
        //     if (format === "number") {
        //         return new Intl.NumberFormat(lng).format(value);
        //     }
        // },
    },
    react: {
        useSuspense: false,
        wait: true,
        // defaultTransParent: 'div',
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
        transWrapTextNodes: ''
    }
});

// i18n.languages = ['en_US', 'hindi', 'ar'];

export default i18n;