import moment from 'moment'
import React, { useEffect } from 'react'
import BidCard from '../../../../Common/Layout/Card/BidCard'
import DefaultProfileicon from '../../../../Images/Vendor/DefaultProfileicon.svg'
import { useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'
import NoContentPage from '../../../../Common/Layout/NoContentPage/NoContentPage'

const MyReqActive = (props) => {

    return (
        <div className={`overflow-y-scroll h-[78vh] bg-white-5`}>
            {props.loadingUser === true ?
                <div className='flex h-full justify-center items-center'>
                    <CircularProgress size={20} color='success' />
                    <p className='pl-3'>Loading...</p>
                </div>
                :
                <div>
                    {props.liveMyRequirementsData && props.liveMyRequirementsData?.length > 0 ?
                        <>
                            {props.liveMyRequirementsData?.map((item, idx) =>
                                <div key={idx}>
                                    {item?.status === "active" &&
                                        <BidCard
                                            DefaultProfileicon={DefaultProfileicon}
                                            name={item?.user?.name}
                                            email={item?.user?.email}
                                            question={item?.title}
                                            content={item?.description}
                                            flex={true}
                                            showdate={true}
                                            date={moment(item?.startDate).format('LL')}
                                            // peoplereplied={true}
                                            numberofpeoplereplied={item?.numberofpeoplereplied}
                                            attachment={item?.document?.length > 0 ? true : false}
                                            validdate={false}
                                            time={moment(item?.endDate).format('LL')}
                                            onClick={() => props.goToUserMyReqrLiveDetails(item)}
                                            item={item}
                                        />
                                    }
                                </div>
                            )}
                        </>
                        :
                        <NoContentPage
                            NoContentMainDiv={`h-[78vh]`}
                            text2={`There is no data`}
                        />
                    }
                </div>
            }
        </div>

    )
}

export default MyReqActive