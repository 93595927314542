import React, { useEffect } from 'react'
import text from "../../../Common/Languages/en_US.json"
import TotalUsersHeader from '../TotalUsers/TotalUsersHeader'
import HyperlinkForm from './HyperlinkForm'
import HyperlinkList from './HyperlinkList'
import SettingSidebar from './SettingSidebar'
import SubAdminFileUpload from './SubAdminFileUpload'
import PropTypes from 'prop-types';
import NotificationSettings from './NotificationSettings'


const ManageNotificationsMain = (props) => {
    return (
        <div className='flex'>

            {/* <SettingSidebar /> */}
            <div className='w-full bg-white'>

                <div>
                    <TotalUsersHeader userName={`Manage Notifications`} dropDown={false} addBtn={false} csvBtn={false} btnColor={false} toggleSubAdmin={props.showHyperlinkForm} />

                    <NotificationSettings
                        checkSpecialAnnouncement={props.checkSpecialAnnouncement}
                        checkAccountExpiry={props.checkAccountExpiry}
                        checkNewOffer={props.checkNewOffer}
                        postNotificationSeting={props.postNotificationSeting}
                        checkNewUser={props.checkNewUser}
                        checkSubscriptionActivity={props.checkSubscriptionActivity}
                        checkBidConfirmation={props.checkBidConfirmation}
                        specialAnnouncement={props.specialAnnouncement}
                        accountExpiry={props.accountExpiry}
                        newOffer={props.newOffer}
                        newUser={props.newUser}
                        subscriptionActivity={props.subscriptionActivity}
                        bidConfirmation={props.bidConfirmation}
                        selectTypeMsg={props.selectTypeMsg}
                        dropdownName={props.dropdownName}
                        notificationFound={props.notificationFound}
                        notificationNotFound={props.notificationNotFound}
                        updateNotifications={props.updateNotifications}
                        settingNotificationUpdate={props.settingNotificationUpdate}
                    />

                </div>
            </div>

        </div>
    )
}

// proptypes 
// HyperlinkMain.propTypes = {
//     showHyperlinkForm: PropTypes.func,
//     hyperlinkForm: PropTypes.bool,
//     createHyperlink: PropTypes.func,
//     allLinks: PropTypes.array,
//     goToLinkTable: PropTypes.func,
//     uploadData: PropTypes.array,

// }

export default ManageNotificationsMain