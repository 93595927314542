import React, { useEffect, useState } from 'react'
import ButtonField from '../../../Common/FormFields/ButtonField'
// import DropDownField from '../../../Common/FormFields/DropDownField'
import TextFieldInput from '../../../Common/FormFields/TextFieldInput'
import './index.css'
import text from "../../../Common/Languages/en_US.json"
import { useForm, Controller } from "react-hook-form"
// import { ForAdmin } from '../../../Containers/Admin/Hooks'
// import { useSelector } from 'react-redux'
import { Box, Chip, FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material'
import locationImage from '../../../Images/Setting/locationImg.svg'
import PropTypes from 'prop-types';
// import MultipleSelect from '../../../Common/FormFields/MultipleSelect'
// import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import UploadButton from '../../../Common/UiComponents/UploadButton'
import { ForUploadMediaHooks } from '../../../Containers/UploadMedia/Hooks'
import { useDispatch } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import fileUploadIcon from '../../../Images/SubAdmin/fileUploadIcon.svg'
import { setUploadedLogo } from '../../../Containers/UploadMedia/reducer/UploadMediaReducer'
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api'
import { ForSignup } from '../../../Containers/Authentication/Signup/Hook'
import DropDownField from '../../../Common/FormFields/DropDownField'
import MultipleSelect from '../../../Common/FormFields/MultipleSelect'
import PhoneInputField from '../../../Common/FormFields/PhoneInputField'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        // fontWeight:
        //     personName.indexOf(name) === -1
        //         ? theme.typography.fontWeightRegular
        //         : theme.typography.fontWeightMedium,
    };
}

const SubAdminForm = (props) => {
    const theme = useTheme()
    // const { t } = useTranslation()
    // const userData = useSelector((state) => state.setting.users)
    // const [addSections, setAddSections] = useState()
    // const [addAssignedRole, setAddAssignedRole] = useState()

    const { cleanupMedia, logoUpload, doUploadedLogo } = ForUploadMediaHooks()
    const { getLocationData, mapLocationTxt } = ForSignup()
    const dispatch = useDispatch()
    const [addLocation, setAddLocation] = useState(props.editSubAdminFormData?.governorate ? props.editSubAdminFormData?.governorate : '')
    const [input, setInput] = useState(
        // props.editSubAdminFormData?.place ? { name: props.editSubAdminFormData?.place, value: props.editSubAdminFormData?.place } : null
        );
    const noLocationFound = []

    const editSelectedMulti = props?.editSubAdminFormData?.sections.map((item) => item?.name)

    const [multipleSections, setMultipleSections] = useState(props?.editSubAdminFormData?.sections ? editSelectedMulti : [])
    const [addMultipleSections, setAddMultipleSections] = useState([])

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`
    })
    const [openMap, setOpenMap] = useState(false)
    const center = React.useMemo(() => ({
        lat: parseFloat(sessionStorage.getItem('latitude')),
        lng: parseFloat(sessionStorage.getItem('longitude'))
    }), [])
    const [marker, setMarker] = useState({})
    const onMapClick = React.useCallback((event) => {
        getLocationData(event.latLng.lat(), event.latLng.lng())
        setMarker({
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
            address: mapLocationTxt,
        });
    }, []);

    // handle multiple select unselect for data
    const selectedSections = addMultipleSections.filter((value, index, self) => {
        return self.indexOf(value) === index && self.lastIndexOf(value) === index;
    });

    // form data 
    // const [formData, setFormData] = useState([])
    // dropdown data 
    const selectArr1 = [
        { name: "Account", value: "Account" },
        { name: "Dashboard", value: "Dashboard" },
        { name: "Total Users", value: "Total Users" },
        { name: "Total Vendors", value: "Total Vendors" },
        { name: "Subscription", value: "Subscription" },
        { name: "Categories", value: "Categories" },
        { name: "Support", value: "Support" },
        { name: "CMS page", value: "CMS page" }
    ]
    // const selectArr2 = [
    //     { name: "Accountant", value: "Accountant" },
    //     { name: "Support", value: "Support" },
    //     { name: "CMS page", value: "CMS page" }
    // ]

    const governorateArray = [
        {
            name: 'Farwaniya',
            value: 'Farwaniya',
            FarwaniyaLocations: [
                { name: 'Jaleeb Al Shuwaikh', value: 'Jaleeb Al Shuwaikh' },
                { name: 'Dajeej', value: 'Dajeej' },
                { name: 'Ardiya', value: 'Ardiya' },
                { name: 'Riggae', value: 'Riggae' },
                { name: 'Farwaniya', value: 'Farwaniya' },
                { name: 'Kaithan', value: 'Kaithan' },
                { name: 'Al Rai', value: 'Al Rai' }
            ]
        },
        {
            name: 'Al Asimah',
            value: 'Al Asimah',
            AlAsimahLocations: [
                { name: 'Shuwaikh', value: 'Shuwaikh' }
            ]
        },
        {
            name: 'Hawally',
            value: 'Hawally',
            HawallyLocations: [
                { name: 'Hawally', value: 'Hawally' },
                { name: 'Jabriya', value: 'Jabriya' },
                { name: 'Salmiya', value: 'Salmiya' },
                { name: 'Shaab', value: 'Shaab' }
            ]
        },
        {
            name: 'Mubarak Al Kabeer',
            value: 'Mubarak Al Kabeer',
            MubarakAlKabeerLocations: [
                { name: 'Sabah Al Salem', value: 'Sabah Al Salem' },
                { name: 'Kuwait City', value: 'Kuwait City' },
                { name: 'Qurain', value: 'Qurain' }
            ]
        },
        {
            name: 'Ahmadi',
            value: 'Ahmadi',
            AhmadiLocations: [
                { name: 'Mahboula', value: 'Mahboula' },
                { name: 'Mangaf', value: 'Mangaf' },
                { name: 'Fahaheel', value: 'Fahaheel' },
                { name: 'Ahmadi', value: 'Ahmadi' }
            ]
        },
        {
            name: 'Jahra', value: 'Jahra',
            JahraLocations: [
                { name: 'Jahra', value: 'Jahra' },
            ]
        },
    ]
    // form handling using react-hook-form 
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm({
        defaultValues: {
            fullName: props.editSubAdminFormData?.name ? props.editSubAdminFormData?.name : '',
            email: props.editSubAdminFormData?.email ? props.editSubAdminFormData?.email : '',
            phoneNumber: props.editSubAdminFormData?.phoneNo ? props.editSubAdminFormData?.phoneNo : '',
            location: props.editSubAdminFormData?.location ? props.editSubAdminFormData?.location : '',
            governorate: props.editSubAdminFormData?.governorate ? props.editSubAdminFormData?.governorate : '',
            // places: props.editSubAdminFormData?.place ? props.editSubAdminFormData?.place : '',
            zip: props.editSubAdminFormData?.zipCode ? props.editSubAdminFormData?.zipCode : '',
            // assignedSection: props?.editSubAdminFormData?.sections ? editSelectedMulti : [],
            // assignedRole: props?.editSubAdminFormData?.subRole ? props?.editSubAdminFormData?.subRole : '',
        }
    });

    const onSubmit = (data) => {
        // setFormData(data)
        // setFormData(formData => [...formData, data]);
        props.editSubAdminFormData ? props.editSubAdminApiCall(data, multipleSections, marker) : props.subAdminCreate(data, selectedSections, marker)
        // resetting after submission 
        props.subAdminCreateMsg?.error === false &&
            reset({
                fullName: '',
                email: '',
                phoneNumber: '',
                location: '',
                zip: '',
                selectedSections: [],
                governorate: [],
                places: [],
                // assignedRole: '',
                description: ''
            })
    };

    useEffect(() => {
        if (props.editSubAdminFormData && Object.keys(props.editSubAdminFormData)?.length > 0) {
            dispatch(setUploadedLogo(props.editSubAdminFormData?.picture))
        }
    }, [])


    // coustomize section's multiple data view
    const handleChangeSections = (event) => {
        const {
            target: { value },
        } = event;
        setMultipleSections(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <div className={`px-[4%] py-[5%] flex flex-col gap-[3rem]`}>
            {/* form content  */}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='flex flex-col gap-5 textFieldText'>
                    <div className='flex justify-between w-full'>
                        <div className='w-[65%]'>
                            <div>
                                <Controller name={"fullName"}
                                    control={control}
                                    render={({ field: { onChange, value }, formState: { error } }) => {
                                        return (
                                            (<TextFieldInput
                                                onlyValue
                                                textnewclass={`w-full text-sm`}
                                                placeholder='*Enter full name'
                                                onChange={onChange}
                                                value={value}
                                                floatingLabel={'*Enter full name'}
                                            />)
                                        )
                                    }}

                                    rules={{
                                        required: true, pattern: /^[a-zA-Z][a-zA-Z ]*/i, minLength: 2
                                    }}
                                />
                                {/* fullName error msg */}
                                {errors.fullName && errors.fullName.type === "required" && (
                                    <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                )}
                                {errors.fullName && errors.fullName.type === "minLength" && (
                                    <span className="error-message text-red-400 text-xs">{text.fullNameValidation}</span>
                                )}
                                {errors.fullName && errors.fullName.type === "pattern" && (
                                    <span className="error-message text-red-400 text-xs">{text.enterValidName}</span>
                                )}
                            </div>

                            {/* Email  */}
                            <div className={`grid grid-cols-2 gap-6 w-full`}>
                                {/* mail controller  */}
                                <div className='flex flex-col'>
                                    <Controller name={"email"}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => {
                                            return (
                                                (<TextFieldInput
                                                    onlyValue
                                                    textnewclass={`flex-1 w-full text-sm`}
                                                    placeholder='*Enter email ID'
                                                    onChange={onChange}
                                                    value={value}
                                                    disabled={props.editSubAdminFormData?.email ? true : false}
                                                    floatingLabel={'*Enter email ID'}
                                                />)
                                            )
                                        }}
                                        rules={{
                                            required: props.editSubAdminFormData?.email ? false : true,
                                            pattern: /^[\w]{1,}[\w.+-]{0,}@[\w-]{2,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$/i
                                        }}
                                    />
                                    {/* mail error msg  */}
                                    {errors.email && errors.email.type === "required" && (
                                        <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                    )}
                                    {errors.email && errors.email.type === "pattern" && (
                                        <span className="error-message text-red-400 text-xs">{text.enterValidMail}</span>
                                    )}
                                </div>

                                <div className='flex flex-col arrowRemovedMain'>
                                    {/* phone controller  */}
                                    <Controller name={"phoneNumber"}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => {
                                            return (
                                                // (<TextFieldInput
                                                //     onlyValue
                                                //     textnewclass={`flex-1 w-full text-sm arrowRemoved`}
                                                //     placeholder='*Enter phone no'
                                                //     typeNumber
                                                //     onChange={onChange}
                                                //     value={value}
                                                //     disabled={props.editSubAdminFormData?.phoneNo ? true : false}
                                                //     floatingLabel={'*Enter phone no'}
                                                // />
                                                <PhoneInputField 
                                                    // phoneName={'phoneNo'}
                                                    defaultCountry={'us'}
                                                    placeholder={'*Enter Phone no'}
                                                    label={'*Enter Phone Number'}
                                                    extraCls={`w-full text-sm mt-[0.45rem]`}
                                                    inputCls={`w-full h-[3.3rem] cursor-default`}
                                                    onChange={(value) => { onChange(value) }}
                                                    value={props.editSubAdminFormData?.phoneNo ? props.editVendorFormData?.phoneNo : value}
                                                    enableSearch={true}
                                                    disabled={props.editSubAdminFormData?.phoneNo ? true : false}
                                                />
                                            )
                                        }}
                                        rules={{
                                            required: props.editSubAdminFormData?.phoneNo ? false : true,
                                            pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i,
                                            // maxLength: 10
                                            minLength: 10
                                        }}
                                    />
                                    {/* phone error msg  */}
                                    {errors.phoneNumber && errors.phoneNumber.type === "required" && (
                                        <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                    )}
                                    {errors.phoneNumber && errors.phoneNumber.type === "pattern" && (
                                        <span className="error-message text-red-400 text-xs">{text.enterValidPhone}</span>
                                    )}
                                    {errors.phoneNumber && errors.phoneNumber.type === "maxLength" && (
                                        <span className="error-message text-red-400 text-xs">{text.phoneNumberValidation}</span>
                                    )}
                                </div>
                            </div>

                            <div className={`grid mb-4`}>
                                {/* governorate  */}
                                <div className={`w-full my-3  flex flex-col governorText`}>
                                    <Controller
                                        name={"governorate"}
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                        // defaultValue={props.editSubAdminFormData?.governorate ? props.editSubAdminFormData?.governorate : ''}
                                        render={({ field: { onChange, value } }) => (
                                            <DropDownField
                                                name='governorate'
                                                id='governorate'
                                                dropDownRootCls={`governorateCls`}
                                                size="small"
                                                selectOption={governorateArray}
                                                placeholder={`enterGovernorate`}
                                                // option={value}
                                                // handleChange={onChange}
                                                option={value ? value : addLocation}
                                                handleChange={(e) => { setAddLocation(e.target.value); onChange(e.target.value); }}
                                            />
                                        )}
                                    />
                                    <small className={`text-red-400 text-xs`}>
                                        {errors?.governorate && errors?.governorate?.type === "required" && (<p>This field is required</p>)}

                                    </small>

                                </div>

                                {/* section for selecting multiple places */}
                                <div className={`w-full  my-3 flex flex-col`}>
                                    <Controller
                                        name={"places"}
                                        control={control}
                                        rules={{
                                            required: props.editSubAdminFormData?.place ? false : true,
                                        }}
                                        // defaultValue={props.editSubAdminFormData?.place ? props.editSubAdminFormData?.place : ''}
                                        render={({ field: { onChange, value } }) => (
                                            <MultipleSelect
                                                // size="small"
                                                multiple={false}
                                                name="places"
                                                id='places'
                                                value={value ? value : input}
                                                // value={value ? value : props.editSubAdminFormData?.place ? props.editSubAdminFormData?.place : input}
                                                onChange={(e, v) => { setInput(v.name); onChange(v.name); }}
                                                // label={`selectPlace`}
                                                isOptionEqualToValue={(option, value) => { return option?.name === value?.name; }}
                                                placeholder={`selectPlace`}
                                                autocompleteExtraCls={`selectLocationCls`}
                                                autocompleteArray={
                                                    addLocation === 'Farwaniya' ? governorateArray[0].FarwaniyaLocations
                                                        :
                                                        addLocation === 'Al Asimah' ? governorateArray[1].AlAsimahLocations
                                                            :
                                                            addLocation === 'Hawally' ? governorateArray[2].HawallyLocations
                                                                :
                                                                addLocation === 'Mubarak Al Kabeer' ? governorateArray[3].MubarakAlKabeerLocations
                                                                    :
                                                                    addLocation === 'Ahmadi' ? governorateArray[4].AhmadiLocations
                                                                        :
                                                                        addLocation === 'Jahra' ? governorateArray[5].JahraLocations
                                                                            : noLocationFound
                                                }

                                            />
                                        )}
                                    />
                                    <small className={`text-red-400 text-xs`}>
                                        {errors?.places && errors?.places?.type === "required" && (<p>This field is required</p>)}
                                    </small>
                                </div>

                                {/* Location */}
                                {/* <div className='flex flex-col '>
                                    <Controller name={"location"}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => {
                                            return (
                                                (<TextFieldInput
                                                    onlyValue
                                                    textnewclass={`flex-1 w-full text-sm`}
                                                    placeholder='*Location'
                                                    onChange={onChange}
                                                    value={mapLocationTxt ? mapLocationTxt : value}
                                                    endAdornment={
                                                        <InputAdornment position={"end"}>
                                                            <img src={locationImage} alt="locationImage" className={`cursor-pointer`} onClick={() => setOpenMap(true)} />
                                                        </InputAdornment>
                                                    }
                                                    floatingLabel={'*Location'}
                                                />)
                                            )
                                        }}
                                        rules={{
                                            required: (props.editSubAdminFormData?.location || (mapLocationTxt && mapLocationTxt?.length > 0)) ? false : true,
                                        }}
                                    />
                                    {errors.location && errors.location.type === "required" && (
                                        <span className="error-message text-red-400 text-xs">{text.enterLocation}</span>
                                    )}
                                </div>
                                {openMap &&
                                    <>
                                        {isLoaded &&
                                            <GoogleMap
                                                mapContainerStyle={{
                                                    height: '400px',
                                                    width: '100%',
                                                }}
                                                zoom={10}
                                                center={center}
                                                onClick={onMapClick}
                                            >
                                                <MarkerF
                                                    position={{ lat: marker.lat, lng: marker.lng }}
                                                />
                                            </GoogleMap>
                                        }
                                    </>
                                } */}

                            </div>

                            {/* Assigned dropDown  */}
                            {/* <div className={`grid grid-cols-2 gap-6 w-full dropdownText`}> */}
                            <div className={`w-full dropdownText`}>
                                {/* assigned section controller */}
                                {/* <div className='flex flex-col'>
                            <Controller
                                name={"assignedSection"}
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => {
                                    return (
                                        (<DropDownField
                                            name='assignedSection'
                                            id='assignedSection'
                                            selectOption={selectArr1}
                                            dropDownRootCls={`h-11  text-sm text-gray-60 font-[Roboto-Regular]`}
                                            filterImg={false}
                                            handleChange={(e) => {
                                                setAddSections(e.target.value);
                                                onChange(e.target.value)
                                            }}
                                            placeholder="*Assigned sections"
                                            option={value}
                                        />)
                                    )
                                }}
                                rules={{
                                    required: props.editSubAdminFormData?.sections ? false : true
                                }}
                            /> */}
                                {/* assigned section error msg  */}
                                {/* {errors.assignedSection && errors.assignedSection.type === "required" && (
                                <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                            )}
                        </div> */}

                                <FormControl className={`w-full`}>
                                    <InputLabel id="demo-multiple-chip-label" className='bg-white'>{`*Add Role`}</InputLabel>
                                    <Select
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        multiple
                                        value={multipleSections}
                                        onChange={handleChangeSections}
                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                        disabled={localStorage.getItem('role') === 'subAdmin' ? true : false}
                                    >
                                        {selectArr1?.map((item, idx) => (
                                            <MenuItem
                                                key={idx}
                                                value={item?.name}
                                                style={getStyles(item?.name, multipleSections, theme)}
                                                onClick={() => setAddMultipleSections([...addMultipleSections, item?.name])}
                                            >
                                                {item?.name}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>

                                {/* assigned role controller  */}
                                {/* <div className='flex flex-col'>
                            <Controller
                                name={"assignedRole"}
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => {
                                    return (
                                        (<DropDownField
                                            selectOption={selectArr2}
                                            dropDownRootCls={`h-11  text-sm !text-gray-60 font-[Roboto-Regular]`}
                                            filterImg={false}
                                            handleChange={(event) => {
                                                onChange(event.target.value);
                                                setAddAssignedRole(event.target.value)
                                            }}
                                            placeholder="*Assigned Role"
                                            option={value}
                                            sx={{ color: '#000' }}
                                        />)
                                    )
                                }}
                                rules={{
                                    required: props.editSubAdminFormData?.subRole ? false : true
                                }}
                            /> */}
                                {/* assigned section error msg  */}
                                {/* {errors.assignedRole && errors.assignedRole.type === "required" && (
                                <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                            )}
                        </div> */}
                            </div>

                            {/* description controller  */}
                            <div>
                                <Controller name={"description"}
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                                        return (
                                            (<TextFieldInput
                                                onlyValue
                                                textnewclass={`w-full text-sm`}
                                                placeholder='*Write Description'
                                                onChange={onChange}
                                                value={(value)}
                                                floatingLabel={'*Write Description'}
                                            />)
                                        )
                                    }}
                                    rules={{
                                        // required: true,
                                        minLength: 3
                                    }}
                                />
                            </div>
                        </div>

                        <div>
                            <p className={`text-Grey-900 font-[Roboto-Medium] font-normal text-base mb-5`}>Upload Your Profile</p>
                            <div className={`relative`}>
                                {logoUpload &&
                                    (logoUpload?.mimetype === 'image/jpeg' || logoUpload?.mimetype === 'image/png') &&
                                    <div className={`absolute -top-2 left-24 -right-4 cursor-pointer`}>
                                        <CloseIcon
                                            sx={{ fontSize: "12px", border: '1px solid red', borderRadius: '9999px' }}
                                            onClick={() => dispatch(setUploadedLogo({}))}
                                        />
                                    </div>
                                }
                                <UploadButton
                                    sendData={(data) => {
                                        if (data[0]?.type === 'image/png' || data[0]?.type === 'image/jpeg') {
                                            doUploadedLogo(data)
                                        }
                                    }}
                                    extracls='flex justify-center items-center bg-white  items-center rounded-md '
                                    type='button'
                                    fileUploadIcon={
                                        logoUpload &&
                                            (logoUpload?.mimetype === 'image/jpeg' || logoUpload?.mimetype === 'image/png') && logoUpload?.key ?
                                            `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${logoUpload?.key}&height=20&width=auto` :
                                            fileUploadIcon
                                    }
                                    accept={'image/jpg, image/png'}
                                    multiple={false}
                                    disabled={logoUpload &&
                                        (logoUpload?.mimetype === 'image/jpeg' || logoUpload?.mimetype === 'image/png') ?
                                        true : false
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    {/* form cta  */}
                    <div className={`flex justify-start`}>
                        <div className='flex gap-6'>
                            <ButtonField
                                buttonName={text.cancel}
                                type='reset'
                                buttonextracls={` !px-6 !py-2 !text-gray-60 bg-gray-1 font-[Roboto-Medium] hover:bg-deepBlue hover:text-white`}
                                onClick={() => { props.goBackToTable(); cleanupMedia() }}
                            />
                            <ButtonField
                                buttonName={text.save}
                                type='submit'
                                buttonextracls={` !px-6 !py-2 !text-white bg-skyblue-6 hover:bg-deepBlue`}
                                onClick={handleSubmit(onSubmit)}
                                loading={props.loading}
                                disabled={props.loading === true ? true : false}
                            />
                        </div>
                    </div>
                    {props.subAdminCreateMsg?.error === true ? <p className='text-red-600 text-sm'>{props.subAdminCreateMsg?.message}</p> : null}
                </div>



            </form>
        </div>
    )
}

SubAdminForm.propTypes = {
    subAdminCreate: PropTypes.func,
    goBackToTable: PropTypes.func,

}

export default SubAdminForm