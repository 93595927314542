import React from 'react'
import searchicon from '../../../Images/profileDashboardIcons/searchicon.svg'
import searchrelatedicon from '../../../Images/profileDashboardIcons/searchrelatedicon.svg'
import defaultserviceIcon from '../../../Images/vendors/defaultprofile.svg'
import defaultMedia from '../../../Images/media.svg'
import defaultPdf from '../../../Images/pdf.svg'
import attachmentsection from '../../../Images/profileDashboardIcons/attachment.svg'
import moment from 'moment'
import _ from 'lodash'
import { InputAdornment } from '@mui/material';
import TextFieldInput from '../../../Common/FormFields/TextFieldInput'

const ChatList = (props) => {

    const element = document.getElementById(props.clicked);
    React.useEffect(() => {
        if (element) {
            element.current?.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ bottom: target.scrollHeight, behavior: 'smooth' });
            });
        }
    }, [element])

    return (
        <div>
            <div className={`p-[5%] border border-gray-0.5`}>
                    {/* <img src={searchicon} alt='searchicon' className={`cursor-pointer`} />
                    <input placeholder='Search' className={`clickText bg-transparent h-10 outline-none px-2 w-full `} type='text' />
                    <img src={searchrelatedicon} alt='searchicon' className={`cursor-pointer`} /> */}
                    <TextFieldInput
                        onlyValue
                        textnewclass={`flex-1 w-full text-sm tableSearchBar`}
                        placeholder={`Search`}
                        id="outlined-basic"
                        variant="outlined"
                        startAdornment={
                            <InputAdornment position={"start"}>
                                <img src={searchicon} alt='search' />
                            </InputAdornment>}
                        onChange={props.handleSearchChat}
                    />
            </div>

            <div className={`overflow-y-auto h-[75vh] px-[5%] pt-2`}>
                {/* When there are chats already, below data is being get from the get API call getChatsApiCAll */}
                {props.chatArr?.length > 0 && _.orderBy(props.chatArr, 'updatedAt', 'desc')?.map((chatItem, idx) =>
                    <div id={chatItem?.id} key={idx} className={`flex flex-col justify-between p-[3%] items-start w-full cursor-pointer border-Grey-400 border-b rounded-sm border-l-primeryColour-4 ${props.clicked === chatItem?.id && 'border-[0.5px] border-Grey-400 bg-gray-100'}`} onClick={() => props.handleclick(chatItem)} >
                        <div className={`flex justify-between  w-[100%]`}>
                            {chatItem?.participants?.filter((item) => item?.id !== parseInt(localStorage.getItem('userId')))?.map((partItem) =>
                                <div className={`flex items-start w-[55%]`}>
                                    <img crossOrigin='anonymous' src={partItem?.business[0]?.logo?.key ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${partItem?.business[0]?.logo?.key}&height=40&width=40` : defaultserviceIcon} alt="profileicon" className={`rounded-full border-2`} height={40} width={40} />
                                    <div className={`flex flex-col pl-2 w-[70%]`}>
                                        <div className={``}>
                                            <p className={`text-sm text-deepgray-5 font-[Roboto-Medium] mb-1 pr-2 truncate`}>{partItem?.business[0]?.name ? partItem?.business[0]?.name : partItem?.name}</p>
                                            {chatItem?.message[0]?.file?.length < 1 ?
                                                <p className={`text-xs text-text-60 font-[Roboto-Regular] mt-1 truncate`}>{chatItem?.message[0]?.message}</p>
                                                :
                                                <div className={`flex`}>
                                                    <img src={chatItem?.message[0]?.file[0]?.mimetype?.includes("application") ? attachmentsection : defaultMedia} alt="" height={17} width={17} />
                                                    <p className={`text-xs text-text-60 font-[Roboto-Regular] ml-1 truncate`}>{chatItem?.message[0]?.file[0]?.name}</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className={`flex items-rigth`}>
                                <p className={`font-[Roboto-Regular] text-xs text-[#434343]/[0.6]`}>{moment(chatItem?.message[0]?.sentAt).startOf('second').fromNow()}</p>
                                {chatItem?.unRead !== 0 ?
                                    <p className={`rounded-full bg-red-600 text-white w-[22px] h-[22px] flex justify-center items-center text-xs ml-2`} >{chatItem?.unRead}</p>
                                    : null}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ChatList