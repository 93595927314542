import React, { useEffect } from 'react'
import HyperlinkMain from '../../Components/Admin/Settings/HyperlinkMain'
import { ForAdmin } from './Hooks'
import SnackBarComponent from '../../Common/Layout/Snackbar/SnackBar'
import { ForUploadMediaHooks } from '../UploadMedia/Hooks'
import { useNavigate } from 'react-router-dom'
import { useJwt } from 'react-jwt'

const SettingsHyperlinkIndex = () => {
    const {
        goBackToTable,
        hyperlinkForm,
        showHyperlinkForm,
        createHyperlink,
        allLinks,
        getAllHyperlinks,
        goToLinkTable,
        uploadData,
        sendData,
        setHyperlinkForm,
        editHyperlinkApi,
        editHyperlinkForm,
        hyperlinkFormData,
        deleteHyperlinkApi,
        adminSnackbar,
        messageClose,
        loading
        } = ForAdmin()

        const { doUploadedLogo } = ForUploadMediaHooks()

        useEffect(() => {
            getAllHyperlinks()
        }, [])
        
    const navigate = useNavigate()

    // For refresh token when actual token expires 
    let token = localStorage.getItem('token') ? localStorage.getItem('token') : "";
    const { isExpired } = useJwt(token);
    const checkTokenExpiration = () => {
        if (isExpired) {
            navigate('/login');
        }
    }
    return (
        <div onClick={() => checkTokenExpiration()}>
            <HyperlinkMain
                goBackToTable={goBackToTable}
                hyperlinkForm={hyperlinkForm}
                setHyperlinkForm={setHyperlinkForm}
                showHyperlinkForm={showHyperlinkForm}
                createHyperlink={createHyperlink}
                allLinks={allLinks}
                goToLinkTable={goToLinkTable}
                uploadData={uploadData}
                sendData={sendData}
                editHyperlinkApi={editHyperlinkApi}
                editHyperlinkForm={editHyperlinkForm}
                hyperlinkFormData={hyperlinkFormData}
                deleteHyperlinkApi={deleteHyperlinkApi}
                doUploadedLogo={doUploadedLogo}
                loading={loading}
            />
            <SnackBarComponent
                messageOpen={adminSnackbar.open}
                messageClose={messageClose}
                notificationText={adminSnackbar.message}
                subText={adminSnackbar.subText}
                alertType={adminSnackbar.alertType}
                borderClass={adminSnackbar.borderClass}
            />
        </div>
    )
}

export default SettingsHyperlinkIndex